import * as React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-typed'
import { History } from 'history'
import { TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { InlineDataTable, HeaderRow, TableHeader, Cell, ExpandCell, DynamicRow } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { PageActions } from 'uiComponents/settingsContainer'
import { ActionButtonA } from 'uiComponents/buttons'
import { InventoryServiceContext } from 'inventory/context'
import { InventoryItem } from 'inventory/inventoryService'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { useGetAllArticles, useIsLoadingCategoriesOrArticles } from 'products/redux'
import { ArticleFromApi } from 'products/types'
import AttachedProductsTreeList from 'products/attachedProductsTreeList'
import { AttachedProduct } from 'products/attachedCategoriesList'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { faFileTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { addSeparators } from 'utils'

const ActionsSection = styled(PageActions)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
const TotalInfo = styled.div`
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.4em;
    span {
        font-weight: 500;
    }
`
const ProductsIcon = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    opacity: 0.5;
    color: ${(props) => props.theme.colors.status.error};
`

interface InventoryListPageProps {
    navigation: Navigation
    match: RouteMatch<{}>
    history: History
    accountSlug: string
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    source: '?from=flat' | '?from=nested'
}

interface InventoryProducts {
    attached: {
        count: number
        perPool: Record<string, AttachedProduct[]>
    }
    unlimited: {
        count: number
        products: AttachedProduct[]
    }
}

function getInventoryProducts(products: ArticleFromApi[]): InventoryProducts {
    const result: InventoryProducts = {
        attached: {
            count: 0,
            perPool: {},
        },
        unlimited: {
            count: 0,
            products: [],
        },
    }
    for (const product of products) {
        const attached = {
            id: product.id,
            numericId: 0,
            name: product.name['en'] || 'No english translation',
            productListUuids: [...product.categories.map((c) => c.id), ...product.inactiveCategories.map((c) => c.id)],
        }
        if (product.resources.length === 0) {
            result.unlimited.count++
            result.unlimited.products.push(attached)
        } else {
            result.attached.count++
            for (const resource of product.resources) {
                const resourceId = resource.inventoryPoolId
                result.attached.perPool[resourceId] = result.attached.perPool[resourceId] || []
                result.attached.perPool[resourceId].push(attached)
            }
        }
    }
    return result
}

function InventoryListPage(props: InventoryListPageProps) {
    const inventoryService = React.useContext(InventoryServiceContext)
    const articles = useGetAllArticles()
    const articlesLoading = useIsLoadingCategoriesOrArticles()
    const [inventoryLoading, setLoading] = React.useState<boolean>(false)
    const [inventoryList, setInventoryList] = React.useState<InventoryItem[]>([])
    const [currentExpanded, setCurrentExpanded] = React.useState<string>('')
    const loading = inventoryLoading || articlesLoading

    async function getInventoryList() {
        setLoading(true)
        try {
            const data = await inventoryService.getInventoryList(props.accountSlug)
            setInventoryList(data)
            setLoading(false)
        } catch {
            setLoading(false)
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    React.useEffect(() => {
        getInventoryList()
        props.setActiveSection('inventory', 'Crowd Control')
    }, [props.accountSlug])

    const { search } = useLocation()
    React.useEffect(() => {
        const query = props.navigation.query()
        if (query.poolId) {
            setCurrentExpanded(query.poolId)
        }
    }, [search])

    const inventoryProducts = getInventoryProducts(articles)
    const displayedInventory = inventoryList.map((x) => ({
        ...x,
        products: inventoryProducts.attached.perPool[x.id] || [],
    }))

    if (inventoryProducts.unlimited.count > 0) {
        displayedInventory.push({
            id: 'not_assigned',
            account: props.accountSlug,
            name: 'Unlimited inventory',
            defaultAvailability: null,
            reservationPeriodType: 'Gate',
            products: inventoryProducts.unlimited.products,
        })
    }

    const toggleExpanded = (id: string) => {
        const expanded = currentExpanded === id
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        expanded ? setCurrentExpanded('') : setCurrentExpanded(id)
        props.navigation.addQueryWithReplace({ poolId: expanded ? null : id })
    }

    const columnWidths = ['3.5em', null, '13em', '13em', '10em']

    return (
        <>
            <InlineDataTable columnWidths={columnWidths} bordered id="inventory-list">
                <HeaderRow>
                    <TableHeader nonInteractive noPadding />
                    <TableHeader>Activity Name</TableHeader>
                    <TableHeader align="center">Products attached</TableHeader>
                    <TableHeader align="center">Limit</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {loading && <TableLoader />}
                {!loading && (
                    <>
                        {displayedInventory.length === 0 && <NoResultsRow text="There is no inventory yet." />}
                        {displayedInventory.length > 0 &&
                            displayedInventory.map((item) => {
                                const expanded = item.id === currentExpanded
                                const unlimited = item.name === 'Unlimited inventory'
                                return (
                                    <div key={item.id}>
                                        <DynamicRow
                                            interactive={!!item.products && item.products.length > 0}
                                            highlighted={expanded}
                                            onClick={() =>
                                                !!item.products && item.products.length > 0
                                                    ? toggleExpanded(item.id)
                                                    : () => {}
                                            }
                                            className={expanded ? 'expanded inventoryRow' : 'inventoryRow'}
                                        >
                                            {!!item.products && item.products.length > 0 ? (
                                                <ExpandCell className="expand" expanded={expanded} />
                                            ) : (
                                                <Cell align="center" style={{ overflow: 'visible' }}>
                                                    <DisabledItemHoverInfotip
                                                        active
                                                        infotipText="No products attached"
                                                        offsetLeft="-1em"
                                                        width="12em"
                                                    >
                                                        <ProductsIcon icon={faFileTimes as IconProp} />
                                                    </DisabledItemHoverInfotip>
                                                </Cell>
                                            )}
                                            <Cell title={item.name} className="withName">
                                                <span>{item.name}</span>
                                            </Cell>
                                            <Cell align="center">{addSeparators(item.products?.length || '-')}</Cell>
                                            <Cell align="center">
                                                {unlimited
                                                    ? 'Unlimited'
                                                    : addSeparators(item.defaultAvailability || '-')}
                                            </Cell>
                                            <Cell align="right">
                                                {!unlimited && (
                                                    <ActionButtonA
                                                        className="editInventory"
                                                        kind="action"
                                                        secondary
                                                        style={{ marginRight: '1em' }}
                                                        href={`/account/${props.accountSlug}/products/inventory/item/${item.id}`}
                                                        stopPropagation
                                                    >
                                                        Edit
                                                    </ActionButtonA>
                                                )}
                                            </Cell>
                                        </DynamicRow>
                                        {expanded && item.products.length > 0 && (
                                            <NestedContainerWrapper grade={0} className="expanded">
                                                <AttachedProductsTreeList
                                                    products={item.products || []}
                                                    accountSlug={props.accountSlug}
                                                    history={props.history}
                                                    source={props.source}
                                                    hideNumericId
                                                />
                                            </NestedContainerWrapper>
                                        )}
                                    </div>
                                )
                            })}
                    </>
                )}
            </InlineDataTable>
            <ActionsSection>
                {!loading && displayedInventory.length > 0 ? (
                    <TotalInfo>
                        <div>
                            Total number of unique products attached to inventory:
                            <span>{inventoryProducts.attached.count}</span>
                        </div>
                        <div>
                            Total number of products with unlimited inventory:
                            <span>{inventoryProducts.unlimited.count}</span>
                        </div>
                    </TotalInfo>
                ) : (
                    <>&nbsp;</>
                )}
            </ActionsSection>
        </>
    )
}

export default withNavigation(InventoryListPage)
