import * as React from 'react'
import { StatsService } from 'http/statsService'
import { DateRange } from 'dateRanges'
import { Row, Column } from 'uiComponents/pageElements'
import { ChartWrapperWithDateRange } from 'reports/helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { LegacyDataNumber } from 'uiComponents/charts/dataNumber'
import { Filters } from 'uiComponents/filter/schema'
import { addSeparators } from 'utils'

interface SingleDataItemsProps {
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface SingleDataItemsState {
    emailRevenue: number
    emailOrders: number
    emailAvgOrderValue: number
    loading: boolean
}

class SingleDataItems extends React.Component<SingleDataItemsProps, SingleDataItemsState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: SingleDataItemsProps) {
        super(props)
        this.state = {
            emailRevenue: 0,
            emailOrders: 0,
            emailAvgOrderValue: 0,
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: SingleDataItemsProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug
        ) {
            await this.getData()
        }
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        this.setState({ loading: true })
        const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
        const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
        try {
            const appliedFilters: Filters[] = []
            const granularity = null
            const metric = ''
            const data = await this.props.statsService.getReportStats(
                'totalsForEmails',
                this.props.accountSlug,
                metric,
                dateFrom,
                dateTo,
                granularity,
                appliedFilters,
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const emailRevenue = data.emailRevenue.totals ? data.emailRevenue.totals[0].value : 0
            const emailOrders = data.emailOrders.totals ? data.emailOrders.totals[0].value : 0
            const emailAvgOrderValue = data.emailAvgOrderValue.totals ? data.emailAvgOrderValue.totals[0].value : 0
            if (this._isMounted) {
                this.setState({
                    emailRevenue,
                    emailOrders,
                    emailAvgOrderValue,
                    loading: false,
                })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    render() {
        const { emailRevenue, emailOrders, emailAvgOrderValue, loading } = this.state

        return (
            <div style={{ position: 'relative' }}>
                {loading && <ChartDataLoader />}
                <ChartWrapperWithDateRange dateRange={this.props.dateRange}>
                    <Row>
                        <Column>
                            <LegacyDataNumber
                                value={emailRevenue ? addSeparators(emailRevenue, 2) : 0}
                                title="Revenue"
                                withCurrency
                                accountSlug={this.props.accountSlug}
                            />
                        </Column>
                        <Column style={{ marginRight: '.8em' }}>
                            <LegacyDataNumber value={emailOrders ? addSeparators(emailOrders) : 0} title="Orders" />
                        </Column>
                        <Column>
                            <LegacyDataNumber
                                value={emailAvgOrderValue ? addSeparators(emailAvgOrderValue, 2) : 0}
                                title="Average order value"
                                withCurrency
                                accountSlug={this.props.accountSlug}
                            />
                        </Column>
                    </Row>
                </ChartWrapperWithDateRange>
            </div>
        )
    }
}

export default SingleDataItems
