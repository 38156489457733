import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { ProductData } from 'products/pricing/pricingService'
import { Col, Row } from 'uiComponents/flex'
import { Bold, SecondaryText } from 'uiComponents/typography'
import { Checkbox, InlineEdit } from 'uiComponents/input'
import { Cell, ExpandCell, DynamicRow, NameAndStatus, Name } from 'uiComponents/table'
import Money from 'uiComponents/money'
import { Toggle } from 'uiComponents/input/toggle'
import { ProductList, ArticleListItem, ProductListMinimum } from 'admin/articleService'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { pricingTypes, PricingType } from 'products/articleConfigurationService'
import ActionMenu from './actionMenu'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { ArticleServiceContext } from 'admin/context'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MessageKind } from 'uiComponents/messages'
import { NestedRowProps } from 'products/components/nestedRow'
import { hasDuplicatePriority } from 'products/utils'
import { DateRange } from 'uiComponents/date'

export interface ProductRowProps {
    narrow?: boolean
    pricingData?: ProductData | null
    deleteArticle?: (id: string) => void
    deleteProductList?: (id: string) => void
    duplicateProduct?: (id: string, name: string) => void
    updateProductListStatusAndPriority?: (id: string, enabled: boolean, priority: number) => void
    updateArticlePriority?: (id: string, priority: number) => void
    updateArticleStatus?: (id: string, enabled: boolean) => void
    priorities?: { [index: number]: string[] }
    hasFeature?: (feature: string, slug: string) => boolean
    origin?: '?from=flat' | '?from=nested' | ''
    toggleArticleCheck?: (checked: boolean) => void
    checkedArticlesIds?: string[]
    replaceTopMessages?: (id: string, status: MessageKind, text: string) => void
    hideMessage?: (id: string) => void
    updateArticleOriginalPrice?: (
        id: string,
        price: string,
        min: number | null,
        avg: number | null,
        gate: number | null,
        stepSize: string | null,
        charmPricing: boolean | null,
        pricingType: PricingType,
    ) => void
}

const EyeIcon = styled(FontAwesomeIcon)`
    margin-left: 0.5em;
    color: ${(props) => props.theme.colors.textLight};
`
const NameAndIconWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`
const Toggler = styled(Toggle)`
    font-size: 0.875em;
    margin-right: 0.3em;
`
const StatusRow = styled(Row)`
    width: 8em;
    margin-top: 0.2em;
    font-style: italic;
    align-items: center;
`

const TogglerWithText = function (props: { isOn: boolean; onClick: (v: boolean) => void; disabled?: boolean }) {
    return (
        <StatusRow gutter={12}>
            <Col span={4}>
                <Toggler
                    className="status-toggle"
                    isOn={props.isOn}
                    onClick={props.onClick}
                    disabled={props.disabled}
                />
            </Col>
            <Col span={8}>
                <SecondaryText>{props.isOn ? 'Active' : 'Inactive'}</SecondaryText>
            </Col>
        </StatusRow>
    )
}

export function ProductRow(props: ProductRowProps & NestedRowProps & React.HTMLAttributes<HTMLDivElement>) {
    const canViewProductValidityOrPricing =
        props.hasPermission('view_pricing_settings', props.accountSlug) ||
        props.hasPermission('view_product_validity', props.accountSlug)
    const {
        element,
        expanded,
        toggleExpanded,
        style,
        narrow,
        expandable,
        accountSlug,
        pricingData,
        history,
        deleteArticle,
        deleteProductList,
        updateProductListStatusAndPriority,
        origin,
    } = props
    const prices = pricingData
    const elementAsArticle = element as ArticleListItem
    const elementAsProductList = element as ProductList | ProductListMinimum
    const isArticle = elementAsArticle.id ? true : false
    const pricingType = pricingTypes[elementAsArticle.pricingType]

    const [articlePriority, setArticlePriority] = useState<number | null>(
        elementAsArticle.priority ? elementAsArticle.priority : null,
    )
    const [catPriority, setCatPriority] = useState<number | null>(
        elementAsProductList.priority ? elementAsProductList.priority : null,
    )
    const [status, setStatus] = useState<boolean>(element.enabled)
    const [togglerEnabled, setTogglerEnabled] = useState<boolean>(
        props.hasPermission('partner_admin', props.accountSlug),
    )
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false)
    const [futureStatusValue, setFutureStatusValue] = useState<boolean>(false)
    const [showPriorityConfirmation, setShowPriorityConfirmation] = useState<boolean>(false)
    const [futurePriorityValue, setFuturePriorityValue] = useState<number | null>(null)
    const [affectedCategories, setAffectedCategories] = useState<string>('')
    const articleService = React.useContext(ArticleServiceContext)

    const toggleDisabledReason = narrow
        ? 'Category has products that belong to multiple categories.'
        : 'Please enable/disable the category in the details page.'

    useEffect(() => {
        if (!!togglerEnabled) {
            !!isArticle
                ? setTogglerEnabled(true)
                : narrow
                ? setTogglerEnabled(!findArticleInMultiplePLs(elementAsProductList as ProductList))
                : setTogglerEnabled(false)
        }
    }, [element])

    function updateArticlePriority(prio: string) {
        if (props.updateArticlePriority) {
            if (!!elementAsArticle.productListUuids && elementAsArticle.productListUuids.length > 1) {
                fetchAffectedCategories()
                setFuturePriorityValue(+prio)
                setShowPriorityConfirmation(true)
            } else {
                props.updateArticlePriority(elementAsArticle.id, +prio)
                setArticlePriority(+prio)
            }
        }
    }

    function updateCatPriority(prio: string) {
        if (updateProductListStatusAndPriority) {
            updateProductListStatusAndPriority(elementAsProductList.uuid, elementAsProductList.enabled, +prio)
            setCatPriority(+prio)
        }
    }

    function updateStatus(v: boolean) {
        const id = isArticle ? elementAsArticle.id : elementAsProductList.uuid
        if (isArticle && props.updateArticleStatus) {
            if (!!elementAsArticle.productListUuids && elementAsArticle.productListUuids.length > 1) {
                fetchAffectedCategories()
                setFutureStatusValue(v)
                setShowStatusConfirmation(true)
            } else {
                props.updateArticleStatus(id, v)
                setStatus(v)
            }
        }
        if (!isArticle && props.updateProductListStatusAndPriority) {
            props.updateProductListStatusAndPriority(elementAsProductList.uuid, v, elementAsProductList.priority)
            setStatus(v)
        }
    }

    async function updateOriginalPrice(value: string) {
        if (props.updateArticleOriginalPrice) {
            if (!prices) {
                return
            }
            if (['upsell', 'static'].indexOf(elementAsArticle.pricingType) > -1) {
                props.updateArticleOriginalPrice(
                    elementAsArticle.id,
                    value,
                    null,
                    null,
                    prices.gatePrice === null ? null : +prices.gatePrice,
                    prices.stepSize,
                    prices.charmPricing,
                    elementAsArticle.pricingType,
                )
            } else {
                props.updateArticleOriginalPrice(
                    elementAsArticle.id,
                    value,
                    +prices.minAcceptedPrice,
                    +prices.avgTargetPrice,
                    prices.gatePrice === null ? null : +prices.gatePrice,
                    prices.stepSize,
                    prices.charmPricing,
                    elementAsArticle.pricingType,
                )
            }
        }
    }

    const fetchAffectedCategories = async () => {
        if (!elementAsArticle.productListUuids) {
            setAffectedCategories('')
            return
        }
        const response = await articleService.getProductListsNames(props.accountSlug, elementAsArticle.productListUuids)
        const names = response.map((r) => `"${r.name}"`).join(', ')
        setAffectedCategories(names)
    }

    function openDetailsPage() {
        if (!props.hasPermission('partner_admin', props.accountSlug)) {
            return
        }
        props.history.push(`/account/${props.accountSlug}/products/crud/${elementAsArticle.id}${origin}`)
    }

    function onLinkClick(e: React.MouseEvent) {
        e.stopPropagation()
    }

    function findArticleInMultiplePLs(pl: ProductList): boolean {
        if (!pl.articles) {
            return false
        }
        if (!pl.hasChildren) {
            return !!pl.articles.find((a) => a.multipleProductLists)
        }
        pl.children?.map((ch) => {
            return findArticleInMultiplePLs(ch)
        })
        return false
    }

    const linkToDetailsPage = isArticle
        ? `/account/${props.accountSlug}/products/crud/${elementAsArticle.id}${origin}`
        : `/account/${props.accountSlug}/products/crud/productList/${elementAsProductList.uuid}${origin}`

    const onStatusChangeConfirmation = () => {
        if (!!props.updateArticleStatus) {
            props.updateArticleStatus(elementAsArticle.id, futureStatusValue)
            setStatus(futureStatusValue)
            setShowStatusConfirmation(false)
            location.reload()
        }
    }
    const onPriorityChangeConfirmation = () => {
        if (!!props.updateArticlePriority && futurePriorityValue !== null) {
            props.updateArticlePriority(elementAsArticle.id, futurePriorityValue)
            setArticlePriority(futurePriorityValue)
            setShowPriorityConfirmation(false)
            location.reload()
        }
    }

    // @ts-ignore
    const showHiddenIcon = !isArticle && element.enabled && !element.showInCheckout
    const hiddenIconMesage = 'Category is accessible only via deep link (when the category is active)'

    const withDuplicatePrio = props.priorities ? hasDuplicatePriority(element, props.priorities) : false

    return (
        <>
            {showStatusConfirmation && affectedCategories && (
                <ConfirmationDialog
                    title={`This change will affect ${affectedCategories} categories. Are you sure you want to proceed?`}
                    text=""
                    buttonText="Proceed"
                    onCancel={() => setShowStatusConfirmation(false)}
                    onConfirm={onStatusChangeConfirmation}
                />
            )}
            {showPriorityConfirmation && affectedCategories && (
                <ConfirmationDialog
                    title={`This change will affect ${affectedCategories} categories. Are you sure you want to proceed?`}
                    text=""
                    buttonText="Proceed"
                    onCancel={() => setShowPriorityConfirmation(false)}
                    onConfirm={onPriorityChangeConfirmation}
                />
            )}
            <DynamicRow
                interactive
                narrow={narrow}
                highlighted={expanded}
                className={expanded ? 'expanded article' : 'article'}
                onClick={isArticle && !expandable ? openDetailsPage : toggleExpanded}
            >
                {origin === '?from=flat' && !!isArticle && (
                    <Cell onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            id="selectArticle"
                            name="selectArticle"
                            checked={props.checkedArticlesIds?.includes(elementAsArticle.id)}
                            onChange={(e) =>
                                props.toggleArticleCheck ? props.toggleArticleCheck(e.target.checked) : {}
                            }
                        />
                    </Cell>
                )}
                {!!expandable ? <ExpandCell className="expand" expanded={expanded} align="left" /> : <Cell />}
                <Cell title={element.name} style={style}>
                    {narrow ? (
                        <>
                            <div>
                                <DisabledItemHoverInfotip
                                    active={!togglerEnabled && !isArticle}
                                    infotipText={toggleDisabledReason}
                                    width="30em"
                                    offsetTop={3}
                                    offsetLeft="3.5em"
                                >
                                    <Toggler
                                        className="status-toggle"
                                        isOn={status}
                                        onClick={(v: boolean) => updateStatus(v)}
                                        disabled={!isArticle && !togglerEnabled}
                                    />
                                </DisabledItemHoverInfotip>
                            </div>
                            <NameAndIconWrapper>
                                <Name className="name">
                                    <UnstyledLink
                                        to={linkToDetailsPage}
                                        onClick={onLinkClick}
                                        disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                                    >
                                        {element.name}
                                    </UnstyledLink>
                                </Name>
                                {showHiddenIcon && <EyeIcon icon={faEyeSlash} title={hiddenIconMesage} />}
                            </NameAndIconWrapper>
                        </>
                    ) : (
                        <NameAndStatus style={{ width: '100%' }}>
                            <NameAndIconWrapper>
                                <Name>
                                    <Bold className="name">
                                        <UnstyledLink
                                            to={linkToDetailsPage}
                                            onClick={onLinkClick}
                                            disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                                        >
                                            {element.name}
                                        </UnstyledLink>
                                    </Bold>
                                </Name>
                                {showHiddenIcon && <EyeIcon icon={faEyeSlash} title={hiddenIconMesage} />}
                            </NameAndIconWrapper>
                            <div style={{ width: 'max-content' }}>
                                <DisabledItemHoverInfotip
                                    active={!togglerEnabled && !isArticle}
                                    infotipText={toggleDisabledReason}
                                    width="15.5em"
                                    offsetTop={31}
                                    offsetLeft="3.5em"
                                >
                                    <TogglerWithText
                                        isOn={status}
                                        onClick={(v: boolean) => updateStatus(v)}
                                        disabled={!togglerEnabled && !isArticle}
                                    />
                                </DisabledItemHoverInfotip>
                            </div>
                        </NameAndStatus>
                    )}
                </Cell>
                {isArticle ? (
                    <>
                        <Cell className="priority" onClick={(e) => e.stopPropagation()}>
                            <InlineEdit
                                isNumber
                                integerOnly
                                min={1}
                                max={1000}
                                id="input"
                                limitedTextWidth="5em"
                                value={articlePriority ? articlePriority : ''}
                                placeholder="Enter"
                                onEditAccept={updateArticlePriority}
                                style={withDuplicatePrio ? { fontWeight: 'bold' } : {}}
                                disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                            />
                        </Cell>
                        <Cell className="type">{pricingType}</Cell>
                        <Cell align="right" className="productMinAcceptance">
                            {!prices || prices.minAcceptedPrice === null || pricingType === 'Static' ? (
                                '-'
                            ) : (
                                <Money amount={prices.minAcceptedPrice} accountSlug={accountSlug} />
                            )}
                        </Cell>
                        <Cell align="right" className="productBoxOffice" onClick={(e) => e.stopPropagation()}>
                            <InlineEdit
                                isNumber
                                min={0}
                                maxLength={7}
                                id="input"
                                limitedTextWidth="5em"
                                value={!prices || prices.originalPrice === null ? '-' : prices.originalPrice}
                                placeholder="Enter"
                                onEditAccept={updateOriginalPrice}
                                disabled={!props.hasPermission('edit_pricing_settings', props.accountSlug)}
                            />
                        </Cell>
                        <Cell className="withName wrapContent validity">
                            <DateRange
                                startDateString={elementAsArticle.validFrom}
                                endDateString={elementAsArticle.validTo}
                            />
                        </Cell>
                        <Cell
                            align="right"
                            style={{ overflow: 'visible', paddingRight: '0' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {canViewProductValidityOrPricing && (
                                <ActionMenu
                                    history={history}
                                    accountSlug={accountSlug}
                                    hasPermission={props.hasPermission}
                                    deleteArticle={deleteArticle}
                                    deleteProductList={deleteProductList}
                                    duplicateProduct={props.duplicateProduct}
                                    element={element}
                                    isArticle
                                    origin={origin}
                                />
                            )}
                        </Cell>
                    </>
                ) : (
                    <>
                        <Cell className="priority" onClick={(e) => e.stopPropagation()}>
                            <InlineEdit
                                isNumber
                                integerOnly
                                min={1}
                                max={1000}
                                id="input"
                                limitedTextWidth="5em"
                                value={catPriority ? catPriority : ''}
                                placeholder="Enter"
                                onEditAccept={updateCatPriority}
                                style={withDuplicatePrio ? { fontWeight: 'bold' } : {}}
                                disabled={!props.hasPermission('partner_admin', props.accountSlug)}
                            />
                        </Cell>
                        <Cell className="type">List</Cell>
                        <Cell />
                        <Cell />
                        <Cell />
                        <Cell
                            align="right"
                            style={{ overflow: 'visible', paddingRight: '0' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {props.hasPermission('partner_admin', props.accountSlug) && (
                                <ActionMenu
                                    history={history}
                                    accountSlug={accountSlug}
                                    hasPermission={props.hasPermission}
                                    deleteArticle={deleteArticle}
                                    deleteProductList={deleteProductList}
                                    element={element}
                                    isArticle={false}
                                    origin={origin}
                                />
                            )}
                        </Cell>
                    </>
                )}
                <Cell noPadding />
            </DynamicRow>
        </>
    )
}
