import React, { useEffect, useState } from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { User } from 'auth/state'
import { delay } from 'utils'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { TableLoader, PageLoader } from 'uiComponents/loaders'
import { withFeatures } from 'features'
import { TemplatePreview } from '../preview'
import {
    ToggleWithValuesComponent,
    FormItem,
    SectionWrapper,
    PreviewSize,
    PreviewContainer,
} from 'ticketTemplates/studio/imports'
import { withMessages, MessageProps, withCurrentUser } from 'hocs'
import { Messages } from 'uiComponents/messages'
import { withPermission } from 'admin/hocs'
import { Row, Col } from 'uiComponents/flex'
import { Header } from '../header'
import { Debounce } from 'debounce'
import { TogglesSection } from '../togglesSection'
import { DownloadSection } from '../downloadSection'
import LanguagesSection from './languagesSection'
import { ArticleServiceContext } from 'admin/context'
import { Locale, LocalesInfo } from 'admin/articleService'
import { UploadElement } from '../form/uploadElement'
import { ImagesServiceContext } from 'http/context'
import { Menu } from 'uiComponents/studio/minimisedMenuVersion'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import {
    TicketTemplatesService,
    Translation,
    TemplateBody,
    PriceVisibilityType,
} from 'ticketTemplates/ticketTemplatesService'
import { LoginService } from 'http/loginService'
import useGetDefaultTaxConfig from 'settings/accountSettings/taxConfigurations/hooks/useGetDefaultTaxConfig'

interface TicketTemplatesStudioPageProps {
    accountSlug: string
    history: History
    user: User
    hasFeature: (feature: string, accountSlug: string) => boolean
    getComponentValue: (component: string, accountSlug: string) => any
    hasPermission: (permission: string, accountSlug: string) => boolean
    accounts: Account[]
    ticketTemplatesService: TicketTemplatesService
    match: RouteMatch<{ id: string }>
    navigation: Navigation
    backofficeEndpoint: string
    loginService: LoginService
}

export type TranslatableField = 'moreInfoText' | 'termsConditionsText'

const InfoText = styled.div`
    font-size: 0.7rem;
    color: ${(p) => p.theme.colors.textLight};
    margin-top: 0.8em;
    font-weight: 400;
`

export const Page = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    display: flex;
    flex: 1;
    position: relative;
    justify-content: center;
`

export const Body = styled(Row)`
    margin-top: 6em;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const Form = styled.form`
    display: flex;
`

export const FormBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.colors.white};
    width: 30em;
`

export const ScrollArea = styled.div`
    overflow: auto;
    flex: 1;
    margin-top: 6em;
    margin-left: 4.4em;
    padding: 1em;
`

export const Preview = styled(PreviewContainer)`
    background-color: ${(p) => p.theme.colors.tableRow};
`

function TicketTemplatesStudioPage(props: TicketTemplatesStudioPageProps & MessageProps) {
    const didMountRef = React.useRef(false)
    const updateCountRef = React.useRef(0)
    const imagesService = React.useContext(ImagesServiceContext)
    type UploadType = 'upload'
    type ToggleType = 'toggle'
    const imageTypeField = {
        name: 'image-type',
        type: 'toggle' as ToggleType,
        label: 'Image type',
        path: 'imageType',
        defaultValue: '',
    }
    const uploadField = {
        name: 'image-upload',
        type: 'upload' as UploadType,
        label: '',
        path: 'imagePath',
        defaultValue: '',
    }

    const [loading, setLoading] = useState<boolean>(false)
    const [previewSize, setPreviewSize] = React.useState<PreviewSize>('desktop')
    const [activeLanguage, setActiveLanguage] = React.useState<string>('')
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const [name, setName] = React.useState<string>('')
    const [additionalInfo, setAdditionalInfo] = useState<Translation | null>(null)
    const [additionalInfoError, setAdditionalInfoError] = useState<string>('')
    const [localesInfo, setLocalesInfo] = useState<LocalesInfo | null>(null)
    const [printingRequired, setPrintingRequired] = React.useState<boolean>(true)
    const [showFullName, setShowFullName] = React.useState<boolean>(false)
    const [showOrderNo, setShowOrderNo] = React.useState<boolean>(false)
    const [showEmail, setShowEmail] = React.useState<boolean>(false)
    const [showOptions, setShowOptions] = React.useState<boolean>(false)
    const [showBarcode, setShowBarcode] = React.useState<boolean>(true)
    const [showBarcodeNumber, setShowBarcodeNumber] = React.useState<boolean>(true)
    const [showPrice, setShowPrice] = React.useState<boolean>(true)
    const [showPriceResellers, setShowPriceResellers] = React.useState<boolean>(true)
    const [showTapQrCode, setShowTapQrCode] = React.useState<boolean>(true)
    const [image, setImage] = React.useState<string>('')
    const [useBannerImage, setUseBannerImage] = React.useState<boolean>(true)
    const [showTax, setShowTax] = React.useState<boolean>(false)
    const [showSupportEmail, setShowSupportEmail] = React.useState<boolean>(false)
    const [showPhoneNumber, setShowPhoneNumber] = React.useState<boolean>(false)
    const [showOpeningHours, setShowOpeningHours] = React.useState<boolean>(false)
    const [showAddress, setShowAddress] = React.useState<boolean>(false)
    const [templateHtml, setTemplateHtml] = React.useState<string>('')
    const [typing, setTyping] = React.useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = React.useState<boolean>(false)
    const [defaultTaxExist, setDefaultTaxExist] = React.useState<boolean>(false)
    const articleService = React.useContext(ArticleServiceContext)
    const defaultTaxConfig = useGetDefaultTaxConfig(props.accountSlug)

    const createEmptyTranslations = (locales: Locale[]): Translation => {
        const translations = {
            key: 'templateInformation',
            text: {},
        }
        locales.forEach((l) => (translations.text[l.code] = ''))
        return translations
    }

    useEffect(() => {
        if (!additionalInfoError) {
            props.removeAllMessages()
        }
    }, [additionalInfoError])

    async function getData() {
        try {
            const localesDetails = await articleService.getAccountLocales(props.accountSlug)
            setLocalesInfo(localesDetails)
            const id = props.match.params.id
            const config = await props.ticketTemplatesService.getTemplate(props.accountSlug, id)
            if (config.additionalInfo) {
                setAdditionalInfo(config.additionalInfo)
            } else {
                setAdditionalInfo(createEmptyTranslations(localesDetails.locales))
            }
            setImage(config.image)
            setName(config.name)
            setShowBarcode(config.showBarcode)
            setShowBarcodeNumber(config.showBarcodeNumber)
            setShowPrice(config.hidePrice !== 'ALWAYS')
            setShowPriceResellers(config.hidePrice === 'NEVER')
            setShowTax(config.showVat)
            setShowFullName(!!config.showFullName)
            setShowEmail(config.showEmail)
            setShowOrderNo(config.showOrderNo)
            setShowOptions(config.showOptions || false)
            setPrintingRequired(config.printingRequired || false)
            setUseBannerImage(config.useBannerImage || false)
            setShowTapQrCode(config.showTapQrCode || false)
            setShowSupportEmail(config.showSupportEmail || false)
            setShowPhoneNumber(config.showPhoneNumber || false)
            setShowOpeningHours(config.showOpeningHours || false)
            setShowAddress(config.showAddress || false)
            setAccountLocales(localesDetails.locales)
            setActiveLanguage(localesDetails.defaultLocale || 'en')
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
        updateTemplateHtml()
        setPreviewSize('desktop')
       defaultTaxConfig && setDefaultTaxExist(true)

        return () => {
            if (getTemplateHtmlLater) {
                getTemplateHtmlLater.clear()
            }
        }
    }, [])

    useEffect(() => {
        if (typing) {
            return
        }
        didMountRef.current ? updateTemplateHtml() : (didMountRef.current = true)
    }, [
        activeLanguage,
        useBannerImage,
        printingRequired,
        typing,
        image,
        showTapQrCode,
        showTax,
        showBarcode,
        showBarcodeNumber,
        showPrice,
        showPriceResellers,
        showFullName,
        showOptions,
        showPhoneNumber,
        showSupportEmail,
        showAddress,
        showOpeningHours,
    ])

    useEffect(() => {
        didMountRef.current ? updateTemplateHtml() : (didMountRef.current = true)
    }, [additionalInfo])

    const isAdditionalInfoValid = (): boolean => {
        if (!localesInfo) {
            return false
        }
        let langWithError = ''
        let atLeastOneTranslation = false
        localesInfo.locales.forEach((loc) => {
            if (!additionalInfo?.text[loc.code]) {
                langWithError = loc.code
            } else {
                atLeastOneTranslation = true
            }
        })
        setActiveLanguage(langWithError)

        if (atLeastOneTranslation) {
            return !langWithError
        }
        return true
    }

    const areTranslationsEmpty = (): boolean => {
        if (!localesInfo) {
            return true
        }
        const atLeastOneTranslation = localesInfo.locales.every((loc) => {
            return !!additionalInfo?.text[loc.code]
        })
        return !atLeastOneTranslation
    }

    const getHidePriceValue = (): PriceVisibilityType => {
        return !showPrice ? 'ALWAYS' : !showPriceResellers ? 'RESELLERS_ONLY' : 'NEVER'
    }

    const getPayload = () => {
        const payload: TemplateBody = {
            name,
            image,
            useBannerImage,
            printingRequired,
            showTapQrCode,
            showVat: showTax,
            showBarcode,
            showBarcodeNumber,
            showFullName,
            showPhoneNumber,
            showAddress,
            showOpeningHours,
            showSupportEmail,
            showEmail,
            showOrderNo,
            showOptions,
            hidePrice: getHidePriceValue(),
            additionalInfo: areTranslationsEmpty() ? null : additionalInfo,
            templateType: 'TICKET',
        }
        return payload
    }

    const saveSettings = async () => {
        if (!isAdditionalInfoValid()) {
            setAdditionalInfoError('You must fill all the translations')
            props.replaceMessages('validation_error', 'error', 'Additional info tanslations are not valid.')
            return
        }
        try {
            setLoading(true)
            const payload = getPayload()
            await props.ticketTemplatesService.updateTemplate(props.accountSlug, props.match.params.id, payload)
            getData()
            setLoading(false)
            props.replaceMessages('success', 'success', 'The template has been saved successfully.')
            await delay(4000)
            props.removeAllMessages()
        } catch {
            setLoading(false)
            props.replaceMessages('server_error', 'error', 'Could not save the template. Please try again later.')
        }
    }

    const setTranslations = (v: string) => {
        if (!additionalInfo) {
            return
        }
        props.removeAllMessages()
        const translations = { ...additionalInfo }
        translations.text[activeLanguage] = v
        setAdditionalInfo(translations)
    }

    const updateTemplateHtml = () => {
        if (!name || !activeLanguage) {
            return
        }
        const settings: TemplateBody = {
            ...getPayload(),
            additionalInfo: additionalInfo,
            language: activeLanguage,
        }
        if (!previewLoading) {
            setPreviewLoading(true)
        }
        updateCountRef.current += 1
        getTemplateHtmlLater.trigger(settings)
    }

    const getTemplateHtmlLater = new Debounce(async (settings: TemplateBody) => getTemplateHtml(settings), 1000)

    const getTemplateHtml = async (settings: TemplateBody) => {
        try {
            props.hideMessage('preview_error')
            const html = await props.ticketTemplatesService.getTemplateHtml(props.accountSlug, settings)
            setTemplateHtml(html)
        } catch {
            props.replaceMessages(
                'preview_error',
                'error',
                'Oops! We could not load the preview. Please try again later.',
            )
        } finally {
            updateCountRef.current -= 1
            if (updateCountRef.current < 1) {
                setPreviewLoading(false)
            }
        }
    }

    const getPreviewSetttingsForDownload = () => {
        const settings = {
            name,
            image,
            template_type: 'TICKET',
            use_banner_image: useBannerImage,
            printing_required: printingRequired,
            show_tap_qr_code: showTapQrCode,
            show_vat: showTax,
            show_barcode: showBarcode,
            show_barcode_number: showBarcodeNumber,
            show_full_name: showFullName,
            show_phone_number: showPhoneNumber,
            show_support_email: showSupportEmail,
            show_opening_hours: showOpeningHours,
            show_address: showAddress,
            hide_price: getHidePriceValue(),
            additional_info: additionalInfo,
            language: activeLanguage,
        }
        return settings
    }

    return (
        <Page id="ticket-template-studio">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <Menu />
            <Form noValidate>
                <FormBody>
                    {!loading && (
                        <>
                            <Header
                                history={props.history}
                                accountSlug={props.accountSlug}
                                onSave={saveSettings}
                                hasPermissionToSave={props.hasPermission('partner_admin', props.accountSlug)}
                            />
                            <ScrollArea id="scroll-area">
                                <SectionWrapper title="Ticket Image">
                                    <FormItem>
                                        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                            <label htmlFor="image-type">Select image type</label>
                                        </Col>
                                        <Col span={6} className="justify-right">
                                            <ToggleWithValuesComponent
                                                field={imageTypeField}
                                                handleChange={(v) => setUseBannerImage(v === 'banner')}
                                                value={useBannerImage ? 'banner' : 'logo'}
                                                options={[
                                                    { name: 'Logo', value: 'logo' },
                                                    { name: 'Banner', value: 'banner' },
                                                ]}
                                            />
                                        </Col>
                                    </FormItem>
                                    <FormItem>
                                        <Col span={12}>
                                            <UploadElement
                                                key={uploadField.name}
                                                field={uploadField}
                                                style={{}}
                                                handleChange={(url) => setImage(url as string)}
                                                imageOptions={{
                                                    max_width: useBannerImage ? 2400 : 480,
                                                    max_height: useBannerImage ? 480 : 200,
                                                }}
                                                value=""
                                                imagesService={imagesService}
                                                replaceTopMessages={props.replaceMessages}
                                                removeAllMessages={props.removeAllMessages}
                                                type="img"
                                            />
                                            <InfoText>
                                                {useBannerImage
                                                    ? '* Image must not be larger than 2400px width by 480px height'
                                                    : '* Image must not be larger than 480px width by 200px height'}
                                            </InfoText>
                                        </Col>
                                    </FormItem>
                                </SectionWrapper>
                                {additionalInfo && !!activeLanguage && (
                                    <LanguagesSection
                                        activeLanguage={activeLanguage}
                                        locales={accountLocales}
                                        handleLanguageChange={setActiveLanguage}
                                        handleTranslations={(v: string) => setTranslations(v)}
                                        moreInformation={additionalInfo}
                                        accountSlug={props.accountSlug}
                                        onFinishTyping={(v) => setTyping(false)}
                                        additionalInfoError={additionalInfoError}
                                    />
                                )}
                                {(!additionalInfo || !activeLanguage) && <TableLoader />}
                                <TogglesSection
                                    accountSlug={props.accountSlug}
                                    templateType="TICKET"
                                    showBarcode={showBarcode}
                                    showBarcodeNumber={showBarcodeNumber}
                                    showPrice={showPrice}
                                    showPriceResellers={showPriceResellers}
                                    showTax={showTax}
                                    defaultTaxExist={defaultTaxExist}
                                    showFullName={showFullName}
                                    showEmail={showEmail}
                                    showOrderNumber={showOrderNo}
                                    showOptions={showOptions}
                                    showTapQrCode={showTapQrCode}
                                    printingRequired={printingRequired}
                                    showSupportEmail={showSupportEmail}
                                    showPhoneNumber={showPhoneNumber}
                                    showAddress={showAddress}
                                    showOpeningHours={showOpeningHours}
                                    setShowBarcode={setShowBarcode}
                                    setShowBarcodeNumber={setShowBarcodeNumber}
                                    setShowPrice={setShowPrice}
                                    setShowPriceResellers={setShowPriceResellers}
                                    setShowTax={setShowTax}
                                    setShowFullName={setShowFullName}
                                    setShowEmail={setShowEmail}
                                    setShowOrderNumber={setShowOrderNo}
                                    setShowOptions={setShowOptions}
                                    setShowTapQrCode={setShowTapQrCode}
                                    setPrintingRequired={setPrintingRequired}
                                    setShowSupportEmail={setShowSupportEmail}
                                    setShowPhoneNumber={setShowPhoneNumber}
                                    setShowAddress={setShowAddress}
                                    setShowOpeningHours={setShowOpeningHours}
                                />
                                <DownloadSection
                                    accountSlug={props.accountSlug}
                                    backofficeEndpoint={props.backofficeEndpoint}
                                    loginService={props.loginService}
                                    downloadData={Object.assign({
                                        ...getPreviewSetttingsForDownload(),
                                    })}
                                />
                            </ScrollArea>
                        </>
                    )}
                    {loading && <PageLoader />}
                </FormBody>
            </Form>
            <Body>
                <Preview className={previewSize}>
                    <TemplatePreview templateHtml={templateHtml} loading={previewLoading || loading} />
                </Preview>
            </Body>
        </Page>
    )
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withPermission(
    withFeatures(withNavigation(withCurrentUser(withMessages(connect(mapStateToProps)(TicketTemplatesStudioPage))))),
)
