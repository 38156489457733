import { useGetTaxConfigurationsQuery } from '../rtkQuery'

const useGetDefaultTaxConfig = (activeAccount: string) => {
    const { data } = useGetTaxConfigurationsQuery(activeAccount)
    const taxConfigurations = data?.taxConfigurations
    const defaultTaxConfigurationUuid = data?.defaultTaxConfigurationUuid
    const defaultTaxConfiguration = taxConfigurations?.find(
        (taxConfiguration) => taxConfiguration.uuid === defaultTaxConfigurationUuid,
    )

    return defaultTaxConfiguration
}

export default useGetDefaultTaxConfig
