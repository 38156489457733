import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { NoDataNotice, tooltipStyle } from 'uiComponents/charts/styleComponents'
import { mapChartDataColor } from 'reports/helpers'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import cloneDeep from 'lodash/cloneDeep'
import { addSeparators } from 'utils'

export interface ChartData {
    value: number
    name: string
}

interface PieChartProps {
    title?: string
    data: ChartData[]
    theme: DashboardTheme
    chartHeight?: string
    legendTop?: number
    pieRadius?: string | string[]
    pieCenter?: string[]
    currency?: boolean
    accountSlug?: string
    loading: boolean
    customSorting?: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

interface PieChartState {
    chartOption: any
    data: ChartData[]
}

class PieChart extends React.Component<PieChartProps & React.HTMLAttributes<HTMLElement>, PieChartState> {
    constructor(props: PieChartProps & React.HTMLAttributes<HTMLElement>) {
        super(props)
        this.state = {
            chartOption: {},
            data: cloneDeep(this.props.data),
        }
    }

    mapDataLabel = () => {
        if (this.props.data) {
            if (!this.props.customSorting) {
                this.state.data.sort((a, b) => b.value - a.value)
            }
            return this.state.data.map((d) => {
                const value =
                    this.props.currency && this.props.accountSlug
                        ? this.props.formatCurrencyString(d.value.toFixed(2), this.props.accountSlug)
                        : addSeparators(d.value)
                d.name = `${d.name}: ${value}`
                return d
            })
        }
        return []
    }
    loadPieChartData = () => {
        const pieChartOption: any = {
            textStyle: {
                fontFamily: 'Roboto',
            },
            title: {
                text: this.props.title || '',
                textStyle: {
                    fontSize: 20,
                    fontWeight: 'normal',
                },
                left: 'left',
                padding: [10, 0, 0, 0],
            },
            tooltip: {
                confine: true,
                trigger: 'item',
                formatter: '{b} ({d}%)',
                position: (p: number[]) => {
                    return [p[0] - 40, p[1] + 20]
                },
                ...tooltipStyle,
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                top: this.props.legendTop ? this.props.legendTop : 350,
                left: 10,
                height: 118,
                itemHeight: 14.5,
                itemGap: 8,
                data: this.mapDataLabel(),
                selectedMode: false,
            },
            series: [
                {
                    type: 'pie',
                    radius: this.props.pieRadius ? this.props.pieRadius : '60%',
                    center: this.props.pieCenter ? this.props.pieCenter : ['50%', '37%'],
                    data: mapChartDataColor(this.state.data, this.props.theme),
                    label: {
                        show: false,
                    },
                },
            ],
        }
        this.setState({
            chartOption: pieChartOption,
        })
    }
    componentDidMount() {
        this.loadPieChartData()
    }
    componentDidUpdate(prevProps: PieChartProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: cloneDeep(this.props.data) }, () => this.loadPieChartData())
        }
    }
    render() {
        return (
            <NoDataNotice dataLength={this.state.data.length} loading={this.props.loading}>
                <ReactEcharts
                    option={this.state.chartOption}
                    style={{
                        height: this.props.chartHeight ? this.props.chartHeight : '480px',
                        ...this.props.style,
                    }}
                    notMerge={true}
                    theme="pricing_theme"
                />
            </NoDataNotice>
        )
    }
}

export default withTheme(withCurrency(PieChart))
