import React, { useCallback, useMemo } from 'react'
import { FormikReactTableField } from 'uiComponents/table/reactTable'
import { useBulkRefundTableColumns } from './columns'
import { withCurrency, CurrencyProps } from 'uiComponents/money/moneyHoc'
import { OrderRefundDetails } from '../../types'

interface RefundTableProps extends CurrencyProps {
    orders: OrderRefundDetails[]
    isOrderDisabled: (order: OrderRefundDetails) => boolean
    cancellation: boolean
}

const RefundTable: React.FC<RefundTableProps> = ({ isOrderDisabled, orders, cancellation, getCurrency }) => {
    const columns = useBulkRefundTableColumns({
        isOrderDisabled,
        cancellation,
        getCurrency,
    })
    const disableRowFromSelecting = useCallback((row) => isOrderDisabled(row.original as OrderRefundDetails), [])
    const bodyProps = useMemo<React.HTMLAttributes<HTMLDivElement>>(
        () => ({
            style: {
                maxHeight: 320,
                overflowY: 'auto',
            },
        }),
        [],
    )

    return (
        <FormikReactTableField
            rowSelect
            sort
            size="small"
            data={orders}
            elevation={false}
            bodyProps={bodyProps}
            valueField="id"
            name="orders"
            disableRowFromSelecting={disableRowFromSelecting}
            columns={columns}
            storeStateInQuery={false}
        />
    )
}

export default withCurrency(RefundTable)
