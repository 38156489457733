import * as React from 'react'
import styled from 'styled-typed'
import { usePrevious } from 'reactUtils'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { BookingCodesService, DiscountsPool } from 'venue/bookingCodes/bookingCodesService'
import { SearchSection } from './searchSection'
import { InlineDataTable, HeaderRow, TableHeader, Cell, DataRow } from 'uiComponents/table'
import { TableLoader, ChartDataLoader } from 'uiComponents/loaders'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { Text } from 'uiComponents/typography'
import { ActionButtonA } from 'uiComponents/buttons'
import { Sorting, Pagination } from 'uiComponents/table'
import { PaginationSection } from 'uiComponents/table/pagination'
import { SmallText } from 'uiComponents/typography'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { InlineEdit } from 'uiComponents/input'
import { NewPoolDialog } from './newPoolDialog'
import { addSeparators, delay } from 'utils'

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SpinnerIcon = styled(FontAwesomeIcon)`
    font-size: 0.8em;
    margin-left: 0.5em;
`

interface BarcodePoolsPageProps {
    accountSlug: string
    bookingCodesService: BookingCodesService
    navigation: Navigation
    match: RouteMatch<{}>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function DiscountPoolsPage(props: BarcodePoolsPageProps) {
    const busyRef = React.useRef(false)
    const monitorUpdateRef = React.useRef(false)
    const searchRef = React.useRef('')
    const sortRef = React.useRef({ prop: 'name', direction: 'asc' })
    const paginationRef = React.useRef({ page: 1, pageSize: 10 })
    const accountRef = React.useRef(props.accountSlug)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [creating, setCreating] = React.useState<boolean>(false)
    const [poolsList, setPoolsList] = React.useState<DiscountsPool[]>([])
    const [sort, setSort] = React.useState<Sorting>({
        prop: 'name',
        direction: 'asc',
    })
    const [pagination, setPagination] = React.useState<Pagination>({
        page: 1,
        pageSize: 10,
    })
    const [totalPools, setTotalPools] = React.useState<number>(0)
    const [depletedPools, setDepletedPools] = React.useState<number>(0)

    async function getPoolsList() {
        const page = paginationRef.current.page
        const pageSize = paginationRef.current.pageSize
        const search = searchRef.current ? `&search=${searchRef.current}` : ''
        const sortBy = sortRef.current.prop
        const sortDirection = sortRef.current.direction
        const query = `?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}${search}`
        const data = await props.bookingCodesService.getDiscountPoolsList(accountRef.current, query)

        monitorUpdateRef.current = !!data.entries.find((p) => p.uploadStatus === 'pending')
        setTotalPools(data.totalCount)
        setDepletedPools(data.depletedCount)
        setPagination({ page: data.currentPage, pageSize: data.pageSize })
        setPoolsList(data.entries)
    }

    const updateLoaders = (action: 'load' | 'update' | null, state: boolean) => {
        if (action === 'load') {
            setLoading(state)
            busyRef.current = state
        } else if (action === 'update') {
            setUpdating(state)
            busyRef.current = state
        }
    }

    async function getListData(action: 'load' | 'update' | null = null) {
        updateLoaders(action, true)
        try {
            await getPoolsList()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            updateLoaders(action, false)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (monitorUpdateRef.current && !busyRef.current) {
                getListData()
            }
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    const prevAccount = usePrevious(props.accountSlug)
    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    React.useEffect(() => {
        const newQuery = props.navigation.query()
        const sortDirection = newQuery.sortDirection === 'desc' ? 'desc' : 'asc'
        const sortChanged =
            (prevQuery.sortBy !== newQuery.sortBy && sort.prop !== newQuery.sortBy) || sortDirection !== sort.direction
        const paginationChanged =
            (prevQuery.page !== newQuery.page && String(pagination.page) !== newQuery.page) ||
            (prevQuery.pageSize !== newQuery.pageSize && String(pagination.pageSize) !== newQuery.pageSize)
        const searchChanged = prevQuery.q !== newQuery.q

        if (searchChanged) {
            searchRef.current = newQuery.q
        }
        if (sortChanged) {
            const newSort = {
                prop: newQuery.sortBy,
                direction: sortDirection as 'asc' | 'desc',
            }
            setSort(newSort)
            sortRef.current = newSort
        }
        if (paginationChanged) {
            const newPagination = {
                page: isNaN(Number(newQuery.page)) ? 1 : Number(newQuery.page),
                pageSize: isNaN(Number(newQuery.pageSize)) ? 10 : Number(newQuery.pageSize),
            }
            setPagination(newPagination)
            paginationRef.current = newPagination
        }

        if (props.accountSlug !== prevAccount) {
            accountRef.current = props.accountSlug
            getListData('load')
        } else if (sortChanged || paginationChanged || searchChanged) {
            getListData('update')
        }
    }, [props.navigation.query(), props.accountSlug])

    const onSortChanged = (sorting: Sorting) => {
        props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    const onPaginationChanged = (newPagination: Pagination) => {
        props.navigation.addQueryWithReplace({
            page: newPagination.page.toString(),
            pageSize: newPagination.pageSize.toString(),
        })
    }

    const getCodesLeftText = (item: DiscountsPool) => {
        const status =
            item.couponsLeft === null
                ? undefined
                : item.couponsLeft > 50
                ? 'success'
                : item.couponsLeft > 0
                ? 'processing'
                : 'error'
        return (
            <StatusContainer>
                {item.couponsLeft === null ? <>-</> : <Text status={status}>{addSeparators(item.couponsLeft)}</Text>}
                {item.uploadStatus === 'pending' && (
                    <SmallText style={{ marginTop: '.2em' }}>
                        New upload pending
                        <SpinnerIcon icon={faSpinner as IconProp} className="fa-pulse" />
                    </SmallText>
                )}
            </StatusContainer>
        )
    }

    const onSaveNewPool = async (name: string) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.createDiscountPool(props.accountSlug, name)
            await getPoolsList()
            setCreating(false)
            updateLoaders('update', false)
            props.replaceMessages('success', 'success', 'Coupons pool was created successfully.')
            await delay(7000)
            props.removeAllMessages()
        } catch {
            updateLoaders('update', false)
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not create a new coupons pool. Please try again later.',
            )
        }
    }

    const onNameEditAccept = async (poolId: string, name: string) => {
        updateLoaders('update', true)
        try {
            await props.bookingCodesService.editDiscountPoolName(props.accountSlug, poolId, name)
            await getPoolsList()
        } catch {
            props.replaceMessages(
                'unknown_error',
                'error',
                'Oops! We could not update the name. Please try again later.',
            )
        } finally {
            updateLoaders('update', false)
        }
    }

    const columnWidths = [null, '13em', '11em', '8em']

    return (
        <>
            {creating && (
                <NewPoolDialog
                    accountSlug={props.accountSlug}
                    loading={updating}
                    onCancel={() => setCreating(false)}
                    onConfirm={onSaveNewPool}
                />
            )}
            {loading && <TableLoader />}
            {!loading && (
                <>
                    <SearchSection
                        poolsCount={totalPools}
                        depletedPools={depletedPools}
                        onAddNew={() => setCreating(true)}
                    />
                    <InlineDataTable columnWidths={columnWidths} bordered id="discounts-pools-list">
                        <HeaderRow>
                            <TableHeader sortKey="name" sorting={sort} changeSort={onSortChanged}>
                                Name
                            </TableHeader>
                            <TableHeader align="center">Post-event email</TableHeader>
                            <TableHeader>Coupons left</TableHeader>
                            <TableHeader nonInteractive />
                        </HeaderRow>
                        {updating && !loading && <ChartDataLoader />}
                        {poolsList.length === 0 && !creating && <NoResultsRow text="There are no coupon pools yet." />}
                        {poolsList.length > 0 &&
                            poolsList.map((item, i) => (
                                <div key={i}>
                                    <DataRow>
                                        <Cell title={item.name} className="pool-name">
                                            <InlineEdit
                                                id="new-pool-name"
                                                value={item.name}
                                                maxLength={100}
                                                onEditAccept={(v) => onNameEditAccept(item.uuid, v)}
                                                responsiveWidth
                                            />
                                        </Cell>
                                        <Cell className="email-attached" align="center">
                                            {!item.assignedToPostEventEmails && '-'}
                                            {item.assignedToPostEventEmails && (
                                                <FontAwesomeIcon icon={faCheck as IconProp} />
                                            )}
                                        </Cell>
                                        <Cell className="codes-left">{getCodesLeftText(item)}</Cell>
                                        <Cell align="right">
                                            <ActionButtonA
                                                kind="action"
                                                secondary
                                                href={`/account/${props.accountSlug}/venue/codes/coupons/upload/${item.uuid}?${location.search}`}
                                            >
                                                Upload
                                            </ActionButtonA>
                                        </Cell>
                                    </DataRow>
                                </div>
                            ))}
                    </InlineDataTable>
                    <PaginationSection
                        pagination={pagination}
                        onPaginationChanged={onPaginationChanged}
                        totalItemsCount={totalPools}
                        pageSizeOptions={['10', '20', '50']}
                        style={{ margin: '2em' }}
                        onWhiteBackground
                    />
                </>
            )}
        </>
    )
}

export default withNavigation(DiscountPoolsPage)
