import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import {
    areDateRangeDatesEqual,
    mapTimeseriesForTooltip,
    getTooltipRow,
    getTooltipXAxisLabel,
    getTooltipHeader,
    getTooltipFooter,
} from 'reports/helpers'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { Filters } from 'uiComponents/filter/schema'
import { NotificationCampaignsTimeseriesStats } from 'reports/schema'
import { DataPoint } from 'reports/schema'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import isEqual from 'lodash/isEqual'
import { queryConfig } from './utils'
import TimeseriesBarChart from 'uiComponents/charts/timeseriesBarChart'
import { addSeparators } from 'utils'

interface ChartProps {
    dateRange: DateRange
    filters: Filters[]
    accountSlug: string
    chart: TimeseriesBarChartSource
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
}

function Chart(props: ChartProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [mappedSalesTotals, setMappedSalesTotals] = useState<DataPoint[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        _isMounted.current = true
        if (
            (prevAccountSlug !== props.accountSlug && !isEqual(prevFilters, props.filters)) ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters)
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange, props.filters])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const dateFrom = format(props.chart.from(), 'yyyy-MM-dd')
        const dateTo = format(props.chart.to(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'app_notifications_summary',
                dateFrom,
                dateTo,
                filters: props.filters,
                granularity: props.chart.granularity,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            const sentSeries = {
                label: data.sent.timeSeries[0].points.length > 0 ? 'Sent' : null,
                points: data.sent.timeSeries[0].points,
            }

            const openedSeries = {
                label: data.opened.timeSeries[0].points.length > 0 ? 'Opened' : null,
                points: data.opened.timeSeries[0].points,
            }

            const clickedSeries = {
                label: data.clicked.timeSeries[0].points.length > 0 ? 'Clicked' : null,
                points: data.clicked.timeSeries[0].points,
            }

            const timeseries = [sentSeries, openedSeries, clickedSeries]
            props.chart.formatSeries(timeseries)

            setData(data)
        } catch {
            setLoading(false)
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Notification campaigns chart could not be loaded, please try again later.',
            )
        }
    }

    const setData = (data: NotificationCampaignsTimeseriesStats) => {
        const granularity = props.chart.customGranularity ? 'customTimeseries' : props.chart.granularity
        const xAxisCategories = props.chart.props().xAxisDataRaw
        const mappedSalesTotalsData =
            data.sent.timeSeries.length > 0
                ? mapTimeseriesForTooltip(xAxisCategories, data.sent.timeSeries[0].points, granularity)
                : []

        if (_isMounted.current) {
            setMappedSalesTotals(mappedSalesTotalsData)
            setLoading(false)
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const totalSalesPoint = mappedSalesTotals[params[0].dataIndex]
        const granularity = props.chart.customGranularity ? 'customTimeseries' : props.chart.granularity
        const xAxisLabel = totalSalesPoint
            ? getTooltipXAxisLabel(totalSalesPoint.timestamp, granularity)
            : params[0].axisValue
        params.sort((a, b) => Number(b.value) - Number(a.value))

        let tooltip = getTooltipHeader(xAxisLabel)
        params.forEach((p) => {
            if (Number(p.value) > 0) {
                tooltip += getTooltipRow([`${p.marker} ${p.seriesName}:`, addSeparators(p.value)])
            }
        })
        tooltip += getTooltipFooter()
        return tooltip
    }

    const legendData = props.chart.props().legendData
    const axisData = props.chart.props().xAxisData
    const series = props.chart.props().series

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <TimeseriesBarChart
                axisData={axisData}
                series={series.map((serie: any) => {
                    return {
                        ...serie,
                        barGap: '-100%',
                        stack: null,
                    }
                })}
                legendData={legendData}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </div>
    )
}

export default withCurrency(Chart)
