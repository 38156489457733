import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { history } from 'middleware'
import { BaseRouteParams, ReplaceMessagesFunc, WithNavigationProps, withNavigation } from 'hocs'
import { getAllSelectedOrderItemsUuids, getSelectedOrdersList } from 'orders/orders/redux'
import OrderTicketsActions from '../../commons/orderTicketsActions'
import PermissionRequired from 'admin/permissionRequired'
import { pathUtils } from 'utils/pathnameFormatter'
import AddOrder from './utilitiesHeader/addOrder'
import UtilityHeader from './utilitiesHeader'
import './index.scss'

interface HeaderProps {
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
}

const Header: FC<WithNavigationProps<BaseRouteParams> & HeaderProps> = ({ match, hideMessage, replaceMessages }) => {
    const { accountSlug } = match.params
    const location = useLocation()
    const areItemsSelected = useSelector(getAllSelectedOrderItemsUuids).length > 0
    const areOrdersSelected = useSelector(getSelectedOrdersList).length > 0

    return (
        <div className="header-container">
            <div className="left-side">
                {areItemsSelected || areOrdersSelected ? (
                    <OrderTicketsActions hideMessage={hideMessage} replaceMessages={replaceMessages} />
                ) : (
                    <UtilityHeader />
                )}
            </div>
            <div className="right-side">
                <PermissionRequired accountSlug={accountSlug} name="export_orders">
                    <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            if (!/export$/.test(location.pathname)) {
                                history.push(pathUtils.addPath(location.pathname, 'export', location.search))
                            }
                        }}
                        data-testid="orders-export-button"
                        className="action-button"
                    >
                        Export
                    </Button>
                </PermissionRequired>
                <AddOrder />
            </div>
        </div>
    )
}

export default withNavigation(Header)
