import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import { Typography } from '@mui/material'
import { Checkbox } from 'uiComponents/input'
import Money from 'uiComponents/money'
import { IOrder, IOrderItems } from 'orders/schema'
import {
    getSelectedOrderById,
    getSelectedOrderItemsListByTableId,
    toggleAllOrderItems,
    toggleOrder,
} from 'orders/orders/redux'
import OrderItem from './item'
import { ReplaceMessagesFunc } from 'hocs'
import './styles.scss'
import ExpandIcon from 'orders/orders/commons/expandIcon'

const OrderItems = ({
    order,
    accountSlug,
    replaceMessages,
    hideMessage,
    shouldShowPricesWithoutTax,
}: {
    order: IOrder
    accountSlug: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    shouldShowPricesWithoutTax: boolean
}) => {
    const dispatch = useDispatch()
    const itemsUuids =
        order?.items?.filter((item: IOrderItems) => !item.refundInfo).map((item: IOrderItems) => item.id) ?? []
    const isOrderSelected = useSelector(getSelectedOrderById(order.id))
    const selectedItems = useSelector(getSelectedOrderItemsListByTableId(order.id))
    const [isAllExpanded, setIsAllExpanded] = React.useState(!!(order.items && order.items.length < 5))

    const toggleOrderAndItems = React.useCallback(
        (checked: boolean) => {
            dispatch(toggleOrder({ orderUuid: order.id, selected: checked }))
            dispatch(
                toggleAllOrderItems({
                    tableId: order.id,
                    rows: itemsUuids.reduce(
                        (acc: { [itemUuid: string]: boolean }, itemId: string) => ({ ...acc, [itemId]: checked }),
                        {},
                    ),
                }),
            )
        },
        [order.id, itemsUuids],
    )

    React.useEffect(() => {
        toggleOrderAndItems(true)
    }, [])

    const handleOrderCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleOrderAndItems(e.target.checked)
    }

    return (
        <div className="order-items">
            <div className="item-row">
                <div className="left-side">
                    <ExpandIcon isExpanded={isAllExpanded} setIsExpanded={setIsAllExpanded} />
                    <Checkbox
                        checked={selectedItems.length > 0}
                        onChange={handleOrderCheckboxChange}
                        indeterminateState={!isOrderSelected}
                    />
                    <h6>Order ID:</h6>
                    <Typography variant="body1">{order.number}</Typography>
                </div>
                <h6>
                    {shouldShowPricesWithoutTax && (
                        <>
                            (Total excl. tax:&nbsp;
                            <Money amount={order.totalDiscountedPriceExclTax} accountSlug={accountSlug} />
                            )&nbsp;
                        </>
                    )}
                    Total: <Money amount={order.totalDiscountedPriceInclTax} accountSlug={accountSlug} />
                </h6>
            </div>
            <div>
                {sortBy(order?.items, ['product', 'id']).map((item: IOrderItems) => (
                    <OrderItem
                        key={item.id}
                        item={item}
                        accountSlug={accountSlug}
                        orderUuid={order.id}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                        shouldBeExpanded={isAllExpanded}
                        shouldShowPricesWithoutTax={shouldShowPricesWithoutTax}
                    />
                ))}
            </div>
        </div>
    )
}

export default OrderItems
