import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import * as config from 'config'
import ActionButton from 'uiComponents/buttons'
import { SingleSelectFieldFormik } from 'uiComponents/input'
import RadioFieldFormik from 'uiComponents/input/radio/radioFieldFormik'
import { removeMessage, replaceMessage } from 'uiComponents/messages/actions'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { delay } from 'utils'
import {
    isDateRangeMoreThanOneDay,
    OrdersPageQueryResult,
    useDownloadLink,
    UseDownloadLinkPayload,
    useOrdersPageQuery,
} from 'orders/orders/utils'
import { ORDER_PATHS } from 'orders/paths'
import { CancelButton } from 'uiComponents/buttons/cancelButton'
import { useScheduleAsyncExportMutation } from 'orders/reduxQueries'
import './styles.scss'
import { useAppSelector } from 'store/hooks'
import { ScheduleAsyncExportPayload } from 'orders/ordersService'

enum ExportType {
    Orders = 'export/orders/',
    OrdersDetails = 'export/orders/details/',
    OrdersDiscounts = 'export/orders/discounts/',
    OrdersRefunds = 'export/orders/refunds/',
    UnusedGiftCards = 'sold_unused_giftcards/export/',
}

const backofficeEndpoint = config.getRequired('backoffice-endpoint')
const ExportOptions = [
    { value: ExportType.Orders, name: 'Orders' },
    { value: ExportType.OrdersDetails, name: 'Order Details' },
    { value: ExportType.OrdersDiscounts, name: 'Order Discounts' },
    { value: ExportType.OrdersRefunds, name: 'Order Refunds' },
    { value: ExportType.UnusedGiftCards, name: 'Unused gift cards' },
]

interface FormValues {
    type: ExportType
    format: 'csv' | 'xlsx'
    dateSelection: 'all' | 'current'
}

const InitialExportValues: FormValues = {
    type: ExportType.Orders,
    format: 'csv',
    dateSelection: 'all',
}

const ExportOrders = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const accountSlug = useAppSelector((state) => state.preferences.activeAccount ?? '')
    const orderPageQuery = useOrdersPageQuery()
    const downloadLink = useDownloadLink()
    const [scheduleAsyncExport] = useScheduleAsyncExportMutation()
    const history = useHistory()

    const closeModal = () => {
        history.push(location.pathname.replace(`/${ORDER_PATHS.internalPaths.orderPath.export}`, '') + location.search)
    }

    const getLocations = (): string => {
        const filterParam = new URLSearchParams(location.search).get('filter')
        if (!filterParam) return ''

        const locationFilter = filterParam
            .split(',')
            .map(decodeURIComponent)
            .find((filter) => filter.startsWith('location_id:'))

        if (!locationFilter) return ''

        const [, locationIdsString] = locationFilter.split(':')
        return locationIdsString
    }

    const getScheduleAsyncExportProps = (
        path: ExportType,
        format: string,
        dateSelection: typeof InitialExportValues.dateSelection,
        accountSlug: string,
        orderPageQuery: OrdersPageQueryResult,
    ): ScheduleAsyncExportPayload | UseDownloadLinkPayload => {
        if (path === ExportType.UnusedGiftCards) {
            return {
                search: orderPageQuery.search,
                account_slug: accountSlug,
                export_format: format,
                timezone_offset: new Date().getTimezoneOffset(),
                locations: getLocations(),
                ...(dateSelection && {
                    date_from:
                        orderPageQuery.date_range_type === 'sale'
                            ? orderPageQuery.from_created_at
                            : orderPageQuery.from_event_date,
                    date_to:
                        orderPageQuery.date_range_type === 'sale'
                            ? orderPageQuery.to_created_at
                            : orderPageQuery.to_event_date,
                }),
            }
        }
        return getDownloadLinkProps(format, orderPageQuery)
    }

    const getDownloadLinkProps = (format: string, orderPageQuery: OrdersPageQueryResult): UseDownloadLinkPayload => {
        return {
            ...orderPageQuery,
            export_format: format,
            locations: getLocations(),
        }
    }

    const getDownloadLink = (path: ExportType, dateRangeMoreThanOneDay: boolean) => {
        if (path === ExportType.UnusedGiftCards) {
            return `${backofficeEndpoint}dashboard_api/sold_unused_giftcards/export/schedule`
        }
        return `${backofficeEndpoint}dashboard_api/accounts/${accountSlug}/${path}${
            dateRangeMoreThanOneDay ? 'schedule/' : ''
        }`
    }

    const onSubmit = async (values: FormValues) => {
        try {
            const dateRangeMoreThanOneDay = isDateRangeMoreThanOneDay(orderPageQuery)

            const endpoint = getDownloadLink(values.type, dateRangeMoreThanOneDay)

            if (dateRangeMoreThanOneDay || values.type === ExportType.UnusedGiftCards) {
                const payload = getScheduleAsyncExportProps(
                    values.type,
                    values.format,
                    values.dateSelection,
                    accountSlug,
                    orderPageQuery,
                )
                await scheduleAsyncExport({ endpoint, payload })
                dispatch(
                    replaceMessage(
                        'schedule_success',
                        'success',
                        'Request was placed successfully. An email with be sent to you shortly.',
                    ),
                )
                closeModal()
                await delay(6000)
                dispatch(removeMessage('schedule_success'))
            } else {
                const payload = getDownloadLinkProps(values.format, orderPageQuery)

                await downloadLink(endpoint, payload)
                dispatch(
                    replaceMessage(
                        'schedule_success',
                        'success',
                        'Report generation may take up to 30 seconds and will download automatically.',
                    ),
                )
                closeModal()
                await delay(6000)
                dispatch(removeMessage('schedule_success'))
                return
            }
        } catch {
            dispatch(replaceMessage('server_error', 'error', 'Oops! Something went wrong. Please try again.'))
        }
    }

    return (
        <ModalDialog onDismiss={closeModal} interactive fromTop="20%" className="orders-export-dialog">
            <ModalDialogTitle>Export orders</ModalDialogTitle>
            <Formik initialValues={InitialExportValues} onSubmit={onSubmit} enableReinitialize>
                {({ values }) => (
                    <Form>
                        <div className="questions-container">
                            <div className="type">
                                <h6>Export type</h6>
                                <SingleSelectFieldFormik
                                    name="type"
                                    options={ExportOptions}
                                    id="export-type-dropdown"
                                />
                            </div>
                            <div className="format">
                                <h6>Format</h6>
                                <div className="format-row">
                                    <RadioFieldFormik
                                        variant="inline-reverse"
                                        name="format"
                                        value="csv"
                                        label=".CSV"
                                        checkMarkSize="medium"
                                        className={values.format === 'csv' ? 'selected' : ''}
                                        id="export-csv"
                                    />
                                    <RadioFieldFormik
                                        variant="inline-reverse"
                                        name="format"
                                        value="xlsx"
                                        label=".XLSX"
                                        checkMarkSize="medium"
                                        className={values.format === 'xlsx' ? 'selected' : ''}
                                        id="export-xlsx"
                                    />
                                </div>
                            </div>
                            {values.type === ExportType.UnusedGiftCards && (
                                <div className="date">
                                    <h6>Time period</h6>
                                    <div className="format-row">
                                        <RadioFieldFormik
                                            variant="inline-reverse"
                                            name="dateSelection"
                                            value="all"
                                            label="All time"
                                            checkMarkSize="medium"
                                            className={values.dateSelection === 'all' ? 'selected' : ''}
                                            id="export-giftcard-all-dates"
                                        />
                                        <RadioFieldFormik
                                            variant="inline-reverse"
                                            name="dateSelection"
                                            value="current"
                                            label="Currently selected"
                                            checkMarkSize="medium"
                                            className={values.dateSelection === 'current' ? 'selected' : ''}
                                            id="export-giftcard-current-dates"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="buttons-container">
                            <CancelButton
                                size="large"
                                secondary
                                onClick={closeModal}
                                data-testid="export-cancel-button"
                            >
                                Cancel
                            </CancelButton>
                            <ActionButton size="large" kind="default" type="submit" data-testid="export-exports-button">
                                Export
                            </ActionButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalDialog>
    )
}

export default ExportOrders
