import React from 'react'
import { useParams } from 'react-router-dom'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { RefundForm } from './interface'
import { BaseRouteParams, MessageProps, withMessages } from 'hocs'
import { getTotalRefundedAmount } from './utils'
import createTextSwitcher from 'uiComponents/textSwitcher'
import { Currency, CurrencyProps, withCurrency } from 'uiComponents/money/moneyHoc'
import { useBulkRefundOrderMutation } from 'orders/reduxQueries'
import { addMessage } from 'uiComponents/messages/actions'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'

interface ConfirmationModalProps extends MessageProps, CurrencyProps {
    refundForm: RefundForm
    cancellation: boolean
    closeConfirmationModal(): void
    openEditDialog(): void
}

type SliceKeys = 'refund' | 'cancellation'
type TextKeys = 'title' | 'errorMessage' | 'body' | 'edit' | 'confirm'

interface ExtendedTextSwitcherProps {
    cancellation: boolean
    selectedOrders?: number
    totalRefund?: number
    currency: Currency
}

const TextSwitcher = createTextSwitcher<SliceKeys, TextKeys, ExtendedTextSwitcherProps>({
    getSliceKey: ({ cancellation }) => (cancellation ? 'cancellation' : 'refund'),
    defaultSliceKey: 'refund',
    slice: {
        refund: {
            title: 'Refund orders',
            errorMessage: 'Oops! Could not refund orders',
            body: ({ selectedOrders, totalRefund, currency }) => (
                <>
                    You are about to refund <b>“{selectedOrders}”</b> orders for
                    <b>
                        {' '}
                        “{totalRefund} {currency.symbol}”{' '}
                    </b>
                    All selected orders will be refunded this cannot be undone!
                </>
            ),
            edit: 'Edit list',
            confirm: 'Refund Orders',
        },
        cancellation: {
            title: 'Cancel orders',
            errorMessage: 'Oops! Could not cancel orders',
            body: ({ selectedOrders, totalRefund, currency }) => (
                <>
                    You are about to cancel <b>“{selectedOrders}”</b> orders for
                    <b>
                        {' '}
                        “{totalRefund} {currency.symbol}”{' '}
                    </b>
                    All selected orders will be canceled, this cannot be undone!
                </>
            ),
            edit: 'Edit list',
            confirm: 'Cancel Orders',
        },
    },
})

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    refundForm,
    closeConfirmationModal,
    openEditDialog,
    replaceMessages,
    cancellation,
    getCurrency,
}) => {
    const dispatch = useDispatch()
    const [refundOrder, status] = useBulkRefundOrderMutation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const currency = getCurrency(accountSlug)

    const submitRefund = async () => {
        try {
            await refundOrder({
                accountSlug,
                refundReason: refundForm.comment || '',
                orderUuids: refundForm.orders.map((order) => order.id),
                forgoPartnerRefundFee: refundForm.partnerRefundFee,
                isCancellation: cancellation,
            })
        } catch (error) {
            dispatch(
                addMessage('server_error', 'error', TextSwitcher({ name: 'errorMessage', cancellation, currency })),
            )
        }
        closeConfirmationModal()
    }

    const formattedTotalRefund = getTotalRefundedAmount({
        orders: refundForm.orders,
        partnerRefundFee: refundForm.partnerRefundFee,
    })

    return (
        <ModalDialog
            onDismiss={() => closeConfirmationModal()}
            interactive
            fromTop="20%"
            style={{
                width: '508px',
            }}
        >
            <ModalDialogTitle>
                <TextSwitcher cancellation={cancellation} name="title" currency={currency} />
            </ModalDialogTitle>
            <TextSwitcher
                cancellation={cancellation}
                name="body"
                currency={currency}
                selectedOrders={refundForm?.orders?.length}
                totalRefund={formattedTotalRefund}
            />
            <NavigationSection>
                <Button variant="outlined" size="large" onClick={closeConfirmationModal} color="secondary">
                    Close
                </Button>
                <Button variant="outlined" size="large" onClick={openEditDialog} color="primary">
                    <TextSwitcher currency={currency} cancellation={cancellation} name="edit" />
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    id="edit-refund-bulk-orders"
                    onClick={submitRefund}
                    color="error"
                    disabled={status.isLoading}
                >
                    <TextSwitcher currency={currency} cancellation={cancellation} name="confirm" />
                </Button>
            </NavigationSection>
        </ModalDialog>
    )
}

export default withCurrency(withMessages(ConfirmationModal))
