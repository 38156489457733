import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { PageTitle, PageHeading, StyledATag } from 'uiComponents/typography'
import { withMessages, MessageProps } from 'hocs'
import { Messages } from 'uiComponents/messages'
import CopySearchListPage from 'copyEditor/searchList'
import CopyEditPage from 'copyEditor/editForm'
import SearchBar from 'uiComponents/search/searchBar'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { withCurrentUser } from 'hocs'
import { User } from 'auth/state'

interface CopyEditorPageProps {
    user: User
    history: History
    accountSlug: string
    location: Location
    navigation: Navigation
    match: RouteMatch<any>
}

function CopyEditorPage(props: CopyEditorPageProps & MessageProps) {
    const searchByOpts = [
        { name: 'Search by text', value: 'text' },
        { name: 'Search by key', value: 'key' },
    ]

    const onSearch = () => {
        // navigate to list even if search params haven't changed
        if (location.pathname.indexOf('engage/copy_editor/edit/') > -1) {
            props.history.push(`/account/${props.accountSlug}/engage/copy_editor/home${location.search}`)
        }
    }

    return (
        <div style={{ position: 'relative', marginBottom: '7em' }} id="copy-editor-page">
            <Messages messages={props.messages} hideMessage={props.hideMessage} />
            <PageTitle>Copy Editor</PageTitle>
            <PageHeading>
                <div>View and edit your Checkouts’ text and descriptions.</div>
                Learn how to use the Copy Editor in our&nbsp;
                <StyledATag target="_blank" href="https://support.convious.com/help/how-to-use-the-copy-editor">
                    how-to-guide
                </StyledATag>
                .
            </PageHeading>
            <div>
                <SearchBar
                    searchByOptions={props.user.isAdmin ? searchByOpts : searchByOpts.filter((o) => o.value !== 'slug')}
                    defaultSearchBy="text"
                    minSearchLength={3}
                    maxSearchLength={130}
                    searchOnEnter={true}
                    onSearch={onSearch}
                    replaceMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                />
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/engage/copy_editor/home"
                        render={() => (
                            <CopySearchListPage
                                history={props.history}
                                accountSlug={props.accountSlug}
                                replaceMessages={props.replaceMessages}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/engage/copy_editor/edit/:copySlug"
                        render={() => (
                            <CopyEditPage
                                history={props.history}
                                accountSlug={props.accountSlug}
                                replaceMessages={props.replaceMessages}
                                hideMessage={props.hideMessage}
                            />
                        )}
                    />
                    <Route render={() => <Redirect to={`/account/${props.accountSlug}/engage/copy_editor/home`} />} />
                </Switch>
            </div>
        </div>
    )
}

export default withCurrentUser(withNavigation(withMessages(CopyEditorPage)))
