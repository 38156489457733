import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TaxConfiguration } from './types'
import { AppServices } from 'middleware'
import { TaxConfigurationsResponse } from './taxConfigurationsService'

export const taxConfigurationsApi = createApi({
    reducerPath: 'taxConfigurationsApi',
    tagTypes: ['taxConfigurations', 'defaultTaxConfigurationUuid'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

export const extendedTaxConfigurationsApi = taxConfigurationsApi.injectEndpoints({
    endpoints: (builder) => ({
        getTaxConfigurations: builder.query<TaxConfigurationsResponse | null, string>({
            queryFn: async (accountSlug) => {
                const data = await AppServices.taxConfigurationsService.getTaxConfigurations(accountSlug)
                return { data }
            },
        }),
        createTaxConfiguration: builder.mutation<
            TaxConfiguration,
            { accountSlug: string; taxConfiguration: Partial<TaxConfiguration> }
        >({
            invalidatesTags: ['taxConfigurations'],
            queryFn: async ({ accountSlug, taxConfiguration }) => {
                const data = await AppServices.taxConfigurationsService.createTaxConfiguration(
                    accountSlug,
                    taxConfiguration,
                )
                return { data }
            },
        }),
        updateTaxConfiguration: builder.mutation<
            TaxConfiguration,
            { accountSlug: string; taxConfiguration: Partial<TaxConfiguration> }
        >({
            invalidatesTags: ['taxConfigurations'],
            queryFn: async ({ accountSlug, taxConfiguration }) => {
                const data = await AppServices.taxConfigurationsService.updateTaxConfiguration(
                    accountSlug,
                    taxConfiguration,
                )
                return { data }
            },
        }),
        deleteTaxConfiguration: builder.mutation<void, { accountSlug: string; taxConfigurationUuid: string }>({
            invalidatesTags: ['taxConfigurations'],
            queryFn: async ({ accountSlug, taxConfigurationUuid }) => {
                const data = await AppServices.taxConfigurationsService.deleteTaxConfiguration(
                    accountSlug,
                    taxConfigurationUuid,
                )
                return { data }
            },
        }),
        setDefaultTaxConfiguration: builder.mutation<void, { accountSlug: string; taxConfigurationUuid: string }>({
            invalidatesTags: ['defaultTaxConfigurationUuid'],
            queryFn: async ({ accountSlug, taxConfigurationUuid }) => {
                const data = await AppServices.taxConfigurationsService.setDefaultTaxConfiguration(
                    accountSlug,
                    taxConfigurationUuid,
                )
                return { data }
            },
        }),
    }),
})

export const { reducer, reducerPath } = taxConfigurationsApi
export const {
    useGetTaxConfigurationsQuery,
    useLazyGetTaxConfigurationsQuery,
    useCreateTaxConfigurationMutation,
    useUpdateTaxConfigurationMutation,
    useDeleteTaxConfigurationMutation,
    useSetDefaultTaxConfigurationMutation,
} = extendedTaxConfigurationsApi
