import { getStatusDescriptor } from 'orders/schema'
import React from 'react'
import { Text } from 'uiComponents/typography'

interface StatusLabelProps {
    status: string
    title?: string
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, title }) => {
    const statusDescriptor = getStatusDescriptor(status)

    return (
        <Text status={statusDescriptor.style} title={title}>
            {statusDescriptor.text}
        </Text>
    )
}

export default StatusLabel
