import React from 'react'
import { ExtensibleReactTableComponentProps } from 'uiComponents/table/reactTable'
import CartComment from './cartComment'
import CartItemTable from './cartItemTable'
import { Cart } from 'orders/schema'

const CartTableCell = ({ row }: ExtensibleReactTableComponentProps<Cart>) => (
    <div className="cart-item-expanded">
        <CartComment cartId={row.original.id} />
        <CartItemTable cartItems={row.original.items} />
    </div>
)

export default CartTableCell
