import React, { FC, useEffect } from 'react'
import { withNavigation, WithNavigationProps } from 'hocs'
import { useLazyGetSentEmailsQuery } from 'orders/reduxQueries'
import { PageContent } from 'uiComponents/appLayouts/pageContent'
import { PageTitle } from 'uiComponents/typography'
import './sentEmailsPage.scss'

interface SentEmailsPageProps extends WithNavigationProps<{ id: string; accountSlug: string }> {}

const SentEmailsPage: FC<SentEmailsPageProps> = ({ match }) => {
    const { accountSlug, id } = match.params
    const [getSentEmails, { data }] = useLazyGetSentEmailsQuery()

    useEffect(() => {
        getSentEmails({ orderUuid: id, accountSlug })
    }, [accountSlug, id, getSentEmails])

    return (
        <PageContent className="sent-emails-page">
            <PageTitle>Sent Emails</PageTitle>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </PageContent>
    )
}

export default withNavigation(SentEmailsPage)
