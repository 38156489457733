import uuid from 'uuid'
import { InventoryItem } from 'inventory/inventoryService'
import { Availability } from 'reservations/types'
import { TimelineItem } from './types'

export const getCssVariables = (capacity: number | null, available: number, isInstant: boolean): string => {
    if (capacity === 0) {
        return `
            --item-background: var(--aluminium-20);
            --item-border: 1px solid transparent;
            --item-text-color: var(--text-dark);
        `
    }

    if (capacity === null || capacity - available === 0) {
        return `
            --item-background: var(--white);
            --item-border: 1px solid var(--boy-blue-50);
            --item-text-color: var(${isInstant ? '--text-dark' : '--boy-blue-50'});
            --flag-color: var(--boy-blue-50);
        `
    }

    const capacityPercentage = Math.min(Math.floor(((capacity - available) / capacity) * 100), 100)

    // Map 1-100% to 10-90 range
    const colorStep = Math.max(Math.min(Math.ceil(capacityPercentage / 10) * 10, 90), 10)
    const boyBlueVar = `var(--boy-blue-${colorStep})`

    const textColor = colorStep >= 40 ? 'white' : 'black'

    return `
        --item-background: ${boyBlueVar};
        --item-border: 1px solid ${isInstant ? boyBlueVar : 'transparent'};
        --item-text-color: ${textColor};
        --flag-color: ${boyBlueVar};
    `
}

export const getGroups = (inventoryList: InventoryItem[]) =>
    inventoryList.map((inventory) => ({
        id: inventory.id,
        content: inventory.name,
        reservationPeriodType: inventory.reservationPeriodType,
    }))

export const getItems = (availabilityList: Availability[]): TimelineItem[] =>
    availabilityList.reduce((items, { resourceId, availability, reservationPeriodType }) => {
        const convertedItems = availability
            // NOTE: For reservationPeriodType === 'instant' we only show Gate Capacity, which in the DB is always 1 second long.
            .filter(({ dateTo }) => reservationPeriodType === 'period' || dateTo.endsWith(':01'))
            .map((item) => {
                const available = item.available || 0
                const capacity = item.capacity || 0

                const isInstant = reservationPeriodType === 'instant' && item.dateTo.endsWith(':01')

                return {
                    id: uuid.v4(),
                    available: available,
                    capacity: capacity,
                    reserved: capacity - available,
                    start: item.dateFrom,
                    end: item.dateTo,
                    group: resourceId,
                    reservationType: isInstant ? ('instant' as const) : ('period' as const),
                    className: isInstant ? 'vis-item-instant' : 'vis-item-period',
                    style: getCssVariables(capacity, available, isInstant),
                }
            })

        return [...items, ...convertedItems]
    }, [])

const formatCalendarDate = (date: string, time: string) => `${date}T${time}`

const padZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`
}

export const getCalendarMin = (date: string, startTime: string) => {
    const [hour, _minute] = startTime.split(':').map(Number)
    return formatCalendarDate(date, `${padZero(hour)}:00`)
}

export const getCalendarMax = (date: string, endTime: string) => {
    const [hour, minute] = endTime.split(':').map(Number)
    let calendarEndTime = minute > 0 ? `${padZero(hour + 1)}:00` : `${padZero(hour)}:00`
    return formatCalendarDate(date, calendarEndTime)
}
