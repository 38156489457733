import { DateRangeName } from 'dateRanges'

export type CompareToType = 'prevPeriod' | 'prevYear' | 'custom'

export type DateRangeType = 'sale' | 'visit' | 'created_at' | 'event_date' | 'expires_at'

export interface Option {
    name: string
    value: CompareToType | DateRangeName
}

export interface PickerRange {
    from: Date
    to: Date
}

export interface CompareRangeQuery {
    compareRangeFrom: string | null
    compareRangeTo: string | null
    compareToPeriod: CompareToType | null
}

export interface DateRangeOption {
    name: string;
    value: string;
}

export const DateRangeOptions: DateRangeOption[] = [
    { name: 'Date created', value: 'created_at' },
    { name: 'Date of visit', value: 'event_date' },
    { name: 'Payment deadline', value: 'expires_at' },
]
