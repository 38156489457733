import React, { useMemo } from 'react'
import styled from 'styled-typed'
import { useField } from 'formik'
import { IndeterminateCheckbox, TableColumn } from 'uiComponents/table/reactTable'
import { calculateOrderPartnerRefundFee } from 'orders/utils/calculateOrderRefund'
import { CheckboxFieldFormik } from 'uiComponents/input'
import { Headline } from 'uiComponents/typography'
import { Currency } from 'uiComponents/money/moneyHoc'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import { getTotalRefundedAmount } from '../utils'
import { OrderRefundDetails } from '../../types'

export const DisabledText = styled.div`
    color: ${(props) => props.theme.colors.aluminiumShades[20]};
`

type IsOrderDisabled = (order: OrderRefundDetails) => boolean

interface UseBulkRefundTableColumns {
    isOrderDisabled: IsOrderDisabled
    cancellation: boolean
    getCurrency: (accountSlug: string) => Currency
}

const roundFee = (refundFee?: number) => {
    if (!!refundFee) {
        return Math.round(refundFee * 100) / 100
    }

    return refundFee
}

const createColumns = ({
    isOrderDisabled,
    currency,
}: {
    isOrderDisabled: IsOrderDisabled
    currency: Currency
}): TableColumn<OrderRefundDetails>[] => {
    return [
        {
            accessor: 'selectable',
            width: '.5rem',
            disableSortBy: true,
            disableHideColumn: true,
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => {
                return (
                    <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                        disabled={isOrderDisabled(row.original)}
                    />
                )
            },
        },
        {
            accessor: 'number',
            Header: 'ID',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return <DisabledText>{value}</DisabledText>
                }

                return value
            },
        },
        {
            accessor: 'total',
            Header: 'Subtotal',
            width: '86px',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return (
                        <DisabledText>
                            {currency.symbol} {value}
                        </DisabledText>
                    )
                }
                return `${currency.symbol} ${value}`
            },
            Footer: [() => <span style={{ textAlign: 'right', fontWeight: 'bold' }}>Total: </span>],
        },
        {
            accessor: 'totalDiscountedPriceInclTax',
            Header: 'Total',
            width: '86px',
            align: 'right',
            Cell: ({ value, row }) => {
                if (isOrderDisabled(row.original)) {
                    return (
                        <DisabledText>
                            {currency.symbol} {value}
                        </DisabledText>
                    )
                }
                return `${currency.symbol} ${value}`
            },
            Footer: [
                (props) => {
                    const [{ value: partnerRefundFee }] = useField('partnerRefundFee')

                    const roundedAmount = `${currency.symbol} ${getTotalRefundedAmount({
                        orders: props.rows.filter((row) => row.isSelected).map((row) => row.original),
                        partnerRefundFee,
                    })}`

                    return (
                        <Headline style={{ margin: 0 }} size={6} title={roundedAmount}>
                            {roundedAmount}
                        </Headline>
                    )
                },
            ],
        },
    ]
}

export const useBulkRefundTableColumns = ({
    isOrderDisabled,
    cancellation,
    getCurrency,
}: UseBulkRefundTableColumns) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const currency = getCurrency(accountSlug)

    return useMemo(() => {
        if (!cancellation) {
            return createColumns({ isOrderDisabled, currency }).map((column) => {
                if (column.accessor === 'selectable') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => (
                            <div>
                                <CheckboxFieldFormik name="partnerRefundFee" />
                            </div>
                        ))
                    }
                }

                if (column.accessor === 'number') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => (
                            <Headline style={{ margin: 0 }} size={6}>
                                Visitor Refund Fee
                            </Headline>
                        ))
                    }
                }

                if (column.accessor === 'total') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift(() => null)
                    }
                }

                if (column.accessor === 'totalDiscountedPriceInclTax') {
                    if (!column.Footer) {
                        column.Footer = []
                    }
                    if (Array.isArray(column.Footer)) {
                        column.Footer?.unshift((props) => {
                            const refundFee = props.rows
                                .filter((row) => row.isSelected)
                                .reduce((acc, row) => acc + calculateOrderPartnerRefundFee({ order: row.original }), 0)

                            return (
                                <Headline style={{ margin: 0 }} size={6}>
                                    {`- ${currency.symbol} ${roundFee(refundFee)}`}
                                </Headline>
                            )
                        })
                    }
                }

                return column
            })
        }

        return createColumns({ isOrderDisabled, currency })
    }, [isOrderDisabled, cancellation, currency])
}
