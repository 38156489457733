import React from 'react'
import { addSeparators } from 'utils'
import { DiscountCode } from 'venue/bookingCodes/bookingCodesService'

interface UsageCountProps {
    usage?: DiscountCode['usage']
}

const UsageCount: React.FC<UsageCountProps> = ({ usage }) => {
    if (!usage) {
        return <>Unlimited</>
    }

    return (
        <>
            {addSeparators(usage.used)}/{addSeparators(usage.total)}
        </>
    )
}

export default UsageCount
