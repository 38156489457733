import * as React from 'react'
import { StatsService } from 'http/statsService'
import { DataTotal } from 'reports/schema'
import { DateRange } from 'dateRanges'
import { Row, Col } from 'uiComponents/flex'
import PieChart from 'uiComponents/charts/pieChart'
import BarChart from 'uiComponents/charts/barChart'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    areDateRangeDatesEqual,
    sortWeekdays,
    formatHour,
    formatWeekDay,
    chartRowsGap,
    getTooltipRow,
    getTooltipHeader,
    getTooltipFooter,
    format24HXAxis,
    formatWeekDayForTooltip,
} from '../helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { TrafficSourseInfotipContent } from 'reports/chartTooltips'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { ChartContainer, ChartHeadline } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { Headline } from 'uiComponents/typography'
import { convertTimeFormat, DateFormats, addSeparators } from 'utils'

interface TrafficByGroupChartsProps {
    filters: Filters[]
    dateRange: DateRange
    statsService: StatsService
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
}

interface TrafficByGroupChartsState {
    returningVisitorsTypeData: DataTotal[]
    deviceTypeData: DataTotal[]
    acquisitionChannelData: DataTotal[]
    popularDaysData: DataTotal[]
    popularHoursData: DataTotal[]
    loading: boolean
}

class TrafficByGroupCharts extends React.Component<TrafficByGroupChartsProps, TrafficByGroupChartsState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: TrafficByGroupChartsProps) {
        super(props)
        this.state = {
            returningVisitorsTypeData: [],
            deviceTypeData: [],
            acquisitionChannelData: [],
            popularDaysData: [],
            popularHoursData: [],
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: TrafficByGroupChartsProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters)
        ) {
            await this.getData()
        }
    }

    getData = async () => {
        this.setState({ loading: true })
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
        const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
        try {
            const metric = ''
            const granularity = ''
            const data = await this.props.statsService.getReportStats(
                'trafficTotalsByGroup',
                this.props.accountSlug,
                metric,
                dateFrom,
                dateTo,
                granularity,
                this.props.filters,
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const returningVisitorsTypeData = data.returningVisitors.totals ? data.returningVisitors.totals : []
            const deviceTypeData = data.deviceType.totals ? data.deviceType.totals : []
            const acquisitionChannelData = data.acquisitionChannel.totals ? data.acquisitionChannel.totals : []
            const popularDaysData = data.weekday.totals ? sortWeekdays(formatWeekDay(data.weekday.totals)) : []
            const popularHoursData = data.hour.totals ? formatHour(data.hour.totals) : []
            if (this._isMounted) {
                this.setState({
                    returningVisitorsTypeData,
                    deviceTypeData,
                    acquisitionChannelData,
                    popularDaysData,
                    popularHoursData,
                    loading: false,
                })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    chartTooltipFormatter = (params: TooltipParams[], type: string) => {
        let axisValue = params[0].axisValue
        if (type === 'hours') {
            axisValue = convertTimeFormat(params[0].axisValue, 'H', DateFormats.SHORT_TIME)
        } else {
            axisValue = formatWeekDayForTooltip(axisValue)
        }
        let tooltip = getTooltipHeader(axisValue)
        tooltip += getTooltipRow([`${params[0].marker} Website traffic:`, addSeparators(params[0].value)])
        tooltip += getTooltipFooter()

        return tooltip
    }

    xAxis24HoursFormatter = (value: string, index: number) => {
        return format24HXAxis(index, this.state.popularHoursData.length)
    }

    render() {
        const {
            returningVisitorsTypeData,
            deviceTypeData,
            acquisitionChannelData,
            popularDaysData,
            popularHoursData,
            loading,
        } = this.state
        const { dateRange } = this.props

        const popularDaysSeries = [
            {
                name: '',
                type: 'bar',
                barWidth: '60%',
                data: popularDaysData,
            },
        ]

        const popularHoursSeries = [
            {
                name: '',
                type: 'bar',
                barWidth: '60%',
                data: popularHoursData,
            },
        ]

        const sharedPieChartProps = {
            chartHeight: '370px',
            legendTop: 250,
            pieRadius: '70%',
            loading: loading,
        }
        const commonPopularTimesChartProps = {
            chartHeight: '220px',
            gridBottom: 20,
            gridLeft: 45,
            loading: loading,
        }
        return (
            <>
                <div style={{ marginTop: chartRowsGap, position: 'relative' }}>
                    {loading && <ChartDataLoader />}
                    <Row>
                        <Col span={4}>
                            <ChartContainer>
                                <WithDateRangeNotice
                                    dateRange={this.props.dateRange}
                                    userpilot="traffic-visitors-canvas"
                                >
                                    <Headline size={4} style={{ marginBottom: '0' }}>
                                        New & returning visitors
                                    </Headline>
                                    <PieChart data={returningVisitorsTypeData} {...sharedPieChartProps} />
                                </WithDateRangeNotice>
                            </ChartContainer>
                        </Col>
                        <Col span={4}>
                            <ChartContainer>
                                <WithDateRangeNotice
                                    dateRange={this.props.dateRange}
                                    userpilot="traffic-acquisition-canvas"
                                >
                                    <ChartHeadline size={4}>
                                        <span>Acquisition channel</span>
                                        <Infotip pointer="left" maxWidth="40em">
                                            <TrafficSourseInfotipContent />
                                        </Infotip>
                                    </ChartHeadline>
                                    <PieChart data={acquisitionChannelData} {...sharedPieChartProps} />
                                </WithDateRangeNotice>
                            </ChartContainer>
                        </Col>
                        <Col span={4}>
                            <ChartContainer>
                                <WithDateRangeNotice dateRange={this.props.dateRange} userpilot="traffic-device-canvas">
                                    <Headline size={4} style={{ marginBottom: '0' }}>
                                        Visitors per platform
                                    </Headline>
                                    <PieChart data={deviceTypeData} {...sharedPieChartProps} />
                                </WithDateRangeNotice>
                            </ChartContainer>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        marginTop: chartRowsGap,
                        marginBottom: '2em',
                        position: 'relative',
                    }}
                >
                    {loading && <ChartDataLoader />}
                    <Row>
                        <Col span={6}>
                            <ChartContainer>
                                <WithDateRangeNotice dateRange={dateRange} userpilot="traffic-popdays-canvas">
                                    <ChartHeadline size={4}>Popular days of your website</ChartHeadline>
                                    <BarChart
                                        series={popularDaysSeries}
                                        {...commonPopularTimesChartProps}
                                        tooltipFormatter={(params) => this.chartTooltipFormatter(params, 'days')}
                                    />
                                </WithDateRangeNotice>
                            </ChartContainer>
                        </Col>
                        <Col span={6}>
                            <ChartContainer>
                                <WithDateRangeNotice dateRange={dateRange} userpilot="traffic-pophours-canvas">
                                    <ChartHeadline size={4}>Popular hours of your website</ChartHeadline>
                                    <BarChart
                                        series={popularHoursSeries}
                                        {...commonPopularTimesChartProps}
                                        tooltipFormatter={(params) => this.chartTooltipFormatter(params, 'hours')}
                                        xAxisFormatter={this.xAxis24HoursFormatter}
                                    />
                                </WithDateRangeNotice>
                            </ChartContainer>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default TrafficByGroupCharts
