import React, { useMemo } from 'react'
import { CartItem } from 'orders/schema'
import { ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import Money from 'uiComponents/money'

const emptyData = [] as any[]

interface CartItemTableProps {
    cartItems: CartItem[]
}

const CartItemTable: React.FC<CartItemTableProps> = ({ cartItems }) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    const columns = useMemo(
        (): TableColumn<CartItem>[] => [
            {
                accessor: 'productName.en',
                Header: 'Product',
                width: '24rem',
                Cell: ({ value }) => <span title={value}>{value}</span>,
            },
            {
                accessor: 'productNumericId',
                Header: 'Prod. ID',
                width: '4rem',
                Cell: ({ value }) => <span title={value}>{value}</span>,
            },
            {
                accessor: 'price',
                Header: 'Price',
                width: '3.75rem',
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            {
                accessor: 'amount',
                width: '2.5rem',
                Header: 'Qty',
                align: 'center',
            },
        ],
        [accountSlug],
    )

    return (
        <ReactTable
            size="small"
            elevation={false}
            columns={columns}
            storeStateInQuery={false}
            bordered={false}
            data={cartItems || emptyData}
            tableProps={{
                className: 'cart-item-table',
            }}
        />
    )
}

export default CartItemTable
