import { BaseRouteParams } from 'hocs'
import { useChangeCartExpirationDateMutation } from 'orders/reduxQueries'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addMessage, removeMessage } from 'uiComponents/messages/actions'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { ErrorResponse, LoggerReject } from '../../../types'
import { DateFormats, parseDate } from 'utils/dates'

interface PaymentDeadlineCellProps {
    value?: string
    cartId: string
}

const PaymentDeadlineCell: React.FC<PaymentDeadlineCellProps> = ({ value, cartId }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [date, setDate] = useState<Date>()
    const [changeDate, { isLoading }] = useChangeCartExpirationDateMutation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const dispatch = useDispatch()

    const closeModal = () => {
        setOpen(false)
        setDate(undefined)
    }

    const onConfirm = () => {
        changeDate({
            accountSlug,
            id: cartId,
            expirationDate: date?.toJSON() as string,
        })
            .unwrap()
            .then(closeModal)
            .catch((error?: LoggerReject<ErrorResponse<any>>) => {
                closeModal()
                const errorVisibleTime = 5000

                dispatch(
                    addMessage(
                        'payment_deadline_change',
                        'error',
                        error?.response?.error?.message || 'Failed to update payment deadline',
                    ),
                )

                setTimeout(() => {
                    dispatch(removeMessage('payment_deadline_change'))
                }, errorVisibleTime)
            })
    }

    if (!value) {
        return <>-</>
    }

    return (
        <>
            {open && (
                <ConfirmationDialog
                    title="Are you sure you want to change payment deadline?"
                    buttonText="Change"
                    onCancel={closeModal}
                    loading={isLoading}
                    onConfirm={onConfirm}
                />
            )}
            <DatePicker
                date={value ? parseDate(value) : null}
                inlineEdit
                modalDatePicker
                onInlineEditAccept={(newDate) => {
                    setDate(newDate)
                    setOpen(true)
                }}
                inlineEditDateFormat={`${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`}
            />
        </>
    )
}

export default PaymentDeadlineCell
