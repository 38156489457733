import { calculateOrderPartnerRefundFee } from 'orders/utils/calculateOrderRefund'
import { OrderRefundDetails } from '../types'

interface GetTotalRefundedAmount {
    orders: OrderRefundDetails[]
    partnerRefundFee: boolean
}

export const getTotalRefundedAmount = ({ orders, partnerRefundFee }: GetTotalRefundedAmount) => {
    const total = orders.reduce((sum: number, order) => {
        const partnerFee = partnerRefundFee ? calculateOrderPartnerRefundFee({ order: order }) : 0

        return Number(order.totalDiscountedPriceInclTax) + sum - partnerFee
    }, 0)

    return Math.round(total * 100) / 100
}
