import { useField, useFormikContext } from 'formik'
import React, { FC, InputHTMLAttributes, useCallback } from 'react'
import FieldWrapper from '../fieldWrapper'
import { useCustomId, useGetComponentState } from '../_shared/hooks'
import { TextInputProps } from './interface'
import { TextInput } from './textInput'

interface TextInputFieldFormikProps extends TextInputProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    name: string
    id?: string
    label?: string | JSX.Element
    validationMessageProps?: any
    width?: string
    validateOnChange?: boolean
}

const TextInputFieldFormik: FC<TextInputFieldFormikProps> = ({
    name,
    label,
    id,
    className,
    width,
    validationMessageProps,
    validateOnChange,
    type = 'text',
    onBlur,
    ...rest
}) => {
    const [{ value, ...field }, { error, touched }] = useField(name)
    const { submitCount, setFieldTouched } = useFormikContext()
    const innerId = useCustomId(id)
    const state = useGetComponentState(name)

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldTouched(name, true)
            field.onChange(e)
        },
        [field, setFieldTouched],
    )

    const showError = validateOnChange ? error && (touched || submitCount > 0) : !!error
    const status = state === 'error' && !showError ? 'normal' : state

    return (
        <FieldWrapper
            className={`${className}${showError ? ' error' : ''}`}
            label={label}
            error={showError ? error : undefined}
            status={status}
            validationMessageProps={{
                ...validationMessageProps,
                style: { position: 'relative', ...validationMessageProps?.style },
            }}
        >
            <TextInput
                style={{ width: width || '100%' }}
                type={type}
                {...field}
                {...rest}
                value={value ?? ''}
                id={innerId}
                status={status}
                onBlur={onBlur}
                onChange={onChange}
            />
        </FieldWrapper>
    )
}

export default TextInputFieldFormik
