import React, { FC, useCallback, useEffect, useState } from 'react'
import { ChevronDownSolid } from '@convious/icons'
import { InlineEdit } from 'uiComponents/input'
import { useLazyGetCartQuery, usePatchCartMutation } from 'orders/reduxQueries'
import { getActiveAccount } from 'preferences/selectors'
import { useAppSelector } from 'store/hooks'
import { PageLoader } from 'uiComponents/loaders'

type CartCommentProps = {
    cartId: string
}

const CartComment: FC<CartCommentProps> = ({ cartId }) => {
    const accountSlug = useAppSelector(getActiveAccount)
    const [getCart, { data, isFetching }] = useLazyGetCartQuery()
    const [patchCart, { fulfilledTimeStamp }] = usePatchCartMutation()
    const [comment, setComment] = useState<{ content: string; uuid?: string }>()

    useEffect(() => {
        if (!accountSlug) throw new Error('No account slug found')
        getCart({ accountSlug, id: cartId })
    }, [cartId, getCart, accountSlug, fulfilledTimeStamp])

    useEffect(() => {
        if (data?.comments?.length) {
            const { comments } = data
            const lastComment = comments[comments.length - 1]
            setComment(lastComment)
        }
    }, [data])

    const handleSetComment = useCallback(
        (content: string) => {
            if (!accountSlug) throw new Error('No account slug found')

            setComment((prev) => ({ ...prev, content }))
            patchCart({
                accountSlug,
                cartId,
                overrides: {
                    comment: {
                        content,
                        ...(comment?.uuid ? { uuid: comment.uuid } : {}),
                    },
                },
            })
        },
        [comment],
    )

    return (
        <details className="cart-comment" open>
            <summary>
                <ChevronDownSolid />
                Notes
            </summary>
            {isFetching && <PageLoader />}
            {!isFetching && (
                <InlineEdit
                    className="cart-comment__text"
                    placeholder="Add a note"
                    value={comment?.content || ''}
                    onEditAccept={handleSetComment}
                    maxLength={1000}
                    multiline
                    responsiveWidth
                />
            )}
        </details>
    )
}

export default CartComment
