import { format, addMinutes } from 'date-fns'
import { DateFormats, parseISODate } from 'utils/dates'

export * from './constants'
export * from './dates'

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function stripTrailingSlash(str: string) {
    return str.replace(/\/$/, '')
}

export function utcValue(date: Date): Date {
    return addMinutes(date, date.getTimezoneOffset())
}

export const cancelOutOffsetBeforeConvertionToUTC = (date: Date) => {
    return addMinutes(date, -date.getTimezoneOffset())
}

export type Index<T> = { [key: string]: T }

export function indexBy<T, U = T>(
    array: T[],
    keySelector: (el: T) => string,
    valueSelector: (el: T) => U = (x) => x as any as U,
): Index<U> {
    return array.reduce((map, el) => {
        map[keySelector(el)] = valueSelector ? valueSelector(el) : el
        return map
    }, {})
}

export function uniqBy<T>(array: T[], keySelector: (el: T) => string): T[] {
    return Object.values(indexBy(array, keySelector))
}

export function mapValues<T, U>(obj: Index<T>, projection: (el: T, key: string) => U): Index<U> {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = projection(value, key)
        return acc
    }, {})
}

export function sum<T>(values: T[], selector: (val: T) => number) {
    return values.reduce((s, v) => s + selector(v), 0)
}

export function groupBy<T, U>(
    array: T[],
    keySelector: (el: T) => string,
    aggregateFunc: (values: T[], group: string) => U,
): Index<U> {
    const result: Index<T[]> = {}
    for (let i = 0; i < array.length; i++) {
        const el = array[i]
        const key = keySelector(el)
        if (!result[key]) {
            result[key] = []
        }

        result[key].push(el)
    }

    return mapValues(result, aggregateFunc)
}

export function flatMap<T, U>(arr: T[], projection: (el: T) => U[]): U[] {
    return ([] as U[]).concat(...arr.map(projection))
}

const hasOwn = Object.prototype.hasOwnProperty

function is(x: any, y: any) {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y
    }
    // eslint-disable-next-line
    return x !== x && y !== y
}

export function shallowEqual(objA: any, objB: any) {
    if (is(objA, objB)) {
        return true
    }
    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false
    }

    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    if (keysA.length !== keysB.length) {
        return false
    }

    for (let i = 0; i < keysA.length; i++) {
        if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
            return false
        }
    }

    return true
}

function setPath<T>(obj: T, path: string[], current: number, value: any): T {
    const objAsAny = obj as any
    const currentPathSegment = path[current]

    if (current >= path.length - 1) {
        return { ...objAsAny, [currentPathSegment]: value }
    }

    const inner = obj[currentPathSegment]
    return {
        ...objAsAny,
        [currentPathSegment]: setPath(inner, path, current + 1, value),
    }
}

export function setPropertyPath<T>(obj: T, path: string, value: any): T {
    const props = path.split('.')
    return setPath(obj, props, 0, value)
}

export function formatLastModified(dateString: string, by: string) {
    if (dateString === '' && by === '') {
        return 'n/a'
    }
    const date = parseISODate(dateString)
    return `${format(date, DateFormats.SHORT_DATE)} - ${by}`
}

export const conviousEmail = (email: string) => {
    return email.indexOf('convious') > -1 || email.indexOf('convio.us') > -1
}

export const roundNumber = (num: number, digits: number = 0): number => {
    const multiplier = Math.pow(10, digits)
    return Math.round(num * multiplier) / multiplier
}

export const abbreviateNumber = (num: number, decimals: number = 1): string => {
    if (isNaN(num) || num === null) {
        return ''
    }
    const isNegative = num < 0
    const absoluteNum = Math.abs(num)
    if (absoluteNum < 1000) {
        return isNegative ? `-${absoluteNum}` : absoluteNum.toString()
    }
    const units = ['', 'K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc', 'Ud', 'Dd', 'Td']
    const unit = Math.floor((absoluteNum.toFixed(0).length - 1) / 3)
    const numToDisplay = roundNumber(absoluteNum / Math.pow(1000, unit), decimals)
    return `${isNegative ? '-' : ''}${numToDisplay}${units[unit]}`
}

const toNumber = (num: number | string): number => {
    if (typeof num === 'number') {
        return num
    }
    return Number(num.replace(/,/g, ''))
}

export const addSeparators = (input: number | string, decimals?: number): string => {
    if (isNaN(toNumber(input))) {
        return '' + input
    }
    const num = toNumber(input)
    const numberString = decimals ? num.toFixed(decimals) : num.toString()
    const numberParts = numberString.split('.')
    const integerPart = numberParts[0]
    const decimalPart = numberParts[1]
    const integerPartWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${integerPartWithSeparators}${decimalPart ? `.${decimalPart}` : ''}`
}

export const keyAllowedForName = (keyCode: number) => {
    // Space, Backspace, Tab, Enter, Delete, all 4 Arrows, minus, dash, single quote
    const allowedKeyList = [32, 8, 9, 13, 46, 37, 38, 39, 40, 109, 189, 222]
    const keyAllowed = allowedKeyList.indexOf(keyCode) > -1
    const isAlphaOnly = keyCode >= 65 && keyCode <= 90
    return isAlphaOnly || keyAllowed
}

export const maskPersonalData = (value: string) => {
    const parts = value.split('@')
    parts[0] = parts[0][0] + '***'
    return parts.join('@')
}

export const maskFullNameValue = (name: string) => {
    const parts = name.split(' ')
    const mappedParts = parts.map((p) => p[0] + '***')
    return mappedParts.join(' ')
}

export const groupArrayByN = (n: number, data: any[]) => {
    let result = []
    for (let i = 0; i < data.length; i += n) {
        result.push(data.slice(i, i + n))
    }
    return result
}

export const slugifyName = (name: string) => {
    return `${name.toLowerCase().replace(/ /g, '_').replace(/[\W]+/g, '')}`
}

export const prettifySlug = (slug: string) => {
    const firstLetter = slug.slice(0, 1).toUpperCase()
    const prettyName = firstLetter + slug.substring(1)
    return prettyName.replace(/_/g, ' ')
}

export function generateRandomChars() {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < 2; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
