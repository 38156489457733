import React from 'react'
import { DateRange, dateRangeToQuery, dateRangeFromQuery } from 'dateRanges'
import { faCaretDown as IconDown, faCaretUp as IconUp } from '@fortawesome/free-solid-svg-icons'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { DateRangeType, DateRangeOptions } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { SingleSelect } from 'uiComponents/input'
import './searchAndDate.scss'

interface CartFiltersProps extends WithNavigationProps<BaseRouteParams> {}

const CartFilters: React.FC<CartFiltersProps> = ({ navigation }) => {
    const status = {
        isLoading: false,
    }
    const query = navigation.query()

    const onDateRangeChanged = (range: DateRange) => {
        const { dateRangeType } = navigation.query()

        navigation.addQueryWithReplace({
            ...dateRangeToQuery(range),
            dateRangeType: dateRangeType || initialValue,
            page: '1',
        })
    }

    const onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        navigation.addQueryWithReplace({ dateRangeType: rangeType, page: '1' })
    }

    const dateRange = dateRangeFromQuery(query, 'last30days')
    const initialValue: DateRangeType =
        (query.dateRangeType as DateRangeType) || (DateRangeOptions[0].value as DateRangeType)

    return (
        <div className="carts-date-filter">
            <SingleSelect
                id="cart-daterange"
                options={DateRangeOptions}
                selected={initialValue}
                noSelectOption={initialValue}
                onSelect={onDateRangeTypeChanged}
                customIconDown={IconDown}
                customIconUp={IconUp}
                height="2rem"
            />
            <div className="carts-calendar">
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    dateRangeTypeDisabled={status.isLoading}
                    dateRangeType={initialValue}
                    onDateRangeTypeChange={onDateRangeTypeChanged}
                    allowFutureDateSelection
                    dateRangeOptions={DateRangeOptions}
                />
            </div>
        </div>
    )
}

export default withNavigation(CartFilters)
