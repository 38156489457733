import * as React from 'react'
import { ReactComponent } from 'reactUtils'
import { State } from 'store'
import { connect, useSelector } from 'react-redux'
import { Subtract } from 'utility-types'
import { Account } from 'auth/state'
import { getCurrentAccount } from 'auth/selectors'
import { addSeparators } from 'utils'

interface StateProps {
    accounts: Account[]
}

export interface CurrencyProps {
    formatCurrency?: (amount: number | string, accountSlug: string) => React.ReactNode
    formatCurrencyString?: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

const defaultCurrency = {
    code: 'EUR',
    symbol: '\u20ac',
    position: 'BEFORE',
    decimalPoints: 2,
}

export interface Currency {
    code: string
    symbol: string
    position: string
    decimalPoints: number
}

export const useCreateFormatCurrencyString = () => {
    const account = useSelector(getCurrentAccount)

    return (amount: number | string | null): string => {
        if (amount === null) {
            return '-'
        }

        const symbol = account && account.currencySymbol ? account.currencySymbol : defaultCurrency.symbol
        const position = account && account.currencyPosition ? account.currencyPosition : defaultCurrency.position
        const decimalPoints = account?.currencyDecimalPoints || defaultCurrency.decimalPoints
        const formattedAmount = isNaN(Number(amount)) ? amount : addSeparators(amount, decimalPoints)
        return position === defaultCurrency.position ? `${symbol} ${formattedAmount}` : `${formattedAmount} ${symbol}`
    }
}

export function withCurrency<TProps extends CurrencyProps>(
    Component: ReactComponent<TProps>,
): ReactComponent<Subtract<TProps, CurrencyProps>> {
    class WithCurrency extends React.Component<TProps & StateProps> {
        getCurrency = (accountSlug: string): Currency => {
            const account = this.props.accounts.find((x) => x.slug === accountSlug)
            const code = account && account.currencyCode ? account.currencyCode : defaultCurrency.code
            const symbol = account && account.currencySymbol ? account.currencySymbol : defaultCurrency.symbol
            const position = account && account.currencyPosition ? account.currencyPosition : defaultCurrency.position
            const decimalPoints = account && account.currencyDecimalPoints ? account.currencyDecimalPoints : 2
            return { code, symbol, position, decimalPoints }
        }

        formatCurrency = (amount: number | null, accountSlug: string): React.ReactNode => {
            if (amount === null) {
                return <>-</>
            }
            const account = this.props.accounts.find((x) => x.slug === accountSlug)
            const symbol = account && account.currencySymbol ? account.currencySymbol : defaultCurrency.symbol
            const position = account && account.currencyPosition ? account.currencyPosition : defaultCurrency.position
            const decimalPoints = account?.currencyDecimalPoints || defaultCurrency.decimalPoints
            const formattedAmount = isNaN(amount) ? amount : addSeparators(amount, decimalPoints)
            return position === defaultCurrency.position ? (
                <>
                    {symbol}&nbsp;{formattedAmount}
                </>
            ) : (
                <>
                    {formattedAmount}&nbsp;{symbol}
                </>
            )
        }

        formatCurrencyString = (amount: number | null, accountSlug: string): string => {
            if (amount === null) {
                return '-'
            }
            const account = this.props.accounts.find((x) => x.slug === accountSlug)
            const symbol = account && account.currencySymbol ? account.currencySymbol : defaultCurrency.symbol
            const position = account && account.currencyPosition ? account.currencyPosition : defaultCurrency.position
            const decimalPoints = account?.currencyDecimalPoints || defaultCurrency.decimalPoints
            const formattedAmount = isNaN(amount) ? amount : addSeparators(amount, decimalPoints)
            return position === defaultCurrency.position
                ? `${symbol} ${formattedAmount}`
                : `${formattedAmount} ${symbol}`
        }

        render() {
            return (
                <Component
                    formatCurrency={this.formatCurrency}
                    formatCurrencyString={this.formatCurrencyString}
                    // @ts-ignore
                    getCurrency={this.getCurrency}
                    {...this.props}
                />
            )
        }
    }

    function mapStateToProps(state: State) {
        return {
            accounts: state?.auth?.user?.accounts ?? [],
        }
    }

    // @ts-ignore
    const ConnectedComponent = connect(mapStateToProps)(WithCurrency)

    // eslint-disable-next-line react/display-name
    return function (props: Subtract<TProps, CurrencyProps>) {
        // @ts-ignore
        return <ConnectedComponent {...props} />
    }
}
