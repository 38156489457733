import { BaseRouteParams } from 'hocs'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-typed'
import { List, ListItem } from 'uiComponents/list'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { addSeparators } from 'utils'
import { DiscountCode } from 'venue/bookingCodes/bookingCodesService'

interface AffectsCellProps {
    productIds?: DiscountCode['products']
}

const StyledLink = styled(UnstyledLink)`
    color: ${(props) => props.theme.colors.boyBlue} !important;
    font-size: 14px;
`

const StyledText = styled.div`
    color: ${(props) => props.theme.colors.boyBlue};
    cursor: pointer;
`

const AffectsCell: React.FC<AffectsCellProps> = ({ productIds }) => {
    const [open, setOpen] = useState<boolean>(false)
    const { accountSlug } = useParams<BaseRouteParams>()

    const formatPath = (id: string) => `/account/${accountSlug}/products/crud/${id}?from=nested`

    if (!productIds || !productIds.length) {
        return <>Cart</>
    }

    if (productIds.length === 1) {
        const product = productIds[0]
        return <StyledLink to={formatPath(product.uuid)}>{product.number}</StyledLink>
    }

    return (
        <>
            <StyledText onClick={() => setOpen((open) => !open)}>{addSeparators(productIds?.length)} products</StyledText>
            {open && (
                <ModalDialog onDismiss={() => setOpen(false)}>
                    <ModalDialogTitle>Affected products</ModalDialogTitle>
                    <List>
                        {productIds.map((product) => {
                            return (
                                <ListItem key={product.uuid}>
                                    <StyledLink to={formatPath(product.uuid)}>{product.number}</StyledLink>
                                </ListItem>
                            )
                        })}
                    </List>
                </ModalDialog>
            )}
        </>
    )
}

export default AffectsCell
