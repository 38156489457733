import * as React from 'react'
import { HeaderRow, TableHeader, Cell } from 'uiComponents/table'
import { NestedContainerWrapper } from 'uiComponents/table/nestedContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NestedTableRow, NestedTable } from 'uiComponents/table/nestedContainer'
import { uploadItemStatuMap } from 'venue/bookingCodes/uploadPage'
import { Text, StatusType } from 'uiComponents/typography'
import { ReservationCodesBatch, RemoveUnusedCodesItem } from 'venue/bookingCodes/bookingCodesService'
import { ActionsMenu } from './actionsMenu'
import { LoginService } from 'http/loginService'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { addSeparators } from 'utils'

interface BatchesTableProps {
    accountSlug: string
    poolId: string
    poolCodeBatches: ReservationCodesBatch[]
    backofficeEndpoint: string
    loginService: LoginService
    onDeleteBatch: (id: string) => void
    onRemoveUnused: (item: RemoveUnusedCodesItem) => void
}

export function BatchesTable(props: BatchesTableProps) {
    const columnWidths = ['4em', null, '12em', '12em', '17em', '8em']

    const typeMap = {
        generate_reservation_codes: 'Generated',
        vouchers_as_barcodes: 'Uploaded',
    }

    const getStatusText = (batch: ReservationCodesBatch) => {
        const status =
            batch.type === 'generate_reservation_codes' && ['error', 'expired'].includes(batch.status)
                ? 'generating_error'
                : batch.status
        return uploadItemStatuMap[status].text
    }

    return (
        <NestedContainerWrapper grade={0} className="expanded">
            <NestedTable columnWidths={columnWidths} id="campaigns-list">
                <HeaderRow>
                    <TableHeader nonInteractive />
                    <TableHeader noPadding style={{ width: 'unset', maxWidth: 'unset', minWidth: 'unset' }}>
                        Name
                    </TableHeader>
                    <TableHeader>Type</TableHeader>
                    <TableHeader>Redeemed</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader nonInteractive />
                </HeaderRow>
                {props.poolCodeBatches.length === 0 && <NoResultsRow text="There are no codes yet." />}
                {props.poolCodeBatches.map((batch) => {
                    return (
                        <div key={batch.uploadId}>
                            <NestedTableRow>
                                <Cell />
                                <Cell
                                    title={batch.filename}
                                    noPadding
                                    style={{
                                        width: 'unset',
                                        maxWidth: 'unset',
                                        minWidth: 'unset',
                                    }}
                                >
                                    <span>{batch.filename}</span>
                                </Cell>
                                <Cell>{typeMap[batch.type]}</Cell>
                                <Cell>
                                    {addSeparators(batch.redeemedCodes)}/{addSeparators(batch.totalCodes)}
                                </Cell>
                                <Cell>
                                    <Text status={uploadItemStatuMap[batch.status].style as StatusType}>
                                        <FontAwesomeIcon
                                            icon={uploadItemStatuMap[batch.status].icon}
                                            style={{ marginRight: '.5em' }}
                                            className={batch.status === 'pending' ? 'fa-pulse' : ''}
                                        />
                                        {getStatusText(batch)}
                                    </Text>
                                </Cell>
                                <Cell align="right" className="overflow-allowed">
                                    {batch.status === 'completed' && (
                                        <ActionsMenu
                                            accountSlug={props.accountSlug}
                                            loginService={props.loginService}
                                            poolId={props.poolId}
                                            batchId={batch.uploadId}
                                            backofficeEndpoint={props.backofficeEndpoint}
                                            onDelete={() => props.onDeleteBatch(batch.uploadId)}
                                            onRemoveUnused={props.onRemoveUnused}
                                        />
                                    )}
                                </Cell>
                            </NestedTableRow>
                        </div>
                    )
                })}
            </NestedTable>
        </NestedContainerWrapper>
    )
}
