import { ColumnConfig } from '.'

export const ORDERS_TABLE_COLUMNS_CONFIG = [
    {
        section: 'Order details',
        columns: [
            { name: 'Location', key: 'location' },
            { name: 'Order ID', key: 'id' },
            { name: 'Email', key: 'email' },
            { name: 'Quantity', key: 'quantity' },
            { name: 'Subtotal (tax incl.)', key: 'subtotalInclTax', feature: 'OrdersListPricesExcludingTax' },
            {
                name: 'Subtotal (tax excl.)',
                key: 'subtotalExclTax',
                feature: 'OrdersListPricesExcludingTax',
                forUSPartner: true,
            },
            { name: 'Discount', key: 'discountAmount' },
            { name: 'Code', key: 'discount_code' },
            { name: 'Total (tax incl.)', key: 'totalInclTax', feature: 'OrdersListPricesExcludingTax' },
            {
                name: 'Total (tax excl.)',
                key: 'totalExclTax',
                feature: 'OrdersListPricesExcludingTax',
                forUSPartner: true,
            },
            { name: 'Sale date', key: 'order_date' },
            { name: 'Payment date', key: 'payment_date' },
            { name: 'Order status', key: 'orderStatus' },
            { name: 'Payment status', key: 'paymentStatus' },
        ],
    },
    {
        section: 'Event information',
        columns: [
            { name: 'Visit date', key: 'visit_date' },
            { name: 'Email status', key: 'emailStatus' },
            { name: 'Event time', key: 'visit_time' },
        ],
    },
    {
        section: 'Transaction information',
        columns: [
            { name: 'Reseller', key: 'reseller' },
            { name: 'Downloaded on', key: 'downloaded' },
        ],
    },
]

export const ORDERS_TABLE_COLUMNS: ColumnConfig[] = ORDERS_TABLE_COLUMNS_CONFIG.flatMap((section, index) =>
    section.columns.map((column) => ({
        ...column,
        sectionKey: index,
        selected: true,
    })),
)

export const sanitizeTaxRelatedColumns = (
    columns: ColumnConfig[],
    isUSPartner: boolean,
    hasFeature: (feature: string) => boolean,
) =>
    columns
        .filter((col) => {
            if (col.forUSPartner && isUSPartner) {
                return true
            }
            if (!!col.feature && col.key !== 'subtotalInclTax' && col.key !== 'totalInclTax') {
                return hasFeature(col.feature)
            }

            return true
        })
        .map((col) => {
            if (col.feature) {
                if (col.key === 'subtotalInclTax') {
                    return {
                        ...col,
                        name: !isUSPartner && !hasFeature(col.feature) ? 'Subtotal' : 'Subtotal (tax incl.)',
                    }
                }
                if (col.key === 'totalInclTax') {
                    return { ...col, name: !isUSPartner && !hasFeature(col.feature) ? 'Total' : 'Total (tax incl.)' }
                }
            }

            return col
        })
