import * as React from 'react'
import styled from 'styled-typed'
import { StatsService } from 'http/statsService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell, Sorting } from 'uiComponents/table'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual, mapTableData } from 'reports/helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { DateRange } from 'dateRanges'
import {
    OverviewDataItem,
    OverviewTotals,
    ReportTimeIdentifierType,
    dummyDownloadData,
    DownloadData,
    DownloadHeader,
} from 'reports/schema'
import Money from 'uiComponents/money'
import { TableLoader } from 'uiComponents/loaders'
import { withFeatures } from 'features'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import ExportMenu from 'reports/exportMenu'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import Infotip from 'uiComponents/infotip'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { addSeparators } from 'utils'

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 2em 0 1.5em 0;
`

const OverviewRefundsDataRow = styled(DataRow)`
    border-top: 1px solid ${(props) => props.theme.colors.border};
`

const dummyTotals: OverviewTotals = {
    amount: 0,
    avgPrice: 0,
    avgPricePaid: 0,
    revenue: 0,
    collectedRevenue: 0,
    refundedAmount: 0,
    refundedMoney: 0,
    reservationsBooked: 0,
}

interface SalesAndRevenueTableProps {
    dateRange: DateRange
    filters: Filters[]
    timeIdentifierType: ReportTimeIdentifierType
    statsService: StatsService
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    loginService: LoginService
    loggingService: LoggingService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

interface SalesAndRevenueTableState {
    dataSeries: OverviewDataItem[]
    totals: OverviewTotals
    downloadData: DownloadData
    loading: boolean
}

class SalesAndRevenueTable extends React.Component<SalesAndRevenueTableProps, SalesAndRevenueTableState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: SalesAndRevenueTableProps) {
        super(props)
        this.state = {
            dataSeries: [],
            totals: dummyTotals,
            downloadData: dummyDownloadData,
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getOverviewData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: SalesAndRevenueTableProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters) ||
            prevProps.timeIdentifierType !== this.props.timeIdentifierType
        ) {
            await this.getOverviewData()
        }
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.sortBy !== prevQuery.sortBy || query.sortDirection !== prevQuery.sortDirection) {
            const sortedData = this.sortData(this.state.dataSeries)
            this.setState({ dataSeries: sortedData })
        }
    }

    getOverviewData = async () => {
        try {
            this.setState({ loading: true })
            const requestTime = new Date().valueOf()
            this._lastRequest = requestTime
            const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
            const data = await this.props.statsService.getOverviewReportTableStats(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                this.props.filters,
                this.props.timeIdentifierType,
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const sortedData = this.sortData(data.dataSeries)
            if (this._isMounted) {
                this.setState({
                    dataSeries: sortedData,
                    totals: data.totals,
                    loading: false,
                })
                const currency = this.props.getCurrency(this.props.accountSlug)
                this.createExportData(sortedData, data.totals, currency.symbol)
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Overview table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    createExportData = (data: OverviewDataItem[], totals: OverviewTotals, currencySymbol: string) => {
        const name = 'sales_overview_table'
        const headers: DownloadHeader[] = [
            { slug: 'product', label: 'Product', width: 40 },
            { slug: 'amount', label: 'Amount Sold', width: 15 },
            {
                slug: 'avg_price',
                label: `Avg List Price (${currencySymbol})`,
                width: 15,
            },
            {
                slug: 'avg_price_paid',
                label: `Avg Price Paid (${currencySymbol})`,
                width: 15,
            },
            {
                slug: 'collected_revenue',
                label: `Collected Revenue (${currencySymbol})`,
                width: 15,
            },
            {
                slug: 'revenue',
                label: `Value of Tickets Sold (${currencySymbol})`,
                width: 15,
            },
        ]
        const values = mapTableData(data)
        values.push(['Reservations booked', totals?.reservationsBooked || 0, '', '', '', ''] as string[] | number[])
        values.push(['Refunded products', totals?.refundedAmount || 0, '', '', totals?.refundedMoney || 0, ''] as
            | string[]
            | number[])
        this.setState({ downloadData: { name, headers, values } })
    }

    sortByProduct = (item1: OverviewDataItem, item2: OverviewDataItem) => {
        const query = this.props.navigation.query()
        const sortKeyA = item1[query.sortBy || 'ticketType'].toUpperCase()
        const sortKeyB = item2[query.sortBy || 'ticketType'].toUpperCase()
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    sortByValue = (item1: OverviewDataItem, item2: OverviewDataItem) => {
        const query = this.props.navigation.query()
        const sortKeyA = item1[query.sortBy || 'amount']
        const sortKeyB = item2[query.sortBy || 'amount']
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    desc = (sortingFunc: (item1: OverviewDataItem, item2: OverviewDataItem) => {}) => {
        return (item1: OverviewDataItem, item2: OverviewDataItem) => -sortingFunc(item1, item2)
    }

    sortingStyle = {
        ticketType: this.sortByProduct,
        amount: this.sortByValue,
        avgPrice: this.sortByValue,
        revenue: this.sortByValue,
        collectedRevenue: this.sortByValue,
        avgPricePaid: this.sortByValue,
    }

    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    sortData = (dataSeries: OverviewDataItem[]) => {
        const query = this.props.navigation.query()
        const sortBy = query.sortBy || 'amount'
        const sortDirection = query.sortDirection || 'desc'
        let sortByFunction = this.sortingStyle[sortBy]
        if (sortDirection === 'desc') {
            sortByFunction = this.desc(sortByFunction)
        }
        return [...dataSeries].sort(sortByFunction)
    }

    render() {
        const query = this.props.navigation.query()
        const sortDirection = query.sortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.sortBy,
            direction: sortDirection,
        }
        const { dataSeries, totals, loading } = this.state

        const totalAmount = totals ? totals.amount + totals.refundedAmount + totals.reservationsBooked : 0
        const totalRevenue = totals ? totals.revenue + totals.refundedMoney : 0
        const totalCollectedRevenue = totals ? totals.collectedRevenue + totals.refundedMoney : 0

        const columnWidths = [
            null, // product
            '11em', // amount sold
            '11em', // avg.price
            '11.4em', // avg price paid
            '13.2em', // revenue collected
            '14.5em', // revenue
        ]

        const infotipMargin = '-0.3em'

        return (
            <div style={{ marginBottom: '2em' }}>
                <TitleWrapper>
                    <ChartHeadline size={4}>Sales/Revenue Overview</ChartHeadline>
                    <ExportMenu
                        accountSlug={this.props.accountSlug}
                        downloadData={this.state.downloadData}
                        loginService={this.props.loginService}
                        loggingService={this.props.loggingService}
                        replaceMessages={this.props.replaceTopMessages}
                        hideMessage={this.props.hideMessage}
                    />
                </TitleWrapper>
                <DataTable columnWidths={columnWidths}>
                    <HeaderRow>
                        <TableHeader sortKey="ticketType" sorting={sort} changeSort={this.onSortChanged}>
                            Product
                        </TableHeader>
                        <TableHeader sortKey="amount" sorting={sort} changeSort={this.onSortChanged}>
                            Amount Sold
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                Number of products sold.
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="avgPrice" sorting={sort} changeSort={this.onSortChanged}>
                            Avg List Price
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                Product price without discounts deducted.
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="avgPricePaid" sorting={sort} changeSort={this.onSortChanged}>
                            Avg Price Paid
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                Product price with discounts deducted.
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="collectedRevenue" sorting={sort} changeSort={this.onSortChanged}>
                            Collected Revenue
                            <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                                Revenue received for tickets at the Convious checkouts. Collected Revenue = Amount sold
                                * Avg Price Paid*.
                                <br />
                                <i>*Minor discrepancies might occur due to the rounding of the price.</i>
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="revenue" sorting={sort} changeSort={this.onSortChanged}>
                            Value of Tickets Sold
                            <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                                Total value of products sold at the Convious checkout, where product value corresponds
                                to product List Price. Value of Tickets sold = Amount sold * Avg List Price*.
                                <br />
                                <i>*Minor discrepancies might occur due to the rounding of the price.</i>
                            </Infotip>
                        </TableHeader>
                    </HeaderRow>
                    {loading && <TableLoader />}
                    {!loading && (
                        <>
                            {dataSeries.length > 0 &&
                                dataSeries.map((d) => (
                                    <DataRow key={d.ticketType} narrow>
                                        <Cell title={d.ticketType}>
                                            <span>{d.ticketType}</span>
                                        </Cell>
                                        <Cell align="right">{addSeparators(d.amount)}</Cell>
                                        <Cell align="right">
                                            <Money
                                                amount={d.avgPrice.toFixed(2)}
                                                accountSlug={this.props.accountSlug}
                                            />
                                        </Cell>
                                        <Cell align="right">
                                            <Money
                                                amount={d.avgPricePaid.toFixed(2)}
                                                accountSlug={this.props.accountSlug}
                                            />
                                        </Cell>
                                        <Cell align="right">
                                            <Money
                                                amount={d.collectedRevenue.toFixed(2)}
                                                accountSlug={this.props.accountSlug}
                                            />
                                        </Cell>
                                        <Cell align="right">
                                            <Money amount={d.revenue.toFixed(2)} accountSlug={this.props.accountSlug} />
                                        </Cell>
                                    </DataRow>
                                ))}
                            <OverviewRefundsDataRow narrow>
                                <Cell>Reservations booked</Cell>
                                <Cell align="right">{totals ? totals.reservationsBooked : 0}</Cell>
                                <Cell />
                                <Cell />
                                <Cell />
                                <Cell align="right" />
                            </OverviewRefundsDataRow>
                            <DataRow narrow>
                                <Cell>Refunded products</Cell>
                                <Cell align="right">{totals ? totals.refundedAmount : 0}</Cell>
                                <Cell />
                                <Cell />
                                <Cell align="right">
                                    <Money
                                        amount={totals && totals.refundedMoney ? totals.refundedMoney.toFixed(2) : 0}
                                        accountSlug={this.props.accountSlug}
                                    />
                                </Cell>
                                <Cell />
                            </DataRow>
                            <DataRow narrow bold>
                                <Cell>Total</Cell>
                                <Cell align="right">{totalAmount}</Cell>
                                <Cell />
                                <Cell />
                                <Cell align="right">
                                    <Money
                                        amount={totalCollectedRevenue ? totalCollectedRevenue.toFixed(2) : 0}
                                        accountSlug={this.props.accountSlug}
                                    />
                                </Cell>
                                <Cell align="right">
                                    <Money
                                        amount={totalRevenue ? totalRevenue.toFixed(2) : 0}
                                        accountSlug={this.props.accountSlug}
                                    />
                                </Cell>
                            </DataRow>
                        </>
                    )}
                </DataTable>
            </div>
        )
    }
}

export default withCurrency(withFeatures(withNavigation(SalesAndRevenueTable)))
