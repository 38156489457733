import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious, useIsMounted } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChart from 'uiComponents/charts/barChart'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import { getTooltipRow, getTooltipHeader, getTooltipFooter } from 'reports/helpers'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, QueryData } from 'navigation'
import { DateRange } from 'dateRanges'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { addSeparators } from 'utils'

const queryConfig: QueryConfig = {
    querySetName: 'VerticalBarChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'customGroupBy',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'filters'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    dateRange: DateRange
    navigation: Navigation
    match: RouteMatch<{}>
    filters: Filters[]
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function VerticalBarChart(props: ChartsProps) {
    const isMounted = useIsMounted()
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [chartData, setChartData] = useState<DataTotal[]>([])
    const [metricName, setMetricName] = useState<string>('Avg Order value')

    const rangeName = 'discounts_cart'
    const defaultRange = 'order_value'
    const rangeOptions: PresetType[] = ['order_value', 'cart_size']

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevFilters = usePrevious(props.filters)
    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        const newQuery = props.navigation.query()
        if (
            prevAccountSlug !== props.accountSlug ||
            !isEqual(prevFilters, props.filters) ||
            prevQuery[rangeName] !== newQuery[rangeName] ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange)
        ) {
            getData(newQuery[rangeName] as PresetType)
        }
    }, [props.accountSlug, props.navigation.query(), props.filters, props.dateRange])

    const getData = async (presetRange: PresetType = 'order_value') => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        setLoading(true)
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        const metric =
            presetRange === 'order_value'
                ? 'compare_discount_coupons_order_value'
                : 'compare_discount_coupons_cart_size'
        try {
            const variables = {
                widget: props.accountSlug,
                metric,
                dateFrom,
                dateTo,
                groupBy: 'compare',
                filters: props.filters,
            }
            const stats = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const data = stats.customGroupBy.totals ? stats.customGroupBy.totals : []
            if (!isMounted.current) {
                return
            }
            setLoading(false)
            setMetricName(presetRange === 'order_value' ? 'Avg Order value' : 'Avg Cart size')
            setChartData(data)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const formatMoney = (amount: string | number) => {
        return props.formatCurrencyString(Number(amount).toFixed(2), props.accountSlug)
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        const axisValue = params[0].axisValue
        let tooltip = getTooltipHeader(axisValue)
        const value = metricName.includes('value') ? formatMoney(params[0].value) : addSeparators(params[0].value)
        tooltip += getTooltipRow([`${params[0].marker} ${metricName}:`, value])
        tooltip += getTooltipFooter()

        return tooltip
    }

    const popularHoursSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            data: chartData,
        },
    ]

    return (
        <ChartContainer>
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                {metricName}
                <PresetDateRangeElement presetName={rangeName} default={defaultRange} options={rangeOptions} />
            </ChartHeadline>
            <BarChart
                series={popularHoursSeries}
                chartHeight="348px"
                gridBottom={20}
                gridLeft={45}
                gridRight={10}
                gridTop={40}
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </ChartContainer>
    )
}

export default withNavigation(withCurrency(VerticalBarChart))
