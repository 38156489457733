import * as React from 'react'
import styled from 'styled-typed'
import { StatsService } from 'http/statsService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell, Sorting } from 'uiComponents/table'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { DateRange } from 'dateRanges'
import { dummyDownloadData, DownloadData, NotificationCampaignTableItem } from 'reports/schema'
import { TableLoader } from 'uiComponents/loaders'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import Infotip from 'uiComponents/infotip'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import Svg from 'svg'
import noDataIcon from 'uiComponents/charts/noData.svg'
import { addSeparators } from 'utils'

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 2em 0 1.5em 0;
`

const NoDataNoticeBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10em;
    margin-top: 1.5em;
`

export enum TABLE_TYPE {
    push_notification,
    chat_notifications,
}

interface TableProps {
    dateRange: DateRange
    filters: Filters[]
    statsService: StatsService
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    type: TABLE_TYPE
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
}

interface TableState {
    dataSeries: NotificationCampaignTableItem[]
    downloadData: DownloadData
    loading: boolean
    showClicked: boolean
}

class NotificationCampaignTable extends React.Component<TableProps, TableState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: TableProps) {
        super(props)
        this.state = {
            dataSeries: [],
            downloadData: dummyDownloadData,
            loading: true,
            showClicked: props.type === TABLE_TYPE.chat_notifications,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getOverviewData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: TableProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            !isEqual(prevProps.filters, this.props.filters)
        ) {
            await this.getOverviewData()
        }
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.sortBy !== prevQuery.sortBy || query.sortDirection !== prevQuery.sortDirection) {
            const sortedData = this.sortData(this.state.dataSeries)
            this.setState({ dataSeries: sortedData })
        }
    }

    getOverviewData = async () => {
        try {
            const requestTime = new Date().valueOf()
            this._lastRequest = requestTime

            this.setState({ loading: true })
            const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')

            const filterValue =
                this.props.type === TABLE_TYPE.chat_notifications ? 'chat_notifications' : 'push_notifications'
            const filters = [{ attribute: 'tap_notifications_category', values: [filterValue] }]

            const data = await this.props.statsService.getNotificationCampaignOverviewStats(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                filters,
            )

            if (this._lastRequest !== requestTime) {
                return
            }

            const formatted: NotificationCampaignTableItem[] = data.data.map((item: any) => {
                return {
                    campaign_name: item[0],
                    sent: item[1],
                    received: item[2],
                    opened: item[3],
                    clicked: item[4],
                    opt_out: item[5],
                    p_received: parseFloat(item[6]),
                    p_opened: parseFloat(item[7]),
                    p_clicked: parseFloat(item[8]),
                    p_opt_out: parseFloat(item[9]),
                }
            })

            const sortedData = this.sortData(formatted)
            if (this._isMounted) {
                this.setState({ dataSeries: sortedData, loading: false })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Notification campaign table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    sortByCampaignName = (item1: NotificationCampaignTableItem, item2: NotificationCampaignTableItem) => {
        const query = this.props.navigation.query()
        const sortKeyA = item1[query.sortBy || 'campaign_name'].toUpperCase()
        const sortKeyB = item2[query.sortBy || 'campaign_name'].toUpperCase()
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    sortByValue = (item1: NotificationCampaignTableItem, item2: NotificationCampaignTableItem) => {
        const query = this.props.navigation.query()
        let sortKeyA = item1[query.sortBy || 'sent']
        let sortKeyB = item2[query.sortBy || 'sent']

        if (parseFloat(sortKeyA)) {
            sortKeyA = parseFloat(sortKeyA)
        }

        if (parseFloat(sortKeyB)) {
            sortKeyB = parseFloat(sortKeyB)
        }

        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    desc = (sortingFunc: (item1: NotificationCampaignTableItem, item2: NotificationCampaignTableItem) => {}) => {
        return (item1: NotificationCampaignTableItem, item2: NotificationCampaignTableItem) =>
            -sortingFunc(item1, item2)
    }

    sortingStyle = {
        campaign_name: this.sortByCampaignName,
        sent: this.sortByValue,
        opened: this.sortByValue,
        clicked: this.sortByValue,
        'opt-out': this.sortByValue,
    }

    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
            table: this.props.type.toString(),
        })
    }

    sortData = (dataSeries: NotificationCampaignTableItem[]) => {
        const query = this.props.navigation.query()

        if (query.table === this.props.type.toString()) {
            const sortBy = query.sortBy || 'campaign_name'
            const sortDirection = query.sortDirection || 'desc'
            let sortByFunction = this.sortingStyle[sortBy]
            if (sortDirection === 'desc') {
                sortByFunction = this.desc(sortByFunction)
            }
            return dataSeries.sort(sortByFunction)
        }

        return dataSeries
    }

    render() {
        const query = this.props.navigation.query()
        const sortDirection = query.sortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.sortBy,
            direction: sortDirection,
        }
        const { dataSeries, loading } = this.state

        const columnWidths = [
            null, // campaign name
            '9.2em', // sent
            '9.5em', // opened
            '9.9em', // clicked
            '11.7em', // opt-out
        ]

        const infotipMargin = '-0.3em'

        return (
            <div style={{ marginBottom: '2em' }}>
                <TitleWrapper>
                    <ChartHeadline size={4}>
                        {this.props.type === TABLE_TYPE.chat_notifications
                            ? 'Chat notifications campaigns'
                            : 'Push notifications campaigns'}
                    </ChartHeadline>
                </TitleWrapper>
                <DataTable columnWidths={columnWidths}>
                    <HeaderRow>
                        <TableHeader sortKey="campaign_name" sorting={sort} changeSort={this.onSortChanged}>
                            Campaign name
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                The name of the campaign
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="sent" sorting={sort} changeSort={this.onSortChanged}>
                            Sent
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                Amount of users reached
                            </Infotip>
                        </TableHeader>
                        <TableHeader sortKey="opened" sorting={sort} changeSort={this.onSortChanged}>
                            Opened
                            <Infotip pointer="left" maxWidth="30em" style={{ marginRight: infotipMargin }}>
                                Amount of users who opened the campaign message
                            </Infotip>
                        </TableHeader>
                        {this.state.showClicked && (
                            <TableHeader sortKey="clicked" sorting={sort} changeSort={this.onSortChanged}>
                                Clicked
                                <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                                    Amount of users who clicked on the chat button
                                </Infotip>
                            </TableHeader>
                        )}
                        <TableHeader sortKey="opt-out" sorting={sort} changeSort={this.onSortChanged}>
                            Opt-out
                            <Infotip pointer="right" maxWidth="32em" style={{ marginRight: infotipMargin }}>
                                Amount of users who opted out after the campaign
                            </Infotip>
                        </TableHeader>
                    </HeaderRow>
                    {loading && <TableLoader />}
                    {!loading && (
                        <>
                            {dataSeries.length > 0 &&
                                dataSeries.map((dataItem, index) => (
                                    <DataRow key={`${index}`} narrow>
                                        <Cell>{dataItem.campaign_name}</Cell>
                                        <Cell>{addSeparators(dataItem.sent)}</Cell>
                                        <Cell>
                                            {addSeparators(dataItem.opened)} ({(dataItem.p_opened * 100).toFixed(2)}
                                            %)
                                        </Cell>
                                        {this.state.showClicked && (
                                            <Cell>
                                                {addSeparators(dataItem.clicked)} (
                                                {(dataItem.p_clicked * 100).toFixed(2)}%)
                                            </Cell>
                                        )}
                                        <Cell>
                                            {addSeparators(dataItem.opt_out)} ({(dataItem.p_opt_out * 100).toFixed(2)}%)
                                        </Cell>
                                    </DataRow>
                                ))}
                        </>
                    )}
                    {dataSeries.length === 0 && (
                        <NoDataNoticeBlock>
                            <Svg src={noDataIcon} style={{ width: '6em' }} />
                            No data available
                        </NoDataNoticeBlock>
                    )}
                </DataTable>
            </div>
        )
    }
}

export default withNavigation(NotificationCampaignTable)
