import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal } from 'reports/schema'
import BarChartYAxis from 'uiComponents/charts/barChartYAxis'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { ChartHeadline, ChartContainer } from 'uiComponents/charts/styleComponents'
import { TooltipParams } from 'uiComponents/charts/timeseriesBarChart'
import { QueryConfig } from 'reports/queryGenerator'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DateRange } from 'dateRanges'
import { areDateRangeDatesEqual } from 'reports/helpers'
import groupBy from 'lodash/groupBy'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { addSeparators } from 'utils'

const queryConfig: QueryConfig = {
    querySetName: 'TopSellingProductsChart',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'groupBy', type: 'String' },
        { name: 'topN', type: 'Int' },
    ],
    queries: [
        {
            name: 'stats',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'groupBy', 'topN'],
            customVariables: [],
            presetResult: 'totals',
        },
    ],
}

interface ChartsProps {
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    dateRange: DateRange
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

function TopSellingChart(props: ChartsProps) {
    const _isMounted = useRef(false)
    const _lastRequest = useRef<number>()
    const statsService = useContext(StatsServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [topSellingProductsData, setTopSellingProductsData] = useState<DataTotal[]>([])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevDateRange = usePrevious(props.dateRange)
    useEffect(() => {
        _isMounted.current = true
        if (prevAccountSlug !== props.accountSlug || !areDateRangeDatesEqual(prevDateRange, props.dateRange)) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.dateRange])

    const getData = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        setLoading(true)
        const dateFrom = format(props.dateRange.from || new Date(), 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to || new Date(), 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: 'products_sold',
                dateFrom,
                dateTo,
                groupBy: 'product_name',
                topN: 4,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const stats = data.stats.totals || []
            const splitList = groupBy(stats, (s) => s.name === 'Other')
            const otherItemList = splitList.true || []
            const allButOtherItemList = splitList.false?.sort((a, b) => b.value - a.value) || []
            const sortedStats = [...allButOtherItemList, ...otherItemList]
            if (_isMounted.current) {
                setLoading(false)
                setTopSellingProductsData(sortedStats)
            }
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Top selling chart could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const chartTooltipFormatter = (params: TooltipParams[]) => {
        let tooltip = ''
        params.forEach((p) => {
            if (Number(p.value) > 0) {
                tooltip = p.seriesName
            }
        })
        return tooltip
    }

    const topSellingProductsSeries = topSellingProductsData.map((x) => ({
        name: `${x.name}: ${addSeparators(x.value)}`,
        type: 'bar',
        barWidth: '50%',
        stack: 1,
        data: topSellingProductsData.map((y) => ({
            name: y.name,
            value: x.name === y.name ? y.value : 0,
        })),
    }))

    const placeholderSeries = [
        {
            name: '',
            type: 'bar',
            barWidth: '60%',
            stack: 1,
            data: topSellingProductsData,
        },
    ]
    const navigationLink = `/account/${props.accountSlug}/reports/revenue/${
        location.search || '?'
    }&metric=products_sold`

    return (
        <ChartContainer className="clickable-title">
            {loading && <ChartDataLoader />}
            <ChartHeadline size={4}>
                <span>
                    <UnstyledLink to={navigationLink}>Top selling products</UnstyledLink>
                </span>
            </ChartHeadline>
            <BarChartYAxis
                series={topSellingProductsSeries.length === 0 ? placeholderSeries : topSellingProductsSeries}
                chartHeight="450px"
                loading={loading}
                tooltipFormatter={chartTooltipFormatter}
            />
        </ChartContainer>
    )
}

export default withNavigation(TopSellingChart)
