import * as React from 'react'
import styled from 'styled-typed'
import Infotip from 'uiComponents/infotip'
import { TargetType } from 'engageTools/studio/schema'
import { SecondaryText, SmallText, RegularText } from 'uiComponents/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { targetDevices } from 'engageTools/overview/index'
import { addSeparators } from 'utils'

export const Container = styled.div`
    flex: 1;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
        margin-right: 0;
    }
    border-right: 1px solid ${(p) => p.theme.colors.border};

    &.deviceType {
        align-self: center;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`
const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 1.2em;
    transform: translateY(-50%);
    width: 1.3em;
    text-align: center;
`
const Title = styled.div`
    display: flex;
    align-items: baseline;

    .tooltip-toggle {
        font-size: 0.75em;
    }
`
const Data = styled.div`
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.15rem;
`

interface StatsElementProps {
    title: string
    data: string | number
    hideData: boolean
    qualifier?: string
    qualifierInFront?: boolean
    infotipText: string
    infotipPosition?: 'left' | 'right'
    infotipMaxWidth?: string
    style?: React.CSSProperties
}

function StatsElement(props: StatsElementProps) {
    const { title, data, hideData, qualifier, qualifierInFront, infotipText, infotipPosition, infotipMaxWidth } = props

    return (
        <Container style={props.style}>
            {!hideData && (
                <Data>
                    {qualifierInFront && qualifier}
                    {addSeparators(data)}
                    {!qualifierInFront && qualifier}
                </Data>
            )}
            {hideData && <Data>-</Data>}
            <Title>
                <SecondaryText>
                    <SmallText>{title}</SmallText>
                </SecondaryText>
                <Infotip pointer={infotipPosition ? infotipPosition : 'left'} maxWidth={infotipMaxWidth}>
                    {infotipText}
                </Infotip>
            </Title>
        </Container>
    )
}

interface DeviceTypeElementProps {
    device: TargetType | 'total'
    name?: string | null
}
export function DeviceTypeElement(props: DeviceTypeElementProps) {
    const { device, name } = props
    const deviceIcon = targetDevices.find((td) => td.id === device)
    const defaultDeviceName = device === 'any' ? 'Default' : device.charAt(0).toUpperCase() + device.slice(1)

    return (
        <Container className={name ? 'withName deviceType' : 'deviceType'} title={name || defaultDeviceName}>
            {deviceIcon ? (
                <IconContainer>
                    <FontAwesomeIcon icon={deviceIcon.icon} />
                </IconContainer>
            ) : (
                ''
            )}
            <RegularText>{name || defaultDeviceName}</RegularText>
        </Container>
    )
}

export default StatsElement
