import React from 'react'

import { PageHeading, PageTitle } from 'uiComponents/typography'
import SearchField from 'uiComponents/search/searchField'
import CartTable from './cartTable'
import ErrorBoundary from 'uiComponents/errorBoundary'
import CartFilters from './filters/searchAndDate'
import './cartPage.scss'

const Cart: React.FC = ({ children }) => (
    <div id="cart__elem">
        <PageTitle>Pending Reservations</PageTitle>
        <PageHeading fullWidth>
            Manage pending reservations by updating guest count, deadlines, products, notes, and payment status. Learn
            more in our&nbsp;
            <a target="_blank" href="https://support.convious.com/help/pending-reservations" rel="noreferrer">
                Knowledge Base
            </a>
            .
        </PageHeading>
        <div className="cart-filters-container">
            <SearchField placeholder="Search by Email, Reservation ID" className="cart-search" />
            <CartFilters />
        </div>
        <div>
            <CartTable />
        </div>
        <ErrorBoundary>{children}</ErrorBoundary>
    </div>
)

export default Cart
