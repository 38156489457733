import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { MessageProps, WithNavigationProps, withMessages, withNavigation } from 'hocs'
import { resetState, selectOrder } from '../redux'
import OrderInfo from './orderInfo'
import OrderItems from './OrderItems'
import OrderTicketsActions from '../commons/orderTicketsActions'
import { Messages } from 'uiComponents/messages'
import './index.scss'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useGetOrderDetailsByUuidQuery } from 'orders/reduxQueries'
import { PageLoader } from 'uiComponents/loaders'
import { FillDetailsAlert } from '../commons/orderTicketsActions/fillDetails/fillDetailsAlert'
import { getIsUSPartner } from 'auth/selectors'
import { useHasFeature } from 'utils/useHasFeature'

interface OrdersDetailsLocationState {
    ordersListParams: string
}

interface OrdersDetailsProps extends WithNavigationProps<{ id: string; accountSlug: string }> {}

const OrderDetails: React.FC<OrdersDetailsProps & MessageProps> = ({
    match,
    replaceMessages,
    messages,
    hideMessage,
}) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { accountSlug, id } = match.params
    const location = useLocation()
    const locationState = useRef(location.state as OrdersDetailsLocationState)
    const isUSPartner = useAppSelector(getIsUSPartner)
    const showPricesWithoutTaxFeature = useHasFeature({ featureName: 'OrdersListPricesExcludingTax' })
    const shouldShowPricesWithoutTax = isUSPartner ? true : showPricesWithoutTaxFeature
    const { data: order, isFetching } = useGetOrderDetailsByUuidQuery({
        uuid: id,
        include: [
            'items',
            'items.options',
            'items.barcode',
            'items.refund_info',
            'payments',
            'discounts',
            'reseller',
            'customer',
            'location',
        ],
    })

    useEffect(() => {
        dispatch(selectOrder(order))
    }, [order])

    useEffect(() => {
        return () => {
            dispatch(resetState())
            dispatch(selectOrder(null))
        }
    }, [])

    const navigateToOrders = () => {
        const params = locationState.current?.ordersListParams
        history.push(`/account/${accountSlug}/orders/orders${params || ''}`)
    }

    if (isFetching) {
        return <PageLoader />
    }

    return (
        <div className="order-details">
            <h1>Order details</h1>
            <Messages messages={messages} hideMessage={hideMessage} />
            <NavigationBack onClick={navigateToOrders} text="Back to orders" />
            <div className="container">
                <OrderInfo order={order} />
                <div className="action-bar">
                    <OrderTicketsActions replaceMessages={replaceMessages} hideMessage={hideMessage} />
                </div>
                <FillDetailsAlert />
                <OrderItems
                    order={order}
                    accountSlug={accountSlug}
                    replaceMessages={replaceMessages}
                    shouldShowPricesWithoutTax={shouldShowPricesWithoutTax}
                    hideMessage={hideMessage}
                />
            </div>
        </div>
    )
}

export default withNavigation(withMessages(OrderDetails))
