import React from 'react'
import { useDispatch } from 'react-redux'
import { Text } from 'uiComponents/typography'
import { TableColumn } from 'uiComponents/table/reactTable'
import { DiscountCodesElement } from 'orders/helpers'
import { specifyOrderStatus, IOrder, IOrderPayment } from 'orders/schema'
import Money from 'uiComponents/money'
import { State } from 'store'
import InlineVisitDate from '../../commons/inlineVisitDate'
import InlineVisitTime from '../../commons/inlineVisitTime'
import InlineEmail from '../../commons/inlineEmail'
import OrderId from '../../commons/orderId'
import styledComponent from 'styled-components'
import StatusLabel from '../../commons/orderStatusLabel'
import { useHasFeatureFunc } from 'utils/useHasFeature'
import { BaseRouteParams } from 'hocs'
import { useParams } from 'react-router-dom'
import { Checkbox } from 'uiComponents/input'
import {
    getIsOrderRowCheckboxDisabled,
    getOrderItems,
    getSelectedOrderById,
    getSelectedOrderItemsByTableId,
    getSelectedOrdersList,
    toggleAllOrderItems,
    toggleAllOrders,
    toggleOrder,
} from 'orders/orders/redux'
import { formatTimestamp, getDiscountCodesString, getLastPayment } from 'orders/orders/utils'
import { ChevronDownSolid, ChevronUpSolid } from '@convious/icons'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

const ClosedIcon = styledComponent(ChevronDownSolid)`
  color: ${(props) => props.theme.colors.aluminiumShades[10]};
  width: 20px;
  height: 20px;
`
const OpenedIcon = styledComponent(ChevronUpSolid)`
  color: ${(props) => props.theme.colors.aluminiumShades[30]};
  width: 20px;
  height: 20px;
`

export interface OrdersColumn extends TableColumn<IOrder> {
    withTimeSlots?: boolean
}

const paymentStatusesToIgnore = ['expired', 'open', 'pending', 'failed']
export const getPaymentToDisplay = (payments: IOrderPayment[]) => {
    const relevantPayments = payments.filter((payment) => !paymentStatusesToIgnore.includes(payment.paymentStatus))
    return getLastPayment(relevantPayments)
}

export const useOrdersColumns = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const accounts = useAppSelector((state) => (state.auth.user ? state.auth.user.accounts : []))
    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const withTimeSlots = activeAccount ? activeAccount.timeSlotsEnabled : false
    const hasFeature = useHasFeatureFunc()
    const dispatch = useDispatch()
    const columnsConfig = useAppSelector((state: State) => state.orderTable.tableColumns)
    const isUSPartner = useAppSelector(getIsUSPartner)

    const columns = React.useMemo(() => {
        const dataColumns = {
            location: {
                Header: 'Location',
                accessor: 'location.name',
                disableSortBy: true,
                width: '7.3rem',
            },
            id: {
                Header: 'ID',
                accessor: 'number',
                width: '6rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => <OrderId order={original} />,
            },
            email: {
                Header: 'Email',
                accessor: 'customer.email',
                width: '15.5rem',
                disableHideColumn: true,
                Cell: ({ row: { original } }) => <InlineEmail order={original} limitedTextWidth="15em" />,
            },
            quantity: {
                Header: 'Qty',
                accessor: 'quantity',
                width: '5.3rem',
                disableHideColumn: true,
                disableSortBy: true,
            },
            subtotalExclTax: {
                Header: 'Subtotal (excl. tax)',
                accessor: 'totalPriceExclTax',
                feature: isUSPartner ? undefined : 'OrdersListPricesExcludingTax',
                width: '9.8rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            subtotalInclTax: {
                Header: isUSPartner || hasFeature('OrdersListPricesExcludingTax') ? 'Subtotal (incl. tax)' : 'Subtotal',
                accessor: 'totalPriceInclTax',
                width: '9.6rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            discountAmount: {
                Header: 'Discount',
                accessor: 'discountAmount',
                width: '7rem',
                disableSortBy: true,
                Cell: ({ value }) => <Money amount={value > 0 ? `-${value}` : value} accountSlug={accountSlug} />,
            },
            discount_code: {
                Header: 'Code',
                accessor: 'discount_code',
                disableSortBy: true,
                width: '8rem',
                Cell: ({ row }) => {
                    const discountCodesString = getDiscountCodesString(row.original)
                    return (
                        <span title={discountCodesString}>
                            <DiscountCodesElement discountsString={discountCodesString} />
                        </span>
                    )
                },
            },
            totalExclTax: {
                Header: 'Total (excl. tax)',
                accessor: 'totalDiscountedPriceExclTax',
                feature: isUSPartner ? undefined : 'OrdersListPricesExcludingTax',
                width: '9.1rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            totalInclTax: {
                Header: isUSPartner || hasFeature('OrdersListPricesExcludingTax') ? 'Total (incl. tax)' : 'Total',
                accessor: 'totalDiscountedPriceInclTax',
                width: '9.1rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ value }) => <Money amount={value} accountSlug={accountSlug} />,
            },
            order_date: {
                Header: 'Sale date',
                accessor: 'order_date',
                width: '10rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return original.orderDate ? formatTimestamp(original.orderDate, original.timezone) : '-'
                },
            },
            payment_date: {
                Header: 'Payment updated at',
                accessor: 'payment_date',
                width: '10.5rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const lastPayment = getPaymentToDisplay(original.payments)
                    return lastPayment?.paymentDate ? formatTimestamp(lastPayment.paymentDate, original.timezone) : '-'
                },
            },
            visit_date: {
                Header: 'Visit date',
                accessor: 'visit_date',
                width: '8.5rem',
                disableHideColumn: true,
                Cell: ({ row: { original } }) => <InlineVisitDate order={original} />,
            },
            visit_time: {
                Header: 'Event time',
                accessor: 'visit_time',
                width: '8rem',
                withTimeSlots: true,
                disableHideColumn: true,
                Cell: ({ row: { original } }) => <InlineVisitTime order={original} />,
            },
            orderStatus: {
                Header: 'Order status',
                accessor: 'detailedStatus',
                width: '12.3rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const status = specifyOrderStatus(original)
                    const errorMessage = original.issueBarcodes?.error || original.confirmReservations?.error || ''
                    return <StatusLabel status={status} title={errorMessage} />
                },
            },
            paymentStatus: {
                Header: 'Payment status',
                accessor: 'paymentStatus',
                width: '9.3rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const lastPayment = getPaymentToDisplay(original.payments)

                    return <StatusLabel status={lastPayment?.paymentStatus ?? 'unpaid'} />
                },
            },
            paymentMethod: {
                Header: 'Payment method',
                accessor: 'paymentMethod',
                width: '9.3rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const lastPayment = getPaymentToDisplay(original.payments)
                    return <Text style={{ textTransform: 'capitalize' }}>{lastPayment?.paymentMethod ?? '-'}</Text>
                },
            },
            reseller: {
                Header: 'Reseller',
                accessor: 'reseller.name',
                width: '8rem',
                feature: 'ResellersFeature',
                disableSortBy: true,
                Cell: ({ value }) => <span>{value || '-'}</span>,
            },
            emailStatus: {
                Header: 'Email status',
                accessor: 'emailStatus',
                width: '9.3rem',
                disableHideColumn: true,
                disableSortBy: true,
                Cell: ({ value }) => (
                    <Text style={{ textTransform: 'capitalize' }}>
                        {value === 'failed' || value === 'disabled' ? (
                            <span style={{ color: 'red' }}>{value}</span>
                        ) : (
                            <>{value}</>
                        )}
                    </Text>
                ),
            },
            downloaded: {
                Header: 'Downloaded',
                accessor: 'downloaded',
                width: '11rem',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => (value ? formatTimestamp(value, original.timezone) : '-'),
            },
        } as Record<string, OrdersColumn>

        const _columns = [
            {
                Header: () => null,
                id: 'expander',
                accessor: 'expander',
                width: '2.5rem',
                disableSortBy: true,
                disableHideColumn: true,
                Cell: ({ row }) => (
                    <div {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <OpenedIcon /> : <ClosedIcon />}</div>
                ),
            },
            {
                accessor: 'selectable',
                width: '2.5rem',
                disableSortBy: true,
                disableHideColumn: true,
                Header: ({ rows }) => {
                    const selectedOrders = useAppSelector(getSelectedOrdersList).length

                    return (
                        <Checkbox
                            checked={selectedOrders > 0 || selectedOrders === rows.length}
                            data-testid="select-all-orders-checkbox"
                            onChange={(e) => {
                                dispatch(
                                    toggleAllOrders(
                                        rows.reduce((acc, row) => {
                                            return { ...acc, [row.original.id]: e.target.checked }
                                        }, {}),
                                    ),
                                )
                            }}
                            indeterminateState={selectedOrders > 0 && selectedOrders !== rows.length}
                        />
                    )
                },
                Cell: ({ row }) => {
                    const isSelected = useAppSelector(getSelectedOrderById(row.original.id))
                    const orderItems = useAppSelector(getOrderItems(row.original.id)) ?? []
                    const disabled = useAppSelector(getIsOrderRowCheckboxDisabled(row.original.id))
                    const selectedOrderItems = useAppSelector(getSelectedOrderItemsByTableId(row.original.id))

                    return (
                        <Checkbox
                            className="select-order"
                            data-testid="orders-select-order-checkbox"
                            checked={Object.values(selectedOrderItems).some((x) => x) || isSelected}
                            indeterminateState={!isSelected}
                            onChange={(e) => {
                                dispatch(toggleOrder({ orderUuid: row.original.id, selected: e.target.checked }))

                                dispatch(
                                    toggleAllOrderItems({
                                        tableId: row.original.id,
                                        rows: orderItems.reduce(
                                            (acc: { [itemUuid: string]: boolean }, item) => ({
                                                ...acc,
                                                [item.id]: !!item.refundInfo ? false : e.target.checked,
                                            }),
                                            {},
                                        ),
                                    }),
                                )
                            }}
                            disabled={disabled}
                        />
                    )
                },
            },
            ...columnsConfig.filter((column) => column.selected).map((column) => dataColumns[column.key]),
        ] as OrdersColumn[]

        return _columns.filter((column: OrdersColumn) => {
            if (!!column.feature) {
                return hasFeature(column.feature)
            }

            if (!!column.withTimeSlots) {
                return withTimeSlots
            }

            return true
        })
    }, [accountSlug, withTimeSlots, columnsConfig])

    return columns
}
