import React, { useState, useContext, useCallback } from 'react'
import { cloneDeep } from 'lodash'
import { DiscountRulesServiceContext } from 'products/discountRules/context'
import { CopyEditorServiceContext } from 'copyEditor/context'
import { MetadataContext } from 'products/discountRules/metadataContext'
import { RuleFormContext } from './formContext'
import { ActionButton } from 'uiComponents/buttons'
import { Translations, getCmsSlug } from 'admin/articleService'
import { textContainsSpecChars } from 'utils/formFieldChecks'
import { ensureRightLocaleCasing } from 'utils/languages'

function ActionsSection() {
    const discountRulesService = useContext(DiscountRulesServiceContext)
    const copyEditorService = useContext(CopyEditorServiceContext)
    const { accountSlug, history, replaceMessages } = useContext(MetadataContext)
    const { rule, formTranslations, locales, match, setLoading, setMarkErrors, setActiveLangIndex } =
        useContext(RuleFormContext)
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)

    const backToList = useCallback(() => {
        history.push(`/account/${accountSlug}/products/discount_rules/home`)
    }, [])

    const getPayloadTranslations = (translationsList: Translations) => {
        return Object.entries(translationsList.text).map(([locale, text]) => ({
            locale: ensureRightLocaleCasing(locale),
            text,
        }))
    }

    const getCopyPayload = useCallback(() => {
        const copySlug = rule.rulePayload.cmsKey ? rule.rulePayload.cmsKey.split('.')[1] : getCmsSlug(rule.name)
        const payload = {
            categorySlug: 'discount_rules',
            copySlug,
            translations: getPayloadTranslations(formTranslations),
        }
        return payload
    }, [rule.name, formTranslations])

    const isFormValid = useCallback(() => {
        let mandatoryFieldsError = false
        if (
            !rule.name.trim() ||
            !rule.categoryUuid ||
            !rule.rulePayload.numberOfItemsFree ||
            !rule.rulePayload.numberOfItemsPaid ||
            !rule.rulePayload.productUuidPaid ||
            !rule.rulePayload.productUuidFree
        ) {
            mandatoryFieldsError = true
        }

        let missingLang = ''
        locales.every((l, i) => {
            if (!formTranslations.text[l.code]?.trim()) {
                missingLang = l.code
                setActiveLangIndex(i)
                return false
            } else {
                return true
            }
        })

        const invalidName = textContainsSpecChars(rule.name)

        const validityError = rule.validFrom && rule.validTo && rule.validTo < rule.validFrom

        return !mandatoryFieldsError && !missingLang && !validityError && !invalidName
    }, [rule, formTranslations])

    const onSave = useCallback(async () => {
        if (!isFormValid()) {
            setMarkErrors(true)
            replaceMessages(
                'validation_error',
                'error',
                'Please complete all the missing fields and/or fix other marked errors.',
            )
            return
        }
        try {
            setLoading(true)
            if (match.params.ruleId === 'new') {
                const cmsKey = await copyEditorService.createCopy(accountSlug, getCopyPayload())
                const payload = cloneDeep(rule)
                payload.rulePayload.cmsKey = cmsKey
                await discountRulesService.createDiscountRule(accountSlug, payload)
            } else {
                const { categorySlug, copySlug, translations } = getCopyPayload()
                const copyKey = `${categorySlug}.${copySlug}`
                await copyEditorService.updateCopy(accountSlug, copyKey, translations)
                await discountRulesService.updateDiscountRule(accountSlug, rule)
            }
            backToList()
        } catch {
            setLoading(false)
            const action = match.params.ruleId === 'new' ? 'create' : 'update'
            replaceMessages('unknown_error', 'error', `Oops! We could not ${action} the rule. Please try again later.`)
        }
    }, [rule, formTranslations, getCopyPayload])

    const onDeleteConfirm = async () => {
        try {
            await discountRulesService.deleteDiscountRule(accountSlug, rule.id)
            backToList()
        } catch {
            replaceMessages('unknown_error', 'error', 'Oops! We could not delete the rule. Please try again later.')
        }
    }

    const onDelete = async () => {
        deleteConfirmation ? await onDeleteConfirm() : setDeleteConfirmation(true)
    }

    return (
        <>
            <div>
                {match.params.ruleId !== 'new' && (
                    <>
                        <ActionButton type="button" kind="destructive" size="large" secondary onClick={onDelete}>
                            Delete
                        </ActionButton>
                        {deleteConfirmation && (
                            <ActionButton
                                size="large"
                                secondary
                                onClick={() => setDeleteConfirmation(false)}
                                style={{ marginLeft: '1.5em' }}
                            >
                                Cancel
                            </ActionButton>
                        )}
                    </>
                )}
            </div>
            <div>
                <ActionButton size="large" secondary onClick={backToList}>
                    Cancel
                </ActionButton>
                <ActionButton
                    id="saveOverride"
                    type="button"
                    size="large"
                    onClick={onSave}
                    style={{ marginLeft: '1.5em' }}
                >
                    Save
                </ActionButton>
            </div>
        </>
    )
}

export default ActionsSection
