import * as React from 'react'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell, Sorting } from 'uiComponents/table'
import { TableLoader } from 'uiComponents/loaders'
import { EmailsData, EmailsDataItem } from 'reports/schema'
import { addSeparators } from 'utils'

interface MarketingEmailsTableProps {
    title: string
    dataSeries: EmailsData[]
    loading: boolean
    navigation: Navigation
    match: RouteMatch<{}>
    onSortChanged: any
}

const columnWidths = [null, '9em', '9em', '9em', '9em', '8em', '9.5em', '8em']

const getNumberWithPercentage = (dataItem: EmailsDataItem) => {
    return `${addSeparators(dataItem.number)} (${(dataItem.share * 100).toFixed(0)}%)`
}

class MarketingEmailsTable extends React.Component<MarketingEmailsTableProps> {
    render() {
        const { title, loading, dataSeries, onSortChanged } = this.props
        const query = this.props.navigation.query()
        const sortDirection = query.meSortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.meSortBy,
            direction: sortDirection,
        }

        return (
            <>
                <h4>{title}</h4>
                <DataTable columnWidths={columnWidths}>
                    <HeaderRow>
                        <TableHeader sortKey="emailName" sorting={sort} changeSort={onSortChanged}>
                            Name
                        </TableHeader>
                        <TableHeader sortKey="emailsSent" sorting={sort} changeSort={onSortChanged} align="right">
                            Emails sent
                        </TableHeader>
                        <TableHeader sortKey="emailsDelivered" sorting={sort} changeSort={onSortChanged} align="right">
                            Delivered
                        </TableHeader>
                        <TableHeader sortKey="emailsOpened" sorting={sort} changeSort={onSortChanged} align="right">
                            Opened
                        </TableHeader>
                        <TableHeader sortKey="emailsClicked" sorting={sort} changeSort={onSortChanged} align="right">
                            Clicked
                        </TableHeader>
                        <TableHeader sortKey="emailsBounced" sorting={sort} changeSort={onSortChanged} align="right">
                            Bounced
                        </TableHeader>
                        <TableHeader
                            sortKey="emailsUnsubscribed"
                            sorting={sort}
                            changeSort={onSortChanged}
                            align="right"
                        >
                            Unsubscribed
                        </TableHeader>
                        <TableHeader sortKey="emailsConverted" sorting={sort} changeSort={onSortChanged} align="right">
                            Converted
                        </TableHeader>
                    </HeaderRow>
                    {loading && <TableLoader />}
                    {!loading &&
                        dataSeries &&
                        dataSeries.map((d) => (
                            <DataRow key={d.emailName} narrow>
                                <Cell title={d.emailName}>
                                    <span>{d.emailName}</span>
                                </Cell>
                                <Cell align="right">{addSeparators(d.emailsSent)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsDelivered)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsOpened)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsClicked)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsBounced)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsUnsubscribed)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsConverted)}</Cell>
                            </DataRow>
                        ))}
                </DataTable>
            </>
        )
    }
}

export default withNavigation(MarketingEmailsTable)
