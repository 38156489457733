import {
    OrderRefundDetails,
    TicketInfoForRefund,
    TicketsForRefund,
} from 'orders/orders/commons/orderTicketsActions/cancelAndRefund/types'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'

export const calculateRefundFee = (total: number, fee: number | null, feeType: RefundFeeType | null) => {
    if (!!total && !!fee && !!feeType) {
        return feeType === 'absolute' ? fee : (total * fee) / 100
    }

    return 0
}

const getTicketTotalAmount = (ticket: TicketsForRefund) => {
    return ticket.items.reduce((s, item) => s + item.price, 0)
}

export const getTotalAmount = (tickets: TicketsForRefund[]) => {
    return tickets.reduce((s, t) => s + getTicketTotalAmount(t), 0).toFixed(2)
}

const checkStatusesEqual = (statuses: string[]) => {
    return statuses.every((s, i, arr) => s === arr[0])
}

export const getTicketItemsForRefund = (t: TicketInfoForRefund) => {
    const items = t.groupedTicketEntities.map((gte, j) => {
        const itemStatus = checkStatusesEqual(gte.map((te) => te.barcode?.status ?? 'none'))
            ? gte[0].barcode?.status ?? 'none'
            : 'partially redeemed'

        const price = t.isBundle
            ? t.bundleItemPricesInclTax
                ? t.bundleItemPricesInclTax[j]
                : t.discountedItemPriceInclTax
            : gte[0]?.priceInclTax || 0

        return {
            ticketEntities: gte,
            ticketUuids: gte.map((i) => i.id),
            barcodeList: gte.map((b) => b.barcode?.barcode || ''),
            status: itemStatus,
            itemRefundable: gte.filter((i) => !i.canRefund).length === 0,
            price: Number(price),
        }
    })
    return items
}

export const getTicketsForRefund = (order: OrderRefundDetails): TicketsForRefund[] => {
    const mappedTicketItemsData = order.tickets.map((t) => ({
        ...t,
        items: getTicketItemsForRefund(t),
    }))
    const mappedTicketsData = mappedTicketItemsData.map((t) => ({
        ...t,
        status: checkStatusesEqual(t.items.map((b) => b.status)) ? t.items[0].status : 'partial',
    }))
    return mappedTicketsData
}

interface CalculateOrderRefund {
    order: OrderRefundDetails
    fullRefundOnly?: boolean
}

export const calculateOrderTotalAmount = ({ order, fullRefundOnly }: CalculateOrderRefund = {} as any) => {
    const ticketsForRefund = getTicketsForRefund(order)
    const refundableTicketsList = ticketsForRefund
        .filter((t) => t.canRefund)
        .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))

    const totalAmount = fullRefundOnly
        ? Number(order.totalDiscountedPriceInclTax)
        : Number(getTotalAmount(refundableTicketsList))

    return totalAmount
}

export const calculateOrderPartnerRefundFee = ({ order, fullRefundOnly }: CalculateOrderRefund) => {
    const totalAmount = calculateOrderTotalAmount({ order, fullRefundOnly })

    return calculateRefundFee(totalAmount, Number(order.partnerRefundFee), order.partnerRefundFeeType) || 0
}
