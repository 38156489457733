import * as React from 'react'
import { usePrevious } from 'reactUtils'
import { StatsServiceContext } from 'http/context'
import { DataTotal, DownloadData, dummyDownloadData } from 'reports/schema'
import { DateRange } from 'dateRanges'
import { Row, Col } from 'uiComponents/flex'
import PieChart from 'uiComponents/charts/pieChart'
import { chartRowsGap, mapPieChartExportData } from 'reports/helpers'
import { format } from 'date-fns'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { areDateRangeDatesEqual, revenuePageMetricMap } from 'reports/helpers'
import { Filters } from 'uiComponents/filter/schema'
import isEqual from 'lodash/isEqual'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { RevenuePageMetricType, ReportTimeIdentifierType } from 'reports/schema'
import { ChartHeadline } from 'uiComponents/charts/styleComponents'
import { TrafficSourseInfotipContent } from 'reports/chartTooltips'
import Infotip from 'uiComponents/infotip'
import { QueryConfig } from 'reports/queryGenerator'
import ExportMenu from 'reports/exportMenu'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'

const queryConfig: QueryConfig = {
    querySetName: 'RevenuePagePieCharts',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'metric', type: 'StatsMetric' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
        { name: 'timeIdentifierType', type: 'TimeIdentifierType' },
    ],
    queries: [
        {
            name: 'paymentMethod',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'groupBy', customValue: '"payment_method"' }],
            presetResult: 'totals',
        },
        {
            name: 'deviceType',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'groupBy', customValue: '"device_type"' }],
            presetResult: 'totals',
        },
        {
            name: 'acquisitionChannel',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [
                {
                    name: 'groupBy',
                    customValue: '"website_traffic_acquisition_channel"',
                },
            ],
            presetResult: 'totals',
        },
        {
            name: 'itemName',
            type: 'stats',
            configVariables: ['widget', 'metric', 'dateFrom', 'dateTo', 'filters', 'timeIdentifierType'],
            customVariables: [{ name: 'groupBy', customValue: '"product_name"' }],
            presetResult: 'totals',
        },
    ],
}

interface PieChartsProps {
    dateRange: DateRange
    timeIdentifierType: ReportTimeIdentifierType
    filters: Filters[]
    accountSlug: string
    metric: RevenuePageMetricType
    loginService: LoginService
    loggingService: LoggingService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

function RevenuePieCharts(props: PieChartsProps) {
    const _isMounted = React.useRef(false)
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [itemNameData, setItemNameData] = React.useState<DataTotal[]>([])
    const [deviceTypeData, setDeviceTypeData] = React.useState<DataTotal[]>([])
    const [acquisitionChannelData, setAcquisitionChannelData] = React.useState<DataTotal[]>([])
    const [paymentMethodData, setPaymentMethodData] = React.useState<DataTotal[]>([])
    const [itemNameDownloadData, setItemNameDownloadData] = React.useState<DownloadData>(dummyDownloadData)
    const [deviceTypeDownloadData, setDeviceTypeDownload] = React.useState<DownloadData>(dummyDownloadData)
    const [acqChDownloadData, setAcqChDownloadData] = React.useState<DownloadData>(dummyDownloadData)
    const [paymentMethodDownloadData, setPaymentMethodDownloadData] = React.useState<DownloadData>(dummyDownloadData)

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevMetric = usePrevious(props.metric)
    const prevDateRange = usePrevious(props.dateRange)
    const prevFilters = usePrevious(props.filters)
    const prevTimeIdentifierType = usePrevious(props.timeIdentifierType)
    React.useEffect(() => {
        _isMounted.current = true
        if (
            prevAccountSlug !== props.accountSlug ||
            prevMetric !== props.metric ||
            !areDateRangeDatesEqual(prevDateRange, props.dateRange) ||
            !isEqual(prevFilters, props.filters) ||
            prevTimeIdentifierType !== props.timeIdentifierType
        ) {
            getData()
        }
        return () => {
            _isMounted.current = false
        }
    }, [props.accountSlug, props.metric, props.dateRange, props.filters, props.timeIdentifierType])

    React.useEffect(() => {
        if (!loading && _isMounted.current) {
            const currency = props.getCurrency(props.accountSlug)
            const symbol = props.metric === 'revenue' ? currency.symbol : ''
            setItemNameDownloadData(mapPieChartExportData(getChartName('product'), itemNameData, symbol))
            setDeviceTypeDownload(mapPieChartExportData(getChartName('device'), deviceTypeData, symbol))
            setAcqChDownloadData(mapPieChartExportData(getChartName('channel'), acquisitionChannelData, symbol))
            setPaymentMethodDownloadData(mapPieChartExportData(getChartName('payment'), paymentMethodData, symbol))
        }
    }, [loading])

    const getData = async () => {
        setLoading(true)
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime
        const dateFrom = format(props.dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(props.dateRange.to, 'yyyy-MM-dd')
        try {
            const variables = {
                widget: props.accountSlug,
                metric: props.metric,
                dateFrom,
                dateTo,
                filters: props.filters,
                timeIdentifierType: props.timeIdentifierType,
            }
            const data = await statsService.getStats(queryConfig, variables)
            if (_lastRequest.current !== requestTime) {
                return
            }
            const itemName = data.itemName.totals ? data.itemName.totals : []
            const deviceType = data.deviceType.totals ? data.deviceType.totals : []
            const acquisitionChannel = data.acquisitionChannel.totals ? data.acquisitionChannel.totals : []
            const paymentMethod = data.paymentMethod.totals ? data.paymentMethod.totals : []
            if (_isMounted.current) {
                setItemNameData(sort(itemName))
                setDeviceTypeData(sort(deviceType))
                setAcquisitionChannelData(sort(acquisitionChannel))
                setPaymentMethodData(sort(paymentMethod))
                setLoading(false)
            }
        } catch {
            props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Some charts could not be loaded, please try again later.',
            )
        }
    }

    const sort = (data: DataTotal[]) => {
        return data.sort((a, b) => b.value - a.value)
    }

    const getChartName = (type: 'product' | 'device' | 'channel' | 'payment') => {
        switch (type) {
            case 'product':
                return `${revenuePageMetricMap[props.metric]} per product`
            case 'device':
                return `${revenuePageMetricMap[props.metric]} per device`
            case 'channel':
                return `${revenuePageMetricMap[props.metric]} per acquisition channel`
            case 'payment':
                return `${revenuePageMetricMap[props.metric]} per payment type`
            default:
                return ''
        }
    }

    return (
        <div style={{ marginTop: chartRowsGap, position: 'relative' }}>
            {loading && <ChartDataLoader />}
            <Row>
                <Col span={6}>
                    <ChartContainer>
                        <ExportMenu
                            accountSlug={props.accountSlug}
                            downloadData={itemNameDownloadData}
                            loginService={props.loginService}
                            loggingService={props.loggingService}
                            replaceMessages={props.replaceTopMessages}
                            hideMessage={props.hideMessage}
                            withinChartWrapper
                        />
                        <ChartHeadline size={4}>{getChartName('product')}</ChartHeadline>
                        <PieChart
                            data={itemNameData}
                            accountSlug={props.accountSlug}
                            currency={props.metric === 'revenue'}
                            loading={loading}
                            chartHeight="430px"
                            legendTop={305}
                        />
                    </ChartContainer>
                </Col>
                <Col span={6}>
                    <ChartContainer>
                        <ExportMenu
                            accountSlug={props.accountSlug}
                            downloadData={deviceTypeDownloadData}
                            loginService={props.loginService}
                            loggingService={props.loggingService}
                            replaceMessages={props.replaceTopMessages}
                            hideMessage={props.hideMessage}
                            withinChartWrapper
                        />
                        <ChartHeadline size={4}>{getChartName('device')}</ChartHeadline>
                        <PieChart
                            data={deviceTypeData}
                            accountSlug={props.accountSlug}
                            currency={props.metric === 'revenue'}
                            loading={loading}
                            chartHeight="430px"
                            legendTop={305}
                        />
                    </ChartContainer>
                </Col>
            </Row>
            <Row style={{ marginTop: chartRowsGap }}>
                <Col span={6}>
                    <ChartContainer>
                        <ExportMenu
                            accountSlug={props.accountSlug}
                            downloadData={acqChDownloadData}
                            loginService={props.loginService}
                            loggingService={props.loggingService}
                            replaceMessages={props.replaceTopMessages}
                            hideMessage={props.hideMessage}
                            withinChartWrapper
                        />
                        <ChartHeadline size={4}>
                            {getChartName('channel')}
                            <Infotip pointer="left" fixedMaxSize maxWidth="40em">
                                <TrafficSourseInfotipContent />
                            </Infotip>
                        </ChartHeadline>
                        <PieChart
                            data={acquisitionChannelData}
                            accountSlug={props.accountSlug}
                            currency={props.metric === 'revenue'}
                            loading={loading}
                            chartHeight="430px"
                            legendTop={305}
                        />
                    </ChartContainer>
                </Col>
                <Col span={6}>
                    <ChartContainer>
                        <ExportMenu
                            accountSlug={props.accountSlug}
                            downloadData={paymentMethodDownloadData}
                            loginService={props.loginService}
                            loggingService={props.loggingService}
                            replaceMessages={props.replaceTopMessages}
                            hideMessage={props.hideMessage}
                            withinChartWrapper
                        />
                        <ChartHeadline size={4}>{getChartName('payment')}</ChartHeadline>
                        <PieChart
                            data={paymentMethodData}
                            accountSlug={props.accountSlug}
                            currency={props.metric === 'revenue'}
                            loading={loading}
                            chartHeight="430px"
                            legendTop={305}
                        />
                    </ChartContainer>
                </Col>
            </Row>
        </div>
    )
}

export default withCurrency(RevenuePieCharts)
