import * as React from 'react'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { StatsService } from 'http/statsService'
import { DateRange } from 'dateRanges'
import { DataTable, HeaderRow, DataRow, TableHeader, Cell, Sorting } from 'uiComponents/table'
import { MessageKind } from 'uiComponents/messages'
import { EmailsData, EmailsDataItem } from 'reports/schema'
import { TableLoader } from 'uiComponents/loaders'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { format, startOfToday, addDays } from 'date-fns'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { addSeparators } from 'utils'

interface TransactionEmailsTableProps {
    statsService: StatsService
    dateRange: DateRange
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    title: string
    navigation: Navigation
    match: RouteMatch<{}>
    mapEmailName: (data: EmailsData[]) => EmailsData[]
}

interface TransactionEmailsTableState {
    dataSeries: EmailsData[]
    loading: boolean
}

const columnWidths = [null, '9em', '9em', '9em', '9em', '9em']

const getNumberWithPercentage = (dataItem: EmailsDataItem) => {
    return `${addSeparators(dataItem.number)} (${(dataItem.share * 100).toFixed(0)}%)`
}

class TransactionEmailsTable extends React.Component<TransactionEmailsTableProps, TransactionEmailsTableState> {
    _isMounted = false
    private _lastRequest?: number = undefined

    constructor(props: TransactionEmailsTableProps) {
        super(props)
        this.state = {
            dataSeries: [],
            loading: true,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        await this.getData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    async componentDidUpdate(prevProps: TransactionEmailsTableProps) {
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug
        ) {
            await this.getData()
        }
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.teSortBy !== prevQuery.teSortBy || query.teSortDirection !== prevQuery.teSortDirection) {
            const sortedData = this.state.dataSeries ? this.sortData(this.state.dataSeries) : this.state.dataSeries
            this.setState({ dataSeries: sortedData })
        }
    }

    getData = async () => {
        const requestTime = new Date().valueOf()
        this._lastRequest = requestTime

        try {
            this.setState({ loading: true })
            const dateFrom = format(this.props.dateRange.from || new Date(0), 'yyyy-MM-dd')
            const dateTo = format(this.props.dateRange.to || addDays(startOfToday(), 1), 'yyyy-MM-dd')
            const dataSeries = await this.props.statsService.getEmailsOverviewStats(
                this.props.accountSlug,
                dateFrom,
                dateTo,
                'transactional',
            )
            if (this._lastRequest !== requestTime) {
                return
            }
            const data = this.props.mapEmailName(dataSeries)
            const sortedData = data ? this.sortData(data) : data
            if (this._isMounted) {
                this.setState({ dataSeries: sortedData, loading: false })
            }
        } catch {
            this.props.replaceTopMessages(
                'server_error',
                'error',
                'Oops! Transactional emails table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    sortByValue = (item1: EmailsData, item2: EmailsData) => {
        const query = this.props.navigation.query()
        const sortByNonObject = !query.teSortBy || query.teSortBy === 'emailName' || query.teSortBy === 'emailsSent'
        const sortKeyA = sortByNonObject ? item1[query.teSortBy || 'emailName'] : item1[query.teSortBy].number
        const sortKeyB = sortByNonObject ? item2[query.teSortBy || 'emailName'] : item2[query.teSortBy].number
        if (sortKeyA < sortKeyB) {
            return -1
        }
        if (sortKeyA > sortKeyB) {
            return 1
        }
        return 0
    }

    desc = (sortingFunc: (item1: EmailsData, item2: EmailsData) => {}) => {
        return (item1: EmailsData, item2: EmailsData) => -sortingFunc(item1, item2)
    }

    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            teSortBy: sorting.prop,
            teSortDirection: sorting.direction,
        })
    }

    sortData = (dataSeries: EmailsData[]) => {
        const query = this.props.navigation.query()
        const sortByFunction =
            query.teSortDirection && query.teSortDirection === 'desc' ? this.desc(this.sortByValue) : this.sortByValue
        return [...dataSeries].sort(sortByFunction)
    }

    render() {
        const { loading, dataSeries } = this.state
        const query = this.props.navigation.query()
        const sortDirection = query.teSortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.teSortBy,
            direction: sortDirection,
        }

        return (
            <div style={{ marginBottom: '2em' }}>
                <h4>{this.props.title}</h4>
                <DataTable columnWidths={columnWidths}>
                    <HeaderRow>
                        <TableHeader sortKey="emailName" sorting={sort} changeSort={this.onSortChanged}>
                            Name
                        </TableHeader>
                        <TableHeader sortKey="emailsSent" sorting={sort} changeSort={this.onSortChanged} align="right">
                            Emails sent
                        </TableHeader>
                        <TableHeader
                            sortKey="emailsDelivered"
                            sorting={sort}
                            changeSort={this.onSortChanged}
                            align="right"
                        >
                            Delivered
                        </TableHeader>
                        <TableHeader
                            sortKey="emailsOpened"
                            sorting={sort}
                            changeSort={this.onSortChanged}
                            align="right"
                        >
                            Opened
                        </TableHeader>
                        <TableHeader
                            sortKey="emailsClicked"
                            sorting={sort}
                            changeSort={this.onSortChanged}
                            align="right"
                        >
                            Clicked
                        </TableHeader>
                        <TableHeader
                            sortKey="emailsBounced"
                            sorting={sort}
                            changeSort={this.onSortChanged}
                            align="right"
                        >
                            Bounced
                        </TableHeader>
                    </HeaderRow>
                    {loading && <TableLoader />}
                    {!loading &&
                        dataSeries &&
                        dataSeries.map((d) => (
                            <DataRow key={d.emailName} narrow>
                                <Cell title={d.emailName}>
                                    <span>{d.emailName}</span>
                                </Cell>
                                <Cell align="right">{addSeparators(d.emailsSent)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsDelivered)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsOpened)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsClicked)}</Cell>
                                <Cell align="right">{getNumberWithPercentage(d.emailsBounced)}</Cell>
                            </DataRow>
                        ))}
                    {!loading && (!dataSeries || dataSeries.length === 0) && (
                        <NoResultsRow item="transactional emails" />
                    )}
                </DataTable>
            </div>
        )
    }
}

export default withNavigation(TransactionEmailsTable)
