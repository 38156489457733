import React from 'react'
import { CursorPagination as ICursorPagination } from '../../../../../types'
import { ReactTableInstance } from '../interface'
import { SingleSelect } from 'uiComponents/input'
import { Button } from '@mui/material'
import { ChevronLeftSolid, ChevronRightSolid } from '@convious/icons'
import './index.scss'

interface CursorPaginationProps {
    pagination: ICursorPagination
    instance: ReactTableInstance
}

const parseCursorFromLink = (link?: string) => {
    if (!link) {
        return
    }

    const url = new URL(link)

    return url.searchParams.get('cursor') ?? url.searchParams.get('offset')
}

export const pageSizeOptions = ['10', '25', '50', '100']
const selectOptions = pageSizeOptions.map((o) => ({ value: o, name: o }))

const CursorPagination: React.FC<CursorPaginationProps> = ({ pagination, instance }) => {
    const hasPrevPage = pagination.previous && instance.setPaginationToken
    const hasNextPage = pagination.next && instance.setPaginationToken

    const setToken = (link?: string | null) => {
        if (link && instance.setPaginationToken) {
            instance.setPaginationToken(parseCursorFromLink(link) as string)
        }
    }

    const getPageCount = () => {
        const { previous, next } = pagination
        const parseCursor = (link: string) => Number(parseCursorFromLink(link))

        if (!previous) {
            return 1
        } else if (next) {
            const cursor = parseCursor(next)
            return Math.ceil(cursor / instance.state.pageSize)
        } else if (previous) {
            const cursor = parseCursor(previous)
            return Math.ceil(cursor / instance.state.pageSize) + 2
        }

        return 0
    }

    return (
        <div className="cursor-pagination-container">
            <div className="rows-per-page-container">
                <p className="body1 light">Rows per page:</p>
                <SingleSelect
                    noSelectOption="-"
                    options={selectOptions}
                    selected={instance.state.pageSize?.toString()}
                    onSelect={(size) => instance.setPageSize(Number.parseInt(size, 10))}
                    width="5em"
                    height="2rem"
                    style={{ margin: '0 0.25rem' }}
                />
            </div>
            <div className="pagination-controls">
                <Button
                    size="medium"
                    onClick={() => setToken(pagination.previous)}
                    disabled={!hasPrevPage}
                    startIcon={<ChevronLeftSolid />}
                >
                    Prev
                </Button>
                <div className="page-number">
                    <p className="body1 light">Page:</p>
                    <p className="body1">{getPageCount()}</p>
                </div>
                <Button
                    size="medium"
                    onClick={() => setToken(pagination.next)}
                    disabled={!hasNextPage}
                    endIcon={<ChevronRightSolid />}
                >
                    Next
                </Button>
            </div>
        </div>
    )
}

export default CursorPagination
