import React from 'react'
import styled from 'styled-typed'
import { ToggleComponent, FormItem, SectionWrapper } from 'ticketTemplates/studio/imports'
import { Col } from 'uiComponents/flex'
import Feature from 'features/feature'
import Infotip from 'uiComponents/infotip'
import { TemplateType } from 'ticketTemplates/ticketTemplatesService'

interface TogglesSectionProps {
    accountSlug: string
    templateType: TemplateType
    showBarcode: boolean
    showBarcodeNumber: boolean
    showPrice: boolean
    showPriceResellers: boolean
    showTax: boolean
    defaultTaxExist: boolean
    showFullName: boolean
    showOptions: boolean
    showTapQrCode: boolean
    printingRequired: boolean
    showEmail: boolean
    showOrderNumber: boolean
    showSupportEmail: boolean
    showPhoneNumber: boolean
    showAddress: boolean
    showOpeningHours: boolean
    setShowBarcode: (v: boolean) => void
    setShowBarcodeNumber: (v: boolean) => void
    setShowPrice: (v: boolean) => void
    setShowPriceResellers: (v: boolean) => void
    setShowTax: (v: boolean) => void
    setShowFullName: (v: boolean) => void
    setShowOptions?: (v: boolean) => void
    setShowTapQrCode?: (v: boolean) => void
    setPrintingRequired?: (v: boolean) => void
    setShowEmail: (v: boolean) => void
    setShowOrderNumber: (v: boolean) => void
    setShowSupportEmail: (v: boolean) => void
    setShowPhoneNumber: (v: boolean) => void
    setShowAddress: (v: boolean) => void
    setShowOpeningHours: (v: boolean) => void
}

const ToggleLabelCol = styled(Col)`
    display: flex;
    align-items: center;
`

export function TogglesSection(props: TogglesSectionProps) {
    type ToggleType = 'toggle'
    const printRequiredField = {
        name: 'print-required',
        type: 'toggle' as ToggleType,
        label: 'Printed ticket required on site',
        path: 'printRequired',
        defaultValue: true,
    }
    const showTaxField = {
        name: 'show-tax',
        type: 'toggle' as ToggleType,
        label: 'Show Tax amount',
        tooltip: 'Only the total Tax amount is shown, not the Tax rate.',
        path: 'showVat',
        defaultValue: false,
    }
    const showOrderNumberField = {
        name: 'show-order-number',
        type: 'toggle' as ToggleType,
        label: 'Show order number',
        path: '',
        defaultValue: true,
    }
    const showBarcodeField = {
        name: 'show-barcode',
        type: 'toggle' as ToggleType,
        label: 'Show barcode',
        path: 'showBarcode',
        defaultValue: true,
    }
    const showBarcodeNumberField = {
        name: 'show-barcode-number',
        type: 'toggle' as ToggleType,
        label: 'Show barcode number',
        path: 'showBarcodeNumber',
        defaultValue: true,
    }
    const showPriceField = {
        name: 'show-price',
        type: 'toggle' as ToggleType,
        label: 'Show price',
        path: '',
        defaultValue: true,
    }
    const showPriceResellersField = {
        name: 'show-price-resellers',
        type: 'toggle' as ToggleType,
        label: 'Show price on Reseller tickets',
        tooltip: 'Show the price on tickets sold by Resellers.',
        path: '',
        defaultValue: false,
    }
    const enableQrCodeField = {
        name: 'enable-qr-code',
        type: 'toggle' as ToggleType,
        label: 'Enable TAP QR Code',
        path: 'qrCodeEnabled',
        defaultValue: true,
    }
    const showFullNameField = {
        name: 'show-full-name',
        type: 'toggle' as ToggleType,
        label: 'Show full name',
        tooltip: "Show the ticket holder's or the buyer's full name, if collected in the after payment form.",
        path: '',
        defaultValue: false,
    }
    const showEmailField = {
        name: 'show-email',
        type: 'toggle' as ToggleType,
        label: 'Show email',
        path: '',
        defaultValue: false,
    }
    const showOptionsField = {
        name: 'show-options',
        type: 'toggle' as ToggleType,
        label: 'Show options',
        path: '',
        defaultValue: false,
    }
    const showSupportEmailField = {
        name: 'showSupportEmail',
        type: 'toggle' as ToggleType,
        label: 'Show support email',
        path: '',
        defaultValue: false,
    }
    const showPhoneNumberField = {
        name: 'showPhoneNumber',
        type: 'toggle' as ToggleType,
        label: 'Show phone number',
        path: '',
        defaultValue: false,
    }
    const showAddressField = {
        name: 'showAddressField',
        type: 'toggle' as ToggleType,
        label: 'Show address',
        path: '',
        defaultValue: false,
    }
    const showOpeningHoursField = {
        name: 'showOpeningHoursField',
        type: 'toggle' as ToggleType,
        label: 'Show opening hours',
        path: '',
        defaultValue: false,
    }

    return (
        <SectionWrapper title="Other Settings">
            {props.templateType === 'VOUCHER' && (
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor="show-order-number">{showOrderNumberField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showOrderNumberField}
                            handleChange={(v) => props.setShowOrderNumber(v as boolean)}
                            value={props.showOrderNumber}
                        />
                    </Col>
                </FormItem>
            )}
            <FormItem>
                <ToggleLabelCol span={6}>
                    <label htmlFor="show-barcode">{showBarcodeField.label}</label>
                </ToggleLabelCol>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={showBarcodeField}
                        handleChange={(v) => props.setShowBarcode(v as boolean)}
                        value={props.showBarcode}
                    />
                </Col>
            </FormItem>
            <FormItem>
                <ToggleLabelCol span={6}>
                    <label htmlFor="show-barcode-number">{showBarcodeNumberField.label}</label>
                </ToggleLabelCol>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={showBarcodeNumberField}
                        handleChange={(v) => props.setShowBarcodeNumber(v as boolean)}
                        value={props.showBarcodeNumber}
                    />
                </Col>
            </FormItem>
            <FormItem>
                <ToggleLabelCol span={6}>
                    <label htmlFor="show-price">{showPriceField.label}</label>
                </ToggleLabelCol>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={showPriceField}
                        handleChange={(v) => props.setShowPrice(v as boolean)}
                        value={props.showPrice}
                    />
                </Col>
            </FormItem>
            <Feature name="ResellersFeature" accountSlug={props.accountSlug}>
                <FormItem>
                    <ToggleLabelCol span={7}>
                        <label htmlFor="show-price-resellers" style={{ opacity: props.showPrice ? '1' : '0.5' }}>
                            {showPriceResellersField.label}
                        </label>
                        <Infotip maxWidth="15em" pointer="left">
                            {showPriceResellersField.tooltip}
                        </Infotip>
                    </ToggleLabelCol>
                    <Col span={5} className="justify-right">
                        <ToggleComponent
                            field={showPriceResellersField}
                            handleChange={(v) => props.setShowPriceResellers(v as boolean)}
                            value={props.showPrice && props.showPriceResellers}
                            disabled={!props.showPrice}
                        />
                    </Col>
                </FormItem>
            </Feature>
            <FormItem>
                <ToggleLabelCol span={6}>
                    <label htmlFor="show-tax">{showTaxField.label}</label>
                    <Infotip maxWidth="15em" pointer="left">
                        {showTaxField.tooltip}
                    </Infotip>
                </ToggleLabelCol>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={showTaxField}
                        handleChange={(v) => props.setShowTax(v as boolean)}
                        value={props.showTax}
                        disabled={!props.defaultTaxExist}
                    />
                </Col>
            </FormItem>
            <FormItem>
                <ToggleLabelCol span={6}>
                    <label htmlFor="show-full-name">{showFullNameField.label}</label>
                    <Infotip maxWidth="16em" pointer="left">
                        {showFullNameField.tooltip}
                    </Infotip>
                </ToggleLabelCol>
                <Col span={6} className="justify-right">
                    <ToggleComponent
                        field={showFullNameField}
                        handleChange={(v) => props.setShowFullName(v as boolean)}
                        value={props.showFullName}
                    />
                </Col>
            </FormItem>
            {props.templateType === 'VOUCHER' && (
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor="show-email">{showEmailField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showEmailField}
                            handleChange={(v) => props.setShowEmail(v as boolean)}
                            value={props.showEmail}
                        />
                    </Col>
                </FormItem>
            )}
            {props.templateType === 'TICKET' && (
                <>
                    <FormItem>
                        <ToggleLabelCol span={6}>
                            <label htmlFor="show-options">{showOptionsField.label}</label>
                        </ToggleLabelCol>
                        <Col span={6} className="justify-right">
                            <ToggleComponent
                                field={showOptionsField}
                                handleChange={(v) => props.setShowOptions && props.setShowOptions(v as boolean)}
                                value={props.showOptions}
                            />
                        </Col>
                    </FormItem>
                    <FormItem>
                        <ToggleLabelCol span={6}>
                            <label htmlFor="enable-qrcode">{enableQrCodeField.label}</label>
                        </ToggleLabelCol>
                        <Col span={6} className="justify-right">
                            <ToggleComponent
                                field={enableQrCodeField}
                                handleChange={(v) => props.setShowTapQrCode && props.setShowTapQrCode(v as boolean)}
                                value={props.showTapQrCode}
                            />
                        </Col>
                    </FormItem>
                    <FormItem>
                        <ToggleLabelCol span={6}>
                            <label htmlFor="printed-required">{printRequiredField.label}</label>
                        </ToggleLabelCol>
                        <Col span={6} className="justify-right">
                            <ToggleComponent
                                field={printRequiredField}
                                handleChange={(v) =>
                                    props.setPrintingRequired && props.setPrintingRequired(v as boolean)
                                }
                                value={props.printingRequired}
                            />
                        </Col>
                    </FormItem>
                </>
            )}
            <Feature name="ticketTemplateLocationInformation" accountSlug={props.accountSlug}>
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor={showSupportEmailField.name}>{showSupportEmailField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showSupportEmailField}
                            handleChange={(v) => props.setShowSupportEmail && props.setShowSupportEmail(v as boolean)}
                            value={props.showSupportEmail}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor={showPhoneNumberField.name}>{showPhoneNumberField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showPhoneNumberField}
                            handleChange={(v) => props.setShowPhoneNumber && props.setShowPhoneNumber(v as boolean)}
                            value={props.showPhoneNumber}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor={showAddressField.name}>{showAddressField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showAddressField}
                            handleChange={(v) => props.setShowAddress && props.setShowAddress(v as boolean)}
                            value={props.showAddress}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <ToggleLabelCol span={6}>
                        <label htmlFor={showOpeningHoursField.name}>{showOpeningHoursField.label}</label>
                    </ToggleLabelCol>
                    <Col span={6} className="justify-right">
                        <ToggleComponent
                            field={showOpeningHoursField}
                            handleChange={(v) => props.setShowOpeningHours && props.setShowOpeningHours(v as boolean)}
                            value={props.showOpeningHours}
                        />
                    </Col>
                </FormItem>
            </Feature>
        </SectionWrapper>
    )
}
