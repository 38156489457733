import React from 'react'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import ExpandIcon from 'orders/orders/commons/expandIcon'
import './index.scss'
import Money from 'uiComponents/money'
import { IOrderItemOption } from 'orders/schema'

interface ItemOptionsProps {
    data?: IOrderItemOption[]
    shouldShowPricesWithoutTax: boolean
}

const ItemOptions: React.FC<ItemOptionsProps> = ({ data, shouldShowPricesWithoutTax }) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    if (!data || data.length === 0) {
        return null
    }
    const [isExpanded, setIsExpanded] = React.useState(false)
    const totalPrice = React.useMemo(() => {
        return data.reduce((acc, option) => acc + Number(option.priceInclTax), 0)
    }, [data])

    return (
        <div className="item-options">
            <div className="header">
                <ExpandIcon isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                <p className="section-title">
                    {data.length} Option{data.length > 1 ? 's' : ''} (
                    <Money amount={totalPrice} accountSlug={accountSlug} />)
                </p>
            </div>
            {isExpanded && (
                <div className="options-content">
                    <div className="content-row">
                        <span className="content-column start text-secondary medium">Name</span>
                        <span className="content-column center text-secondary medium">Quantity</span>
                        {shouldShowPricesWithoutTax && (
                            <span className="content-column end text-secondary medium">Tax</span>
                        )}
                        <span className="content-column end text-secondary medium">Subtotal</span>
                    </div>
                    {data.map((option) => (
                        <div key={option.name} className="content-row">
                            <span className="content-column start text-secondary">{option.name}</span>
                            <span className="content-column center text-secondary">{option.numberOfItems}</span>
                            {shouldShowPricesWithoutTax && (
                                <span className="content-column end text-secondary">
                                    <Money
                                        amount={Number(option.priceInclTax) - Number(option.priceExclTax)}
                                        accountSlug={accountSlug}
                                    />
                                </span>
                            )}
                            <span className="content-column end text-secondary">
                                <Money
                                    amount={shouldShowPricesWithoutTax ? option.priceExclTax : option.priceInclTax}
                                    accountSlug={accountSlug}
                                />
                            </span>
                        </div>
                    ))}
                    <div className="separator" />
                    <div className="content-column total-price">
                        <span className="text-secondary medium">Total:</span>
                        <span className="text-secondary">
                            <Money amount={totalPrice} accountSlug={accountSlug} />
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ItemOptions
