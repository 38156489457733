import * as React from 'react'
import { GroupedTicketEntity, TicketsForRefund } from '../types'
import Money from 'uiComponents/money'
import Infotip from 'uiComponents/infotip'
import './styles.scss'

interface AmountCellProps {
    accountSlug: string
    ticket: TicketsForRefund
    ticketEntities: GroupedTicketEntity[]
    amount: number
    status: string
    cancellation: boolean
}

function AmountCell({ accountSlug, ticket, ticketEntities, amount, status, cancellation }: AmountCellProps) {
    const infotipStatuses = ticketEntities
        .map((t) => (t.barcode ? `${t.barcode.barcode}: ${!!t.barcode.redeemed ? 'redeemed' : 'not redeemed'}` : '-'))
        .join(', ')
    switch (status) {
        case 'refunded':
            return cancellation && amount === 0 ? (
                <div className="status">cancelled</div>
            ) : (
                <div className="status">refunded</div>
            )
        case 'cancelled':
            return <div className="status">cancelled</div>
        case 'redeemed':
        case 'partially redeemed':
            return (
                <>
                    <Money accountSlug={accountSlug} amount={amount.toFixed(2)} />
                    <Infotip pointer="right" maxWidth="18em">
                        {infotipStatuses}
                    </Infotip>
                </>
            )
        default:
            return <Money accountSlug={accountSlug} amount={amount.toFixed(2)} />
    }
}

export default AmountCell
