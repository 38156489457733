import React, { useContext, useEffect, useState } from 'react'
import { FormWrapper } from 'uiComponents/form/form'
import TextAreaFieldFormik from 'uiComponents/input/textInput/textAreaFieldFormik'
import { ModalDialog, ModalDialogTitle, NavigationSection } from 'uiComponents/popups/modal'
import { Body } from 'uiComponents/typography'
import { OrdersServiceContext } from 'orders/context'
import { LoaderWrapper } from 'uiComponents/loaders'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { IOrder } from 'orders/schema'
import RefundTable from './refundTable'
import { bulkRefundFormValidationSchema } from './validationSchema'
import { RefundForm } from './interface'
import createTextSwitcher from 'uiComponents/textSwitcher'
import { addMessage } from 'uiComponents/messages/actions'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { OrderRefundDetails } from '../types'

interface BulkOrderRefundProps extends WithNavigationProps<BaseRouteParams> {
    cancellation: boolean
    orders: IOrder[]
    initialData?: RefundForm
    setRefundDialogOpen: (open: boolean) => void
    handleSubmit: (values: any) => void
}

type SliceKeys = 'refund' | 'cancellation'
type TextKeys = 'title' | 'errorMessage' | 'body' | 'commentLabel' | 'commentPlaceholder' | 'confirm'

interface ExtendedTextSwitcherProps {
    cancellation: boolean
}

const TextSwitcher = createTextSwitcher<SliceKeys, TextKeys, ExtendedTextSwitcherProps>({
    getSliceKey: ({ cancellation }) => (cancellation ? 'cancellation' : 'refund'),
    defaultSliceKey: 'refund',
    slice: {
        refund: {
            title: 'Refund orders',
            errorMessage: 'Oops! Could not receive fees information',
            body: 'You are about to refund multiple selected orders:',
            commentLabel: 'Refund reason:',
            commentPlaceholder: 'Please write a reason why orders requires refund',
            confirm: 'Refund orders',
        },
        cancellation: {
            title: 'Cancel orders',
            errorMessage: 'Oops! Could not receive fees information',
            body: 'You are about to cancel multiple selected orders:',
            commentLabel: 'Cancel reason:',
            commentPlaceholder: 'Please write a reason why orders requires cancellation',
            confirm: 'Cancel orders',
        },
    },
})

const BulkOrderRefund: React.FC<BulkOrderRefundProps> = ({
    match,
    setRefundDialogOpen,
    handleSubmit,
    initialData,
    orders,
    cancellation,
}) => {
    const { accountSlug } = match.params
    const orderService = useContext(OrdersServiceContext)
    const [loadingFees, setLoadingFees] = useState(false)
    const [detailedOrders, setDetailedOrders] = useState<OrderRefundDetails[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        setLoadingFees(true)
        const data: OrderRefundDetails[] = []
        let hadFailed = false

        const loadData = async () => {
            for await (const order of orders) {
                try {
                    const response = await orderService.getOrderDetailsForRefund(order.id)

                    data.push(response)
                } catch (error) {
                    hadFailed = true
                }
            }

            if (hadFailed) {
                dispatch(addMessage('server_error', 'error', TextSwitcher({ cancellation, name: 'errorMessage' })))
            }
            setDetailedOrders(data)
            setLoadingFees(false)
        }

        loadData()
    }, [orderService, orders, accountSlug])

    const isOrderDisabled = (order: OrderRefundDetails) => ['partially_canceled', 'cancelled'].includes(order.status)

    return (
        <ModalDialog style={{ maxHeight: 720 }} onDismiss={() => setRefundDialogOpen(false)} interactive fromTop="20%">
            <LoaderWrapper loading={loadingFees}>
                <div style={{ width: '500px', position: 'relative' }}>
                    <ModalDialogTitle>
                        <TextSwitcher cancellation={cancellation} name="title" />
                    </ModalDialogTitle>
                    <Body size={1} style={{ marginBottom: 16 }}>
                        <TextSwitcher cancellation={cancellation} name="body" />
                    </Body>
                    <FormWrapper
                        onSubmit={handleSubmit}
                        validationSchema={bulkRefundFormValidationSchema}
                        enableReinitialize
                        initialValues={
                            initialData || {
                                orders: detailedOrders.filter((order) => !isOrderDisabled(order)),
                                partnerRefundFee: cancellation ? false : true,
                            }
                        }
                    >
                        <RefundTable
                            cancellation={cancellation}
                            isOrderDisabled={isOrderDisabled}
                            orders={detailedOrders}
                        />
                        <TextAreaFieldFormik
                            style={{ height: 85, width: '100%' }}
                            name="comment"
                            label={TextSwitcher({ cancellation, name: 'commentLabel' })}
                            placeholder={TextSwitcher({
                                cancellation,
                                name: 'commentPlaceholder',
                            })}
                        />
                        <NavigationSection>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => setRefundDialogOpen(false)}
                                color="secondary"
                            >
                                Close
                            </Button>
                            <Button
                                variant="outlined"
                                size="large"
                                color="error"
                                id="edit-refund-bulk-orders"
                                type="submit"
                            >
                                <TextSwitcher cancellation={cancellation} name="confirm" />
                            </Button>
                        </NavigationSection>
                    </FormWrapper>
                </div>
            </LoaderWrapper>
        </ModalDialog>
    )
}

export default withNavigation(BulkOrderRefund)
