import React, { MouseEvent, useCallback, useEffect } from 'react'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { SingleSelectFieldFormik } from 'uiComponents/input'
import { Link } from './utils'
import { TaxConfiguration } from 'settings/accountSettings/taxConfigurations/types'
import ReadOnlyTaxConfigurationElement from 'settings/accountSettings/taxConfigurations/readOnlyTaxConfigurationElement'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useField } from 'formik'
import './productTax.scss'
import { delay } from 'lodash'

interface ProductTaxProps {
    accountSlug: string
    loadingTaxConfigurations: boolean
    taxConfigurations: TaxConfiguration[] | null
    defaultTaxConfigurationUuid: string | null
    style?: React.CSSProperties
    onExternalNavigate?: (event: MouseEvent, destination: string) => void
}

function getTaxConfigurationByUuid(taxConfigurations: TaxConfiguration[], uuid: string): TaxConfiguration {
    return taxConfigurations?.find((taxConfiguration) => taxConfiguration.uuid === uuid)!
}

export const ProductTax = ({
    accountSlug,
    loadingTaxConfigurations,
    taxConfigurations,
    defaultTaxConfigurationUuid,
    style,
    onExternalNavigate,
}: ProductTaxProps) => {
    const taxConfigurationUuid = useField('taxConfigurationUuid')[0].value
    const [fakeLoading, setFakeLoading] = React.useState(false)
    const taxConfigurationsLink = `/account/${accountSlug}/settings/account_settings/tax_configurations`

    useEffect(() => {
        setFakeLoading(true)
        delay(() => setFakeLoading(false), 1000)
    }, [taxConfigurationUuid])

    const selectedTaxConfiguration = getTaxConfigurationByUuid(
        taxConfigurations || [],
        taxConfigurationUuid || defaultTaxConfigurationUuid!,
    )
    
    const onEdit = useCallback((event: MouseEvent) => {
        if (onExternalNavigate) {
            onExternalNavigate(event, taxConfigurationsLink)
        }
    }, [taxConfigurationsLink, onExternalNavigate])
    
    return (
        <div className="product-tax-container" style={style}>
            <FormItem htmlFor="taxConfigurationUuid">
                <FormItemName>Product tax</FormItemName>
                <LoaderWrapper loading={loadingTaxConfigurations}>
                    {taxConfigurations && defaultTaxConfigurationUuid && (
                        <>
                            <SingleSelectFieldFormik
                                id="tax-configurations"
                                name="taxConfigurationUuid"
                                options={[
                                    { name: 'Use default product tax', value: null },
                                    ...taxConfigurations.map((taxConfiguration) => ({
                                        name: taxConfiguration.name,
                                        value: taxConfiguration.uuid,
                                    })),
                                ]}
                                height="2.5rem"
                                noSelectOption="Use default product tax"
                            />
                            <div id="tax-configuration-preview" className={fakeLoading ? 'loading' : ''}>
                                <ReadOnlyTaxConfigurationElement
                                    taxConfiguration={selectedTaxConfiguration}
                                    isDefault={!taxConfigurationUuid}
                                />
                            </div>
                            <Link>
                                Taxes can be edited in your&nbsp;
                                <a
                                    href={taxConfigurationsLink}
                                    onClick={onEdit}
                                >
                                    Account settings
                                </a>
                                .
                            </Link>
                        </>
                    )}
                </LoaderWrapper>
            </FormItem>
        </div>
    )
}
