import { createApi } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { State } from 'store'
import { MollieStatusResponse } from './accountSettings/integrations/schema'
import { AdyenAccountHolderInfo, AdyenRedirectResponse, AdyenResponse } from './schema'
import { authenticatedHttpServiceQuery } from 'utils/reduxQuery'
import { ConfigReader } from 'utils/confiReader'

export const integrationsApi = createApi({
    reducerPath: 'integrationsApi',
    tagTypes: ['integrationDetails', 'adyenAccountHolder'],
    baseQuery: authenticatedHttpServiceQuery({
        baseUrl: ConfigReader.getBackOfficeEndpoint(),
    }),
    endpoints: (builder) => ({}),
})

const extendedIntegrationsApi = integrationsApi.injectEndpoints({
    endpoints: (builder) => ({
        getMollieStatus: builder.query({
            providesTags: [
                {
                    type: 'integrationDetails',
                    id: 'mollie',
                },
            ],
            queryFn: async ({
                accountSlug,
                requestId,
            }: {
                accountSlug: string
                requestId: string
            }): Promise<{ data: MollieStatusResponse }> => {
                const response = await AppServices.integrationsService.getMollieStatus({ accountSlug, requestId })

                return { data: response }
            },
        }),
        sendMollieAuth: builder.mutation({
            invalidatesTags: [{ type: 'integrationDetails', id: 'mollie' }],
            queryFn: async (
                {
                    requestId,
                    authorizationResponse,
                }: {
                    requestId: string
                    authorizationResponse: string
                },
                { getState },
            ) => {
                try {
                    const state = getState() as State
                    const response = await AppServices.integrationsService.sendMollieAuth({
                        accountSlug: state.preferences.activeAccount as string,
                        requestId,
                        authorizationResponse,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        deleteMollieAuth: builder.mutation({
            invalidatesTags: [{ type: 'integrationDetails', id: 'mollie' }],
            queryFn: async ({ accountSlug }: { accountSlug: string }) => {
                try {
                    const response = await AppServices.integrationsService.deleteMollieAuth({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        initPaypalAuthorization: builder.mutation({
            queryFn: async ({ accountSlug, email }: { accountSlug: string; email: string }) => {
                const response = await AppServices.integrationsService.initPaypalAuthorization({
                    email,
                    accountSlug,
                })

                return {
                    data: response,
                }
            },
        }),
        checkPaypalStatus: builder.query({
            providesTags: [
                {
                    type: 'integrationDetails',
                    id: 'paypal',
                },
            ],
            queryFn: async ({ accountSlug }: { accountSlug: string }) => {
                try {
                    const response = await AppServices.integrationsService.checkPaypalStatus({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        postbackPaypal: builder.query({
            queryFn: async () => {
                try {
                    const response = await AppServices.integrationsService.postbackPaypal()

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        deletePaypalAuth: builder.mutation({
            invalidatesTags: [{ type: 'integrationDetails', id: 'paypal' }],
            queryFn: async ({ accountSlug }: { accountSlug: string }) => {
                try {
                    const response = await AppServices.integrationsService.deletePaypalAuth({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        getAdyenAccountInformation: builder.query<AdyenResponse, { accountSlug: string }>({
            providesTags: ['adyenAccountHolder'],
            queryFn: async ({ accountSlug }) => {
                try {
                    const response = await AppServices.integrationsService.getAdyenAccountInformation({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        getAdyenClassicAccountInformation: builder.query<AdyenAccountHolderInfo, { accountSlug: string }>({
            providesTags: ['adyenAccountHolder'],
            queryFn: async ({ accountSlug }) => {
                try {
                    const response = await AppServices.integrationsService.getAdyenClassicAccountInformation({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        createAdyenAccountHolder: builder.mutation({
            invalidatesTags: ['adyenAccountHolder'],
            queryFn: async ({ accountSlug, form }: { accountSlug: string; form: any }) => {
                try {
                    const response = await AppServices.integrationsService.createAdyenAccountInformation({
                        accountSlug,
                        form,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        getAdyenOnboardingLink: builder.query<AdyenRedirectResponse, { accountSlug: string }>({
            queryFn: async ({ accountSlug }) => {
                try {
                    const response = await AppServices.integrationsService.getAdyenOnboardingLink({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        getAdyenPCIOnboardingLink: builder.query<AdyenRedirectResponse, { accountSlug: string }>({
            query: ({ accountSlug }) => ({
                url: `api/v1/integration_management/adyen/${accountSlug}/pci_onboarding/`,
            }),
        }),
        getVoucherifyStatus: builder.query({
            providesTags: [
                {
                    type: 'integrationDetails',
                    id: 'voucherify',
                },
            ],
            queryFn: async ({ accountSlug }: { accountSlug: string }): Promise<{ data: any }> => {
                const response = await AppServices.integrationsService.getVoucherifyStatus({ accountSlug })

                return { data: response }
            },
        }),
        connectVoucherify: builder.mutation({
            invalidatesTags: [{ type: 'integrationDetails', id: 'voucherify' }],
            queryFn: async (
                {
                    appId,
                    apiKey,
                }: {
                    appId: string
                    apiKey: string
                },
                { getState },
            ) => {
                try {
                    const state = getState() as State
                    const response = await AppServices.integrationsService.connectVoucherify({
                        accountSlug: state.preferences.activeAccount as string,
                        appId,
                        apiKey,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
        disconnectVoucherify: builder.mutation({
            invalidatesTags: [{ type: 'integrationDetails', id: 'voucherify' }],
            queryFn: async ({ accountSlug }: { accountSlug: string }) => {
                try {
                    const response = await AppServices.integrationsService.disconnectVoucherify({
                        accountSlug,
                    })

                    return { data: response }
                } catch (error) {
                    return { error: error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = integrationsApi
export const {
    useGetMollieStatusQuery,
    useSendMollieAuthMutation,
    useDeleteMollieAuthMutation,
    useInitPaypalAuthorizationMutation,
    useLazyCheckPaypalStatusQuery,
    useCheckPaypalStatusQuery,
    useDeletePaypalAuthMutation,
    useGetAdyenAccountInformationQuery,
    useGetAdyenClassicAccountInformationQuery,
    useLazyGetAdyenOnboardingLinkQuery,
    useGetVoucherifyStatusQuery,
    useDisconnectVoucherifyMutation,
    useLazyGetAdyenPCIOnboardingLinkQuery,
} = extendedIntegrationsApi
