import React, { forwardRef } from 'react'
import { format } from 'date-fns'
import { TimelineItem } from '../types'
import { TimesSolid } from '@convious/icons'
import './capacityItemTooltip.scss'

type CapacityItemTooltipProps = {
    item: TimelineItem
    strategy: 'absolute' | 'fixed'
    x: number | null
    y: number | null
}

const UnlimitedItemTooltipContent = (item: TimelineItem) => (
    <>
        <strong>Reserved: {item.reserved}</strong>
        <span>
            <TimesSolid /> Unlimited
        </span>
        <span>From: {format(item.start, 'HH:mm')}</span>
        <span>To: {format(item.end, 'HH:mm')}</span>
    </>
)

const ClosedItemTooltipContent = (item: TimelineItem) => (
    <>
        <span>
            <TimesSolid /> Closed
        </span>
        <span>From: {format(item.start, 'HH:mm')}</span>
        <span>To: {format(item.end, 'HH:mm')}</span>
    </>
)

const PeriodItemTooltipContent = (item: TimelineItem) => (
    <>
        <strong>Reserved: {item.reserved}</strong>
        <span>Remaining: {item.available}</span>
        <span>Total: {item.capacity}</span>
        <span>From: {format(item.start, 'HH:mm')}</span>
        <span>To: {format(item.end, 'HH:mm')}</span>
    </>
)

const InstantItemTooltipContent = (item: TimelineItem) => (
    <>
        <strong>Reserved: {item.reserved}</strong>
        <span>Remaining: {item.available}</span>
        <span>Total: {item.capacity}</span>
        <span>Time {format(item.start, 'HH:mm')}</span>
    </>
)

const CapacityItemTooltip = forwardRef<HTMLDivElement, CapacityItemTooltipProps>(({ strategy, item, x, y }, ref) => {
    const getTooltipContent = () => {
        if (item.capacity === 0) {
            return ClosedItemTooltipContent(item)
        }
        if (item.capacity === null) {
            return UnlimitedItemTooltipContent(item)
        }
        if (item.reservationType === 'instant') {
            return InstantItemTooltipContent(item)
        }
        return PeriodItemTooltipContent(item)
    }

    return (
        <div className="capacity-item-tooltip" ref={ref} style={{ top: y ?? 0, left: x ?? 0, position: strategy }}>
            {getTooltipContent()}
        </div>
    )
})

CapacityItemTooltip.displayName = 'Tooltip'

export default CapacityItemTooltip
