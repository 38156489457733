import * as React from 'react'
import styled, { keyframes } from 'styled-typed'
import { DataRow, Cell } from 'uiComponents/table'
import LoaderGif from './loader.gif'
import './loader.scss'

const FadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Loader = styled.img`
    animation: ${FadeInAnimation} 0.1s ease-in;
`

const LoaderRow = styled(DataRow)`
    height: unset;
    justify-content: center;
`

const ChartLoader = styled.div<{ topOffset?: string }>`
    position: absolute;
    top: ${(props) => (props.topOffset ? props.topOffset : '25%')};
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
`
const UploadLoaderContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`
export const ChartLoaderBackground = styled.div<ChartDataLoaderProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${(p) => (p.opaque ? 1 : 0.8)};
    z-index: 10;
    background: ${(p) => (p.opaque ? p.theme.colors.white : p.theme.colors.background)};
`

interface ChartDataLoaderProps {
    opaque?: boolean
    topOffset?: string
}

export function TableLoader({ style }: { style?: React.CSSProperties }) {
    return (
        <LoaderRow style={style}>
            <Cell align="center" style={{ width: '7em', maxWidth: '7em' }}>
                <div style={{ textAlign: 'center' }}>
                    <Loader src={LoaderGif} style={{ width: '7em' }} />
                </div>
            </Cell>
        </LoaderRow>
    )
}

export function PageLoader({ style }: { style?: React.CSSProperties }) {
    return (
        <div style={style} className="convious-loader">
            <Loader src={LoaderGif} />
        </div>
    )
}

export function AvatarLoader() {
    return <Loader src={LoaderGif} style={{ width: '5.3em', height: '6.2em' }} />
}

export function EditFieldLoader() {
    return <Loader src={LoaderGif} style={{ width: '1.5em' }} />
}

export function ChartDataLoader(props: ChartDataLoaderProps) {
    return (
        <>
            <ChartLoaderBackground opaque={props.opaque} />
            <ChartLoader topOffset={props.topOffset}>
                <Loader src={LoaderGif} style={{ width: '6em' }} />
            </ChartLoader>
        </>
    )
}

interface LoaderWrapperProps extends ChartDataLoaderProps {
    loading?: boolean
}

export const LoaderWrapper: React.FC<LoaderWrapperProps> = ({ loading = false, children, ...props }) => {
    return (
        <>
            {!!loading && <ChartDataLoader {...props} />}
            {children}
        </>
    )
}

export function UploadLoader() {
    return (
        <UploadLoaderContainer>
            <Loader src={LoaderGif} style={{ width: '6em' }} />
        </UploadLoaderContainer>
    )
}

export function AbsoluteLoader() {
    return (
        <div style={{ position: 'absolute', top: '4em', left: '45%' }}>
            <Loader src={LoaderGif} style={{ width: '10em' }} />
        </div>
    )
}

export function PlainLoader({ style }: { style?: React.CSSProperties }) {
    return <Loader src={LoaderGif} style={style} />
}
