import React from 'react'
import { specifyOrderStatus, IOrder } from 'orders/schema'
import InlineEmail from '../commons/inlineEmail'
import InlineVisitDate from '../commons/inlineVisitDate'
import InlineVisitTime from '../commons/inlineVisitTime'
import StatusLabel from '../commons/orderStatusLabel'
import { DiscountCodesElement } from 'orders/helpers'
import DataItem from './components/dataItem'
import { formatTimestamp, getDiscountCodesString } from '../utils'
import { useAppDispatch } from 'store/hooks'
import { selectOrder } from '../redux'
import { getPaymentToDisplay } from '../ordersTable/columns'

const OrderInfo = ({ order }: { order: IOrder }) => {
    const paymentToDisplay = getPaymentToDisplay(order.payments)
    const dispatch = useAppDispatch()

    return (
        <div className="content">
            <div className="order-info">
                <div className="column">
                    <DataItem className="row" name="Order ID:">
                        {order.number}
                    </DataItem>
                    <DataItem className="row" name="Email:">
                        <InlineEmail
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Payment updated at:">
                        {paymentToDisplay?.paymentDate
                            ? formatTimestamp(paymentToDisplay.paymentDate, order.timezone)
                            : '-'}
                    </DataItem>
                    <DataItem className="row" name="Visit date:">
                        <InlineVisitDate
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Visit time:">
                        <InlineVisitTime
                            order={order}
                            onUpdated={(order) => {
                                dispatch(selectOrder(order))
                            }}
                        />
                    </DataItem>
                    <DataItem className="row" name="Discount codes:">
                        <DiscountCodesElement discountsString={getDiscountCodesString(order)} />
                    </DataItem>
                </div>
                <div className="column">
                    <DataItem className="row" name="Order status:">
                        <StatusLabel status={specifyOrderStatus(order)} />
                    </DataItem>
                    <DataItem className="row" name="Payment status:">
                        <StatusLabel status={paymentToDisplay?.paymentStatus ?? 'unpaid'} />
                    </DataItem>
                    <DataItem className="row" name="Payment method:">
                        {paymentToDisplay?.paymentMethod ?? '-'}
                    </DataItem>
                    <DataItem className="row" name="Email status:">
                        {order.emailStatus}
                    </DataItem>
                    <DataItem className="row" name="Downloaded:">
                        {order.downloaded ? formatTimestamp(order.downloaded, order.timezone) : '-'}
                    </DataItem>
                    <DataItem className="row" name="Reseller:">
                        {order.reseller.name ? order.reseller.name : '-'}
                    </DataItem>
                </div>
            </div>
        </div>
    )
}

export default OrderInfo
