import * as React from 'react'
import { IconTextInput, SingleSelectOption } from 'uiComponents/input'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { SingleSelect } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { delay } from 'utils'
import classNames from 'classnames'
import './searchBar.scss'

interface SearchBarProps {
    searchByOptions: SingleSelectOption[]
    defaultSearchBy?: string
    navigation: Navigation
    match: RouteMatch<any>
    minSearchLength?: number
    maxSearchLength?: number
    selectWidth?: string
    searchOnEnter?: boolean
    onSearch?: () => void
    onChange?: (search: string) => void
    onSearchByChange?: (searchBy: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    className?: string
    autoSearchByPatterns?: [RegExp, string][]
    showClearButton?: boolean
}

function SearchBar({
    searchByOptions,
    defaultSearchBy,
    navigation,
    minSearchLength,
    maxSearchLength,
    selectWidth,
    searchOnEnter,
    onSearch,
    onChange,
    onSearchByChange,
    replaceMessages,
    removeAllMessages,
    className,
    autoSearchByPatterns,
    showClearButton,
}: SearchBarProps) {
    const [search, setSearch] = React.useState<string>('')
    const [searchBy, setSearchBy] = React.useState<string>('')

    const setSearchParameters = () => {
        const query = navigation.query()
        const searchText = query.search || ''
        const searchByCategory = query.searchBy || defaultSearchBy || ''
        setSearch(searchText)
        setSearchBy(searchByCategory)
    }

    React.useEffect(() => {
        setSearchParameters()
    }, [window.location.pathname])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        if (onChange) {
            onChange(value)
        }

        if (autoSearchByPatterns) {
            for (const [regex, searchByValue] of autoSearchByPatterns) {
                if (regex.test(value)) {
                    setSearchBy(searchByValue)
                    break
                }
            }
        }
    }

    const handleSearchByChange = (value: string) => {
        setSearchBy(value)
        if (onSearchByChange) {
            onSearchByChange(value)
        }
    }

    const handleClearSearch = () => {
        setSearch('')
        if (onChange) {
            onChange('')
        }

        if (search) {
            navigation.addQueryWithReplace({ search: '' })
            if (onSearch) {
                onSearch()
            }
        }
    }

    const executeSearch = async () => {
        if (minSearchLength && search.length < minSearchLength) {
            await flashErrorMessage(`Minimum search length is ${minSearchLength} symbols`)
        } else if (maxSearchLength && search.length > maxSearchLength) {
            await flashErrorMessage(`Maximum search length is ${maxSearchLength} symbols`)
        } else {
            navigation.addQueryWithReplace({ search, searchBy })
            if (onSearch) {
                onSearch()
            }
        }
    }

    const flashErrorMessage = async (text: string) => {
        replaceMessages('error', 'error', text)
        await delay(4000)
        removeAllMessages()
    }

    return (
        <div className={`search-bar-container ${className || ''}`.trim()}>
            <IconTextInput
                id="search-text"
                iconLeft={faSearch}
                placeholder="Enter search text"
                value={search}
                onChange={handleSearchChange}
                onFinishTyping={(value) => {
                    if (value !== value.trim()) {
                        setSearch(value.trim())
                    }
                }}
                onKeyUp={(event) => {
                    if (event.key === 'Enter' && searchOnEnter) {
                        executeSearch()
                    }
                }}
                block
            />
            <div className="search-by-container">
                <FontAwesomeIcon className="search-by-icon" icon={faSlidersH} />
                <SingleSelect
                    id="search-by"
                    options={searchByOptions}
                    noSelectOption="Select search by"
                    width={selectWidth || '11em'}
                    selected={searchBy}
                    onSelect={handleSearchByChange}
                    whiteBackground
                    style={{ margin: '0 .5em' }}
                />
                <ActionButton
                    id="search-button"
                    kind="action"
                    size="large"
                    style={{ marginRight: '.2em' }}
                    onClick={executeSearch}
                >
                    Search
                </ActionButton>
            </div>
            {showClearButton && (
                <ActionButton
                    className={classNames('clear-search', { visible: search !== '' })}
                    disabled={search === ''}
                    kind="action"
                    onClick={handleClearSearch}
                >
                    Clear
                </ActionButton>
            )}
        </div>
    )
}

export default withNavigation(SearchBar)
